import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Actions, Pagination, PaginationMetadata, RechtFilter, Resources } from '@mp/shared/data-access';

import { CreateNewsfeed, Newsfeed, UpdateNewsfeed } from '@mp/dashboard/data-access';

import { PageEvent } from '@angular/material/paginator';
import { filterUndefined } from '@core/shared/util';
import { NewsfeedFacade } from '../newsfeed.facade';

@Component({
  selector: 'mp-newsfeed',
  templateUrl: './newsfeed.component.html',
})
export class NewsfeedComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-newsfeed';

  editMode: Map<number, boolean>;
  createNewMessage: boolean;
  titel: string | null;
  nachricht: string | null;

  readonly news$: Observable<Array<Newsfeed> | undefined> = this.newsfeedFacade.news$;
  readonly pagination$: Observable<PaginationMetadata> = this.newsfeedFacade.pagination$.pipe(filterUndefined());

  readonly schreibrecht: RechtFilter = {
    resource: Resources.News,
    action: Actions.Write,
  } as const;

  constructor(private readonly newsfeedFacade: NewsfeedFacade) {
    this.editMode = new Map();
    this.createNewMessage = false;
    this.titel = null;
    this.nachricht = null;
  }

  ngOnInit(): void {
    this.newsfeedFacade.getNews();
  }

  setEditMode(isActivated: boolean, newsId: number): void {
    this.editMode.set(newsId, isActivated);
    this.createNewMessage = false;
  }

  cancel(newsAlreadyExists: boolean, newsId?: number): void {
    if (!newsAlreadyExists) {
      this.titel = null;
      this.nachricht = null;
    }

    if (newsId) {
      this.editMode.set(newsId, false);
    }

    this.createNewMessage = false;
  }

  create(): void {
    this.createNewMessage = true;
    this.titel = null;
    this.nachricht = null;
  }

  createNews(): void {
    if (this.createNewMessage) {
      const news = {
        titel: this.titel,
        nachricht: this.nachricht,
        istAktiv: true,
      };

      this.newsfeedFacade.createNews(news as CreateNewsfeed);
      this.createNewMessage = false;
    }
  }

  updateNews(newsId: number, titel: string, nachricht: string): void {
    this.editMode.set(newsId, false);

    const news: UpdateNewsfeed = {
      id: newsId,
      titel,
      nachricht,
    };

    this.newsfeedFacade.updateNews(news);
  }

  deleteNews(newsId: number): void {
    this.newsfeedFacade.deleteNews(newsId);
    this.titel = null;
    this.nachricht = null;
  }

  onPageChange(event: PageEvent): void {
    const {pageIndex, pageSize} = event;
    const paginationParams: Pagination = {
      page: pageIndex + 1,
      pageSize: pageSize,
    };
    this.newsfeedFacade.getNews(paginationParams);
  }
}
