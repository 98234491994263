<!-- <ng-template #pictureTemplate>
  <div class="profile-picture-template">
    <ng-content select=[profile-picture]></ng-content>
  </div>
</ng-template>
<ng-container *ngIf="picture; else pictureTemplate">
  <mp-avatar  size="small"
              [name]="user"
              [image]="picture">
  </mp-avatar>
</ng-container>

<ng-template #userTemplate>
  <span class="profile-user">
    <ng-content select="[profile-user]"></ng-content>
  </span>
</ng-template>
<ng-container *ngIf="user; else userTemplate">
  <span class="profile-user">
    {{ user }}
  </span>
</ng-container>

<ng-template #companyTemplate>
  <span class="profile-company">
    <ng-content select="[profile-company]"></ng-content>
  </span>
</ng-template>
<ng-container *ngIf="company; else companyTemplate">
  <span class="profile-company">
    {{ company }}
  </span>
</ng-container> -->

<mp-avatar  size="medium"
            [name]="user"
            [image]="picture">
</mp-avatar>

<span class="profile-user">{{ user }}</span>
<span class="profile-company">{{ company }}</span>