import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CreateOrganisationBenutzer,
  CreateRolleBenutzer,
  OrganisationBenutzer,
  RolleBenutzer,
  UpdateOrganisationBenutzer
} from './organisation-benutzer';
import { ApiBaseService } from '@mp/shared/data-access';

// Temporärer Workaround!
type DataWrapper = {
  data: CreateOrganisationBenutzerNew;
}

type CreateOrganisationBenutzerNew = {
  entity: OrganisationBenutzer;
  statusCode: number;
  statusMessage: string;
}

@Injectable({ providedIn: 'root' })
export class OrganisationBenutzerService
  extends ApiBaseService<OrganisationBenutzer, CreateOrganisationBenutzer, UpdateOrganisationBenutzer> {

  private readonly kostenstellenApiPath = '/api/my/organisation/kostenstellen';
  private readonly rollenApiPath = '/api/my/organisation/rollen';

  constructor(http: HttpClient) {
    super(http, '/api/my/organisation/benutzer');
  }

  createBenutzer(benutzerToCreate: CreateOrganisationBenutzer): Observable<DataWrapper> {
    return this.http.post<DataWrapper>('/api/my/organisation/benutzer', benutzerToCreate );
  }

  addRollenToBenutzer(rollenToAdd: Array<CreateRolleBenutzer>): Observable<Array<boolean>> {
    return forkJoin(rollenToAdd.map(rolle => this.addSingleRolleToBenutzer(rolle)));
  }

  private addSingleRolleToBenutzer(rolle: CreateRolleBenutzer): Observable<boolean> {
    return this.http.post<boolean>(`${this.rollenApiPath}/${rolle.rolleId}/benutzer/${rolle.benutzerId}`, rolle);
  }

  removeRollenFromBenutzer(benutzerId: number, rollenIds: Array<RolleBenutzer>): Observable<Array<boolean>> {
    return forkJoin(rollenIds.map(rolleId => this.removeSingleRolleFromBenutzer(benutzerId, rolleId.id)));
  }

  private removeSingleRolleFromBenutzer(benutzerId: number, rolleId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.rollenApiPath}/${rolleId}/benutzer/${benutzerId}`, { observe: 'response' })
      .pipe(map(response => response.status === 204));
  }

  addKostenstellenToBenutzer(benutzerId: number, kostenstellenIds: Array<number>): Observable<Array<boolean>> {
    return forkJoin(kostenstellenIds.map(kostenstelleId => this.addSingleKostenstelleToBenutzer(benutzerId, kostenstelleId)));
  }

  private addSingleKostenstelleToBenutzer(benutzerId: number, kostenstelleId: number): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.kostenstellenApiPath}/${kostenstelleId}/benutzer/${benutzerId}`, null, { observe: 'response' })
      .pipe(map(response => response.status === 204));
  }

  removeKostenstellenFromBenutzer(benutzerId: number, kostenstellenIds: Array<number>): Observable<Array<boolean>> {
    return forkJoin(kostenstellenIds.map(kostenstelleId => this.removeSingleKostenstelleFromBenutzer(benutzerId, kostenstelleId)));
  }

  private removeSingleKostenstelleFromBenutzer(benutzerId: number, kostenstelleId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.kostenstellenApiPath}/${kostenstelleId}/benutzer/${benutzerId}`, { observe: 'response' })
      .pipe(map(response => response.status === 204));
  }
}
