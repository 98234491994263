import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { AvatarModule } from '../avatar/avatar.module';
import { NavigationItemComponent } from './navigation-item.component';
import { NavigationProfileComponent } from './navigation-profile.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    AvatarModule
  ],
  declarations: [
    NavigationItemComponent,
    NavigationProfileComponent
  ],
  exports: [
    NavigationItemComponent,
    NavigationProfileComponent
  ]
})
export class NavigationModule { }
