import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

import { MeineOrganisationWidgetData } from '@mp/dashboard/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';

@Component({
  selector: 'mp-meine-organisation-widget',
  templateUrl: './meine-organisation-widget.component.html',
  styleUrls: ['./meine-organisation-widget.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DashboardWidgetComponent,
    CountUpStatComponent
  ],
})
export class MeineOrganisationWidgetComponent {
  @HostBinding('class') readonly class = 'mp-meine-organisation-widget';

  @Input() data?: MeineOrganisationWidgetData;
}
