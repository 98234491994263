import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiBaseService } from '@mp/shared/data-access';
import { Observable } from 'rxjs';

import { Benutzer, CreateBenutzer, UpdateBenutzer } from '..';

// Temporärer Workaround!
type DataWrapper = {
  data: CreateBenutzerNew;
}

type CreateBenutzerNew = {
  entity: Benutzer;
  statusCode: number;
  statusMessage: string;
}

@Injectable({ providedIn: 'root' })
export class BenutzerService extends ApiBaseService<Benutzer, CreateBenutzer, UpdateBenutzer> {

  constructor(http: HttpClient) {
    super(http, '/api/core/authorization/benutzerverwaltung');
  }

  createBenutzer(benutzerToCreate: CreateBenutzer): Observable<DataWrapper> {
    return this.http.post<DataWrapper>('/api/core/authorization/benutzerverwaltung', benutzerToCreate );
  }

  changePassword(id: number, passwort: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/${id}/reset`, { passwort });
  }
}
