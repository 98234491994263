import { Pipe, PipeTransform } from '@angular/core';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

import { concat, find, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'hasRequisitionsWidgetRights', standalone: true })
export class HasRequisitionsWidgetRightsPipe implements PipeTransform {
  private readonly requisitionsAdditionalRightsResources: Resources[] = [
    Resources.AlleAnforderungen,
    Resources.Anforderungen,
    Resources.EigeneAnforderungen,
  ];

  private readonly hasAnyOfAdditionalRights$: Observable<boolean> = concat(
    ...this.requisitionsAdditionalRightsResources.map((resource) =>
      this.hasResourceReadPermission(resource)
    )
  ).pipe(
    find((hasPermission) => hasPermission),
    map(Boolean)
  );

  constructor(private readonly userInfo: UserInfoFacade) {}

  transform(widgetRight: RechtFilter): Observable<boolean> {
    const hasWidgetRight$: Observable<boolean> = this.hasPermission(widgetRight);

    return hasWidgetRight$.pipe(
      switchMap(hasWidgetRight => hasWidgetRight ? this.hasAnyOfAdditionalRights$ : of(false)),
    );
  }

  private hasPermission(right: RechtFilter): Observable<boolean> {
    return this.userInfo.watchRecht$(right).pipe(map((hasRight) => hasRight ?? false));
  }

  private hasResourceReadPermission(resource: Resources): Observable<boolean> {
    return this.hasPermission({ resource, action: Actions.Pass });
  }
}
