<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  {{ data.message }}
</div>
<div mat-dialog-actions [align]="'center'">
  <button
    mat-raised-button
    color="accent"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    <mat-icon>check</mat-icon>
    Ja
  </button>
  <button mat-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
    Nein
  </button>
</div>
