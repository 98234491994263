<ul
  class="article-list"
  [ngStyle]="{ height: listHeight }"
>
  <cdk-virtual-scroll-viewport #scroller [itemSize]="ITEM_SIZE">
    <li
      *cdkVirtualFor="let article of deletedArticles; trackBy: trackByFn"
      class="article-list__article"
    >
      <div class="article-list__article-name truncate" [title]="article.articleRecordId">
        <ng-container *ngIf="article.articleName; else showArticleRecordId">
          {{ article.articleName }}
        </ng-container>
        <ng-template #showArticleRecordId>
          {{ article.articleRecordId }}
        </ng-template>
      </div>

      <div class="article-list__article-actions">
        <button
          class="article-list__delete-article-button"
          mat-stroked-button
          color="warn"
          (click)="onArticleDelete(article.articleRecordId)"
        >
          <mat-icon>done</mat-icon>
          Bestätigen
        </button>
      </div>
    </li>
    <li *ngIf="hasMoreRows" class="article-list__loading">
      <mat-spinner [diameter]="25"></mat-spinner>
    </li>
  </cdk-virtual-scroll-viewport>
</ul>
