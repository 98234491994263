import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-view',
  templateUrl: './view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('open => closed', [animate('0.15s ease-in-out')]),
      transition('closed => open', [animate('0.15s ease-in-out')]),
    ]),
  ],
})
export class ViewComponent extends ElementDirective implements OnDestroy {
  @Output() readonly cancel: EventEmitter<void>;
  @Output() readonly send: EventEmitter<void>;

  @Input() viewTitle?: string;
  @Input() submittable = false;

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  idFormValidInternal?: boolean;
  isContextBarActive?: boolean;

  constructor() {
    super();

    this.class = 'mp-view';
    this.cancel = new EventEmitter<void>();
    this.send = new EventEmitter<void>();
  }

  ngOnDestroy(): void {
    if (this.cancel) {
      this.cancel.complete();
    }
    if (this.send) {
      this.send.complete();
    }
  }
}
