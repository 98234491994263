import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ArticleCounts, ArticleCountsBaseWidgetComponent } from '@mpcm/shared';
import { Observable } from 'rxjs';
import { MedicalShopWidgetStore } from './medical-shop-widget.store';

@Component({
  selector: 'mpms-medical-shop-widget',
  templateUrl: './medical-shop-widget.component.html',
  styleUrls: ['./medical-shop-widget.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, ArticleCountsBaseWidgetComponent],
  providers: [MedicalShopWidgetStore],
})
export class MedicalShopWidgetComponent {
  @HostBinding('class') readonly class = 'mpms-medical-shop-widget';

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.store.articleCounts$;

  constructor(private readonly store: MedicalShopWidgetStore) {
    this.store.fetchArticleCounts();
  }

  handleSearch(searchTerm: string): void {
    this.store.navigateToArticleSearch(searchTerm);
  }
}
