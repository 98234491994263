import { Directive, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

/**
 * Sets the icon of a `mat-icon` component using a SVG icon when the name starts with `@`, using a regular font icon otherwise.
 */
@Directive({
  selector: 'mat-icon[mpIcon]',
})
export class IconDirective {
  constructor(private readonly matIcon: MatIcon) {
  }

  @Input()
  set mpIcon(name: string) {
    if (name[0] === '@') {
      this.matIcon.svgIcon = name.substring(1);
    } else {
      this.matIcon._elementRef.nativeElement.innerHTML = name;
    }
  }
}
