<mp-dashboard-widget
  title="Anforderungen (Offen)"
  icon="fact_check"
  [featurePageLink]="['/medical-shop/auftraege/offen']"
  [enabledByPermission]="true"
>
  <ng-container *ngIf="data">
    <p class="subtitle">Heute</p>

    <div class="requisition-statistics">
      <mpcm-count-up-stat
        class="requisition-statistics__pending"
        [statValue]="data.anforderungenOffenAnzahl"
        [statLabel]="'Offen'"
        [ngClass]="{
          'mpcm-count-up-stat--success': data.anforderungenOffenAnzahl === 0
        }"
      ></mpcm-count-up-stat>

      <mpcm-count-up-stat
        class="requisition-statistics__failed"
        [statValue]="data?.anforderungenProblemAnzahl"
        [statLabel]="'mit Problemen'"
        [ngClass]="{
          'mpcm-count-up-stat--danger': data?.anforderungenProblemAnzahl > 0
        }"
      ></mpcm-count-up-stat>
    </div>
  </ng-container>
</mp-dashboard-widget>
