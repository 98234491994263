<mp-dashboard-widget
  [title]="title"
  [icon]="icon"
  [showSearchField]="true"
  [featurePageLink]="featurePageLink"
  (search)="handleSearch($event)"
  [enabledByPermission]="enabledByPermission"
  [noPermissionText]="noPermissionText"
>
  <div class="article-statistics">
    <mpcm-count-up-stat
      class="article-statistics__industry"
      [statValue]="data.industryArticleCount"
      [statLabel]="'Industrieartikel'"
    ></mpcm-count-up-stat>

    <mpcm-count-up-stat
      class="article-statistics__clinic"
      [statValue]="data.clinicArticleCount"
      [statLabel]="'Klinikartikel'"
    ></mpcm-count-up-stat>
  </div>
</mp-dashboard-widget>
