<a
  [class.small-mode]="isSmall"
  [href]="mailToHref$ | async"
  [matTooltip]="'Sende uns Feedback!'"
  target="_blank"
  rel="noopener noreferrer"
  color="accent"
  mat-button
>
  <mat-icon>chat</mat-icon>
  <span>
    Rückmeldung
  </span>
</a>
