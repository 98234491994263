<mp-dashboard-widget
  *ngIf="purchaseRequisitionsHistoryCounts$ | async as purchaseRequisitionsHistoryCounts"
  title="Anforderungen (Historie)"
  icon="fact_check"
  [showSearchField]="false"
  [enabledByPermission]="true"
>
  <mpdm-requisitions-period-selector
    [selectedPeriod]="defaultPeriod"
    (selectedPeriodChange)="onPeriodChange($event)"
    ngProjectAs="widget-header-element"
  ></mpdm-requisitions-period-selector>

  <ng-container *ngIf="purchaseRequisitionsHistoryCounts">
    <div class="requisition-statistics">
      <mpcm-count-up-stat
        class="requisition-statistics__approved"
        [statValue]="purchaseRequisitionsHistoryCounts.approvedCount"
        [statLabel]="'Freigegeben'"
        [link]="link"
        [queryParams]="approvedRequisitionsParams"
        [ngClass]="{
          'mpcm-count-up-stat--success': purchaseRequisitionsHistoryCounts.approvedCount > 0
        }"
      ></mpcm-count-up-stat>

      <mpcm-count-up-stat
        class="requisition-statistics__rejected"
        [statValue]="purchaseRequisitionsHistoryCounts.rejectedCount"
        [statLabel]="'Abgelehnt'"
        [link]="link"
        [queryParams]="rejectedPendingRequisitionsParams"
        [ngClass]="{
          'mpcm-count-up-stat--danger': purchaseRequisitionsHistoryCounts.rejectedCount > 0
        }"
      ></mpcm-count-up-stat>

      <mpcm-count-up-stat
        class="requisition-statistics__total"
        [statValue]="purchaseRequisitionsHistoryCounts.totalValue"
        [statLabel]="'Auftragswert'"
        [options]="countUpOptionsCurrency"
      ></mpcm-count-up-stat>
    </div>
  </ng-container>
</mp-dashboard-widget>
