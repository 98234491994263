import { createAction, props } from '@ngrx/store';

import { Profil, UpdateProfil } from '../profil';

const loadInitial = createAction(
  '[Profil Display Component] Load Profil Initially'
);

const loadedInitialSuccessfully = createAction(
  '[Profil Service] Initial Profil Load Successfully',
  props<{ loadedProfil: Profil }>()
);

const reload = createAction(
  '[Profil Display Component] Reload Profil'
);

const reloadedSuccessfully = createAction(
  '[Profil Service] Profil Reload Successfully',
  props<{ loadedProfil: Profil }>()
);

const update = createAction(
  '[Profil Edit Component] Update Profil',
  props<{ updateDto: UpdateProfil }>()
);

const updatedSuccessfully = createAction(
  '[Profil Service] Updated Profil Successfully',
  props<{ updateResult: Profil }>()
);

const cancelUpdate = createAction(
  '[Profil Edit Component] Cancel Profil Update'
);

const canceledUpdate = createAction(
  '[Profil Edit Component] Canceled Profil Update'
);

const selectActiveOrganisation = createAction(
  '[Selector Component] Switch Organisation',
  props<{ organisationId: number, rememberSelectedOrganisation: boolean, referrerUrl: string }>()
);

const selectedActiveOrganisationSuccessfully = createAction(
  '[Profil Service] Switched Organisation Successfully',
  props<{ organisationId: number, referrerUrl: string }>()
);

const selectActiveOrganisationFailed = createAction(
  '[Profil Service] Switch Organisation Failed'
);

export namespace ProfilActions {
  export const API = {
    loadedInitialSuccessfully,
    reloadedSuccessfully,
    updatedSuccessfully,
    selectActiveOrganisationFailed,
    selectedActiveOrganisationSuccessfully
  };

  export const COMPONENT = {
    selectActiveOrganisation,
    loadInitial,
    reload,
    cancelUpdate,
    canceledUpdate,
    update
  };
}
