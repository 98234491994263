<div class="icon-wrapper icon-{{icon}}" [ngSwitch]="icon">

  <div *ngSwitchCase="'parentorganization'" class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon class="material-icons-outlined">foundation</mat-icon>
  </div>

  <div *ngSwitchCase="'supplier'" class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon class="material-icons-outlined">local_shipping</mat-icon>
  </div>

  <div *ngSwitchCase="'clinicsupplier'" class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon class="material-icons-outlined">maps_home_work</mat-icon>
  </div>

  <div *ngSwitchCase="'manufacturer'" class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon class="material-icons-outlined">factory</mat-icon>
  </div>

  <div *ngSwitchCase="'division'" class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon svgIcon="division" class="suppliertype--division"></mat-icon>
  </div>

  <div *ngSwitchDefault class="lieferant-icon">
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path d="M9 36.0213V27.5552V11.0213H27V36.0213L18 31.9027L9 36.0213Z" fill="#E3ECED" stroke="#BEBEBE" stroke-linecap="square"/>
      <rect x="0.292893" y="17.9706" width="25" height="25" rx="1.5" transform="rotate(-45 0.292893 17.9706)" fill="white" stroke="#BEBEBE" stroke-linecap="square"/>
    </svg>

    <mat-icon class="material-icons-outlined">question_mark</mat-icon>
  </div>

</div>
