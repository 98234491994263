import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterFacade } from '@core/shared/data-access';
import { UtilDirectivesModule } from '@core/shared/util';
import { SearchFieldModule } from '@core/ui';
import { PageLink } from '@mp/shared/data-access';

@Component({
  standalone: true,
  selector: 'mp-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,

    MatIconModule,
    MatCardModule,
    MatButtonModule,

    SearchFieldModule,
    UtilDirectivesModule,
  ],
})
export class DashboardWidgetComponent {
  @HostBinding('class') readonly class = 'mp-dashboard-widget';

  @Input() icon?: string;
  @Input() title?: string;
  @Input() featurePageLink: PageLink;
  @Input() showSearchField = false;
  @Input() enabledByPermission = false;
  @Input() noPermissionText = '';

  @Output() readonly search: EventEmitter<string> = new EventEmitter<string>();

  searchFieldSearchTerm = '';

  get actionTitle(): string {
    return this.enabledByPermission ? this.title ?? '' : this.noPermissionText;
  }

  constructor(@Optional() private readonly routerFacade: RouterFacade) {}

  handleSearch(searchTerm: string): void {
    if (searchTerm.length) this.search.emit(searchTerm);
  }

  handleSearchTermChange(searchTerm: string): void {
    this.searchFieldSearchTerm = searchTerm;
  }

  onLinkClick(): void {
    if (!this.enabledByPermission) {
      return;
    }

    if (this.searchFieldSearchTerm) {
      this.handleSearch(this.searchFieldSearchTerm);
      return;
    }

    this.routerFacade?.navigate((router) =>
      router.navigate(
        Array.isArray(this.featurePageLink) ? this.featurePageLink : [this.featurePageLink]
      )
    );
  }
}
