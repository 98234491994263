<ng-template #default>
  <span class="item__label truncate"><ng-content></ng-content></span>
</ng-template>

<a 	class="link"
    matRipple
    [routerLink]="[path]"
    [routerLinkActive]="isGroup ? 'expanded' : 'active'"
    [routerLinkActiveOptions]="{ exact: path === '/' }"
    #rla="routerLinkActive"
    #link
    >
  <mat-icon class="icon" [ngClass]="rla.isActive ? '' : 'material-icons-outlined'">
    {{ icon }}
  </mat-icon>
  <ng-container *ngIf="label; else default">
    <span class="item__label truncate">{{ label }}</span>
  </ng-container>
</a>

<ng-container *ngIf="isGroup || label">
  <div class="group-content" #groupcontent>
    <span class="group-label">{{ label }}</span>
    <ng-content select="mp-nav-item"></ng-content>
  </div>
</ng-container>
