import { Component, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

@Component({
  selector: 'mp-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent {
  @Input()
  isSmall = false;

  supportMailAddress$: Observable<string | undefined> =
    this.appConfigService.envConfig$
    .pipe(
      map((envConfig) => envConfig.supportMailAddress)
    );

  supportMailSubject$: Observable<string | undefined> =
    this.appConfigService.envConfig$
    .pipe(
      map((envConfig) => envConfig.supportMailSubject)
    );

  supportMailBody$: Observable<string | undefined> =
    this.appConfigService.envConfig$
    .pipe(
      map((envConfig) => envConfig.supportMailBody)
    );

  private addressEmail = 'plattformfeedback@mediio.de';

  private name$ = this.userInfoFacade.profil$.pipe(
    map((profile) => profile ? `${profile?.vorname} ${profile?.nachname}` : '')
  );

  private email$ = this.userInfoFacade.profil$.pipe(
    map((profile) => profile ? `${profile?.email}` : '')
  );

  mailToHref$ = combineLatest([this.supportMailSubject$, this.supportMailBody$, this.name$, this.email$, this.supportMailAddress$]).pipe(
    map(([subject, body, name, email, supportMailAddress]) => {
      return [subject, `${body}
${name}
${email}`, supportMailAddress] as Array<string>;
    }),
    map(([subject, bodyWithName, supportMailAddress]: Array<string>) =>
      supportMailAddress
      ? `mailto:${this.addressEmail};${supportMailAddress}?subject=${subject}&body=${encodeURIComponent(bodyWithName)}`
      : `mailto:${this.addressEmail}?subject=${subject}&body=${encodeURIComponent(bodyWithName)}`
    )
  );

  constructor(
    private userInfoFacade: UserInfoFacade,
    private readonly appConfigService: AppConfigService
  ) {}
}
