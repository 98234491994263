<form [formGroup]="form">

  <mat-form-field>
    <mat-label>Neues Passwort</mat-label>
    <input  matInput
            type="password"
            formControlName="password"
            [matAutocomplete]="auto"
            (click)="onFirstPasswordInputClick()">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Neues Passwort bestätigen</mat-label>
    <input  matInput
            type="password"
            formControlName="passwordConfirmation"
            (click)="onFirstPasswordConfirmationInputClick()">
  </mat-form-field>

  <button mat-flat-button
          type="submit"
          (click)="onButtonClick()"
          [disabled]="form.invalid">
    Passwort ändern
  </button>

</form>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let requirement of (passwordRequirements$ | async)">
    <div class="requirements-row"
         [class.color-met]="requirement.met"
         [class.color-unmet]="!requirement.met">
      <div class="icon-col">
        <ng-container *ngIf="requirement.met;
                             then metTemplate;
                             else unmetTemplate">
        </ng-container>

        <ng-template #metTemplate>
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <ng-template #unmetTemplate>
          <mat-icon color="warn">error</mat-icon>
        </ng-template>
      </div>
      <div class="text-col">
        <small>{{ requirement.text }}</small>
      </div>
    </div>
  </mat-option>
</mat-autocomplete>
