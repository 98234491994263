import { Injectable, Injector } from '@angular/core';

import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';

import { ComponentFlyoutRef } from '../component/component-flyout-ref';
import { CustomOverlayConfig } from '../custom-overlay/custom-overlay-config';
import { CustomOverlayService } from '../custom-overlay/custom-overlay.service';
import { FlyoutComponent } from '../component/flyout-component';


@Injectable({ providedIn: 'root' })
export class ComponentFlyoutService extends CustomOverlayService {

  constructor(injector: Injector) {
    super(injector);
  }

  open<T extends FlyoutComponent<unknown>>(
    component: ComponentType<T>,
    flyoutConfig: CustomOverlayConfig
  ): ComponentFlyoutRef<T> {
    const overlayRef = this.createOverlay(flyoutConfig);
    const flyoutRef = new ComponentFlyoutRef<T>(overlayRef);

    const overlayComponentRef = this.attachOverlayContainer(
      component,
      overlayRef,
      flyoutConfig,
      flyoutRef
    );

    flyoutRef.initializeWithComponentRef(overlayComponentRef);

    if (flyoutConfig.hasBackdrop) {
      overlayRef
        .backdropClick()
        .subscribe({ next: () => { flyoutRef.close(); } });
    }

    return flyoutRef;
  }

  protected override createOverlay(flyoutConfig: CustomOverlayConfig): OverlayRef {
    const overlayConfig = this.buildOverlayConfig(flyoutConfig);

    return this.overlay.create(overlayConfig);
  }

  private buildOverlayConfig(flyoutConfig: CustomOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .right()
      .top();

    const overlayConfig: OverlayConfig = {

      panelClass: 'mp-flyout',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,

      ...flyoutConfig.overlayConfig
    };

    return overlayConfig;
  }
}
