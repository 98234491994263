import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { filter, map, pluck, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ImageLoaderService, ImageTypeUtils } from '@core/shared/data-access';
import { Profil, ProfilActions, ProfilPartialState, ProfilService, UpdateProfil, profilSelectQuery } from '@mp/shared/profil/data-access';
import { NotificationService } from '@mp/shared/data-access';

@Injectable()
export class ProfilFacade {

  profil$ = this.store$.pipe(
    select(profilSelectQuery.PROFIL),
    filter(profil => !!profil),
    shareReplay(1)
  );

  isLoading$ = this.store$.pipe(
    select(profilSelectQuery.IS_LOADING)
  );

  name$ = this.profil$
    .pipe(
      filter(profil => !!profil),
      map(profil => profil as Profil),
      map(profile => `${profile.vorname} ${profile.nachname}`),
      shareReplay(1)
    );

  image$ = this.profil$
    .pipe(
      filter(profil => !!profil),
      map(profil => profil as Profil),
      map(profile => profile.bild ?
        `api/uploads/images/${profile.bild}` :
        undefined
      ),
      shareReplay(1)
    );

  activeOrganisation$ = this.profil$
    .pipe(
      filter(profil => !!profil),
      map(profil => profil as Profil),
      pluck('activeOrganisation'),
      shareReplay(1)
    );

  organisationLength$ = this.profil$
    .pipe(
      filter(profil => !!profil),
      map(profil => profil as Profil),
      map(profile => profile.organisationen.length),
      shareReplay(1)
    );

  constructor(
    private readonly store$: Store<ProfilPartialState>,
    private readonly imageLoader: ImageLoaderService,
    private readonly profilService: ProfilService,
    private readonly toaster: NotificationService
  ) { }

  reload(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.reload());
  }

  update(updateDto: UpdateProfil): void {
    this.store$.dispatch(ProfilActions.COMPONENT.update({ updateDto }));
  }

  cancelUpdate(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.cancelUpdate());
  }

  uploadProfilePictureAndGetImageId(file: File): Observable<string> {
    return this.imageLoader.uploadImage(file, ImageTypeUtils.DEFAULT_ALLOWED_TYPES);
  }

  changePassword(passwort: string): void {
    this.profilService
      .changePassword(passwort)
      .subscribe({ next: () => { this.toaster.toastSuccess('Passwort wurde erfolgreich geändert!'); } });
  }
}
