import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DragHandleComponent } from './drag-handle/drag-handle.component';

import {
  EntityItemActionsSlotComponent,
  EntityItemContentLabelSlotComponent,
  EntityItemContentMetaSlotComponent,
  EntityItemContentSlotComponent,
  EntityItemFooterSlotComponent,
  EntityItemIconSlotComponent,
} from './entity-item-slots';
import { EntityItemSlotsComponent } from './entity-item-slots.component';
import { EntityItemStatusComponent } from './entity-status/entity-item-status.component';

@NgModule({
  imports: [
    CommonModule,

    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    EntityItemSlotsComponent,

    EntityItemIconSlotComponent,
    EntityItemContentSlotComponent,
    EntityItemContentLabelSlotComponent,
    EntityItemContentMetaSlotComponent,
    EntityItemActionsSlotComponent,
    EntityItemStatusComponent,
    EntityItemFooterSlotComponent,

    DragHandleComponent,
  ],
  declarations: [
    EntityItemSlotsComponent,

    EntityItemIconSlotComponent,
    EntityItemContentSlotComponent,
    EntityItemContentLabelSlotComponent,
    EntityItemContentMetaSlotComponent,
    EntityItemActionsSlotComponent,
    EntityItemStatusComponent,
    EntityItemFooterSlotComponent,

    DragHandleComponent,
  ],
  providers: [],
})
export class EntityItemSlotsModule {}
