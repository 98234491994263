<mat-card class="widget">
  <div class="widget-header">
    <mat-icon *ngIf="icon" class="widget-header__icon material-icons-outlined" [mpIcon]="icon"></mat-icon>

    <h3 class="widget-header__title truncate">{{ title }}</h3>

    <div
      *ngIf="showSearchField"
      class="widget-header__search-field-container"
      [title]="actionTitle"
    >
      <mp-search-field
        [disabled]="!enabledByPermission"
        #searchField
        appearance="raised"
        placeholder="Durchsuchen"
        (searched)="handleSearch($event)"
        (valueChanges)="handleSearchTermChange($event)"
      >
        <button
          mat-icon-button
          mp-search-field-button="right"
          *ngIf="searchField.value.length > 0"
          (click)="searchField.clear()"
        >
          <mat-icon>clear</mat-icon>
        </button>

        <button
          mat-icon-button
          mp-search-field-button="right"
          [disabled]="searchField.value.length < 2"
          (click)="searchField.search(searchField.value)"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mp-search-field>
    </div>

    <ng-content select="widget-header-element"></ng-content>

    <a
      *ngIf="featurePageLink"
      mat-icon-button
      class="widget-header__link-button"
      color="accent"
      [title]="actionTitle"
      [disabled]="!enabledByPermission"
      (click)="onLinkClick()"
    >
      <mat-icon>north_east</mat-icon>
    </a>
  </div>

  <div class="widget-content">
    <ng-content></ng-content>
  </div>

  <ng-content select="[footer]"></ng-content>
</mat-card>
