import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { QueryParams } from '@core/shared/util';
import { PageResponse, Pagination } from '@mp/shared/data-access';

import { Observable } from 'rxjs';
import { CreateNewsfeed, Newsfeed, UpdateNewsfeed } from './newsfeed';
import { MedicalShopKennzahlenDto } from './widget-data/medical-shop-widget-data';

@Injectable()
export class DashboardService {
  private readonly newsfeedUrl = '/api/newsfeed';

  constructor(private readonly http: HttpClient) {}

  getStatistics(): Observable<MedicalShopKennzahlenDto> {
    return this.http.get<MedicalShopKennzahlenDto>('/api/kennzahlen/medicalshop/artikel');
  }

  getAnzahlBenutzer(): Observable<number> {
    return this.http.get<number>('/api/kennzahlen/my/organisation');
  }

  getSingleNews(newsId: number): Observable<Newsfeed> {
    return this.http.get<Newsfeed>(`${this.newsfeedUrl}/${newsId}`);
  }

  getNews(requestParams: Partial<Pagination> = {}): Observable<PageResponse<Newsfeed>> {
    const { page = 1, pageSize = 10 } = requestParams;
    const params = QueryParams.build<Newsfeed>().page(page).pageSize(pageSize).toHttpParams();
    return this.http.get<PageResponse<Newsfeed>>(`${this.newsfeedUrl}`, { params });
  }

  createNews(news: CreateNewsfeed): Observable<Newsfeed> {
    return this.http.post<Newsfeed>(`${this.newsfeedUrl}`, news);
  }

  updateNews({ id, titel, nachricht }: UpdateNewsfeed): Observable<Newsfeed> {
    return this.http.put<Newsfeed>(`${this.newsfeedUrl}/${id}`, { titel, nachricht });
  }

  deleteNews(newsId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.newsfeedUrl}/${newsId}`);
  }
}
