<div
  matRipple
  class="card"
  [class.active]="selected"
  [class.disabled]="disabled">
  <ng-content></ng-content>
</div>
<div *ngIf="selected" class="selection">
  <mat-icon color="primary">check_circle</mat-icon>
</div>
