export enum AdressTyp {
  Nichts = 0,
  Postanschrift = 1,
  Lieferadresse = 2,
  Rechnungsadresse = 4,
  Sonstiges = 8
}

export namespace AdressTyp {
  export function contains(typ: AdressTyp, containedTyp: AdressTyp): boolean {
    return (typ & containedTyp) !== 0;
  }
}