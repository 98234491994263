import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { RelationListComponent } from './relation-list.component';
import { RelationListItemComponent } from './relation-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    RelationListComponent,
    RelationListItemComponent
  ],
  exports: [
    RelationListComponent,
    RelationListItemComponent
  ]
})
export class RelationListModule { }
