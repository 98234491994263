/* eslint-disable @angular-eslint/component-max-inline-declarations */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndustryCatalogType } from '@mp/content-manager/industriekataloge/data-access';
import { PlatformSupplierBasic } from '@mp/lieferanten-manager/lieferanten/data-access';

@Component({
  selector: 'mp-katalog-lieferant-titel',
  template: ` <ng-container
      *ngIf="editMode; then katalogLieferantInput; else katalogLieferant"
    >
    </ng-container>

    <ng-template #katalogLieferantInput>
      <mp-lieferanten-autocomplete-input
        [label]="label"
        [value]="lieferant"
        [placeholder]="placeholder"
        [icon]="icon"
        [disabled]="disabled"
        (valueChanges)="onLieferantChanges($event)"
      >
      </mp-lieferanten-autocomplete-input>
    </ng-template>

    <ng-template #katalogLieferant>
      <span class="typ-icon">
        <mp-katalogtyp-icon [typ]="katalogtyp"></mp-katalogtyp-icon>
        <h2 *ngIf="lieferant" class="katalog-lieferant">
          {{ lieferant?.name }}
        </h2>
        <mp-lieferanten-autocomplete-input
          *ngIf="!lieferant"
          label="Kein Schreibrecht"
          [disabled]="true"
        >
        </mp-lieferanten-autocomplete-input>
      </span>
    </ng-template>`,
  styleUrls: ['./katalog-lieferant-titel.component.scss'],
})
export class KatalogLieferantTitelComponent {
  @Input() lieferant?: PlatformSupplierBasic;
  @Input() editMode = false;
  @Input() disabled = false;
  @Input() katalogtyp?: IndustryCatalogType;
  @Input() label = 'Plattformlieferanten zuordnen';
  @Input() placeholder = 'Plattformlieferanten zuordnen';
  @Input() icon = 'local_shipping';

  @Output() readonly lieferantChanges = new EventEmitter<
    PlatformSupplierBasic | undefined
  >();

  onLieferantChanges(lieferant?: PlatformSupplierBasic): void {
    this.lieferantChanges.next(lieferant);
  }
}
