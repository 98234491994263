import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Profil, ProfilActions, ProfilPartialState, profilSelectQuery } from '@mp/shared/profil/data-access';
import { LocalStorageService } from '@core/shared/data-access';

@Injectable()
export class AppFacade {

  readonly profil$: Observable<Profil | undefined>;
  readonly shouldSwitch$: Observable<boolean>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly profilStore$: Store<ProfilPartialState>
  ) {
    this.profil$ = this.profilStore$
      .pipe(
        select(profilSelectQuery.PROFIL),
        first(profil => !!profil)
      );

    this.shouldSwitch$ = this.route.queryParamMap
      .pipe(
        map(paramMap => paramMap.has('switch'))
      );
  }

  loadProfileAndStoreActiveOrganisationId(): void {
    this.profil$
      .pipe(
        filter(profil => !!profil),
        map(profil => profil as Profil)
      )
      .subscribe({
        next: ({ activeOrganisationId, organisationen }: Profil) => {
          if (organisationen.length === 0) {
            this.showNoOrganisationErrorPage();
          }

          const hasSingleOrganisation = organisationen.length === 1;
          const hasActiveOrganisation = activeOrganisationId !== -1;
          const hasNoMatchingActiveOrganisationInLocalStorage = !this.isActiveOrganisationIdInLocalStorage(activeOrganisationId);

          if (hasSingleOrganisation &&
              hasActiveOrganisation &&
              hasNoMatchingActiveOrganisationInLocalStorage) {

            this.profilStore$.dispatch(ProfilActions.COMPONENT.selectActiveOrganisation({
              organisationId: activeOrganisationId,
              rememberSelectedOrganisation: false,
              referrerUrl: self.location.href
            }));
          }

          if(organisationen.length > 1 && !this.isActiveOrganisationIdInLocalStorage(activeOrganisationId)) {
            this.router.navigate(['/'], { queryParams: { switch: 'organisation' } });
          }
        }
      });
    this.profilStore$.dispatch(ProfilActions.COMPONENT.loadInitial());
  }

  private showNoOrganisationErrorPage(): void {
    window.location.assign('/assets/no-organisation.html');
  }

  private isActiveOrganisationIdInLocalStorage(activeOrganisationId: number): boolean {
    try {
      const storedValue = this.localStorage.tryReading<number>('activeOrganisationId');
      return storedValue === activeOrganisationId;
    } catch (error: unknown) {
      return false;
    }
  }
}
