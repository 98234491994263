import { NgModule } from '@angular/core';
import {
  PendingRequisitionCountsWidgetComponent,
  RequisitionsHistoryWidgetComponent,
} from './components';
import { RequisitionsMetricsService } from './services';

@NgModule({
  imports: [PendingRequisitionCountsWidgetComponent, RequisitionsHistoryWidgetComponent],
  exports: [PendingRequisitionCountsWidgetComponent, RequisitionsHistoryWidgetComponent],
  providers: [RequisitionsMetricsService],
})
export class DocumentManagementDashboardModule {}
