import { Optional } from 'utility-types';

export interface FilterItem<T> {
  value: T;
  key: number | string;
  label: string;

  selected: boolean;
  disabled: boolean;

  stats?: number;
  icon?: string;
}

export type FilterItemConfig<T> = Optional<FilterItem<T>, 'selected' | 'disabled'>;
export class CheckboxFilterItem<T> implements FilterItem<T> {

  key: string | number;
  value: T;
  label: string;

  selected: boolean;
  disabled: boolean;

  stats?: number;
  icon?: string;

  constructor (params: FilterItemConfig<T>) {
    this.key = params.key;
    this.value = params.value;
    this.label = params.label ?? params.key.toString();

    this.selected = params.selected ?? false;
    this.disabled = params.disabled ?? false;
    this.stats = params.stats ?? undefined;
    this.icon = params.icon ?? undefined;
  }
}

export interface FilterSection<T = unknown, Data = unknown> {
  key: number | string;
  label: string;
  icon?: string;
  options: Array<FilterItem<T>>;

  data?: Data;
  searchable?: boolean;
  disabled?: boolean;
  multiselect?: boolean;
}

export type FilterSectionSelection = Record<number | string, Array<unknown>>;
export type FilterSectionStats = Record<number | string, Record<number | string, number | undefined>>;
