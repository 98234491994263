import { Pipe, PipeTransform } from '@angular/core';

import { EntityIcons } from './entity-icons';
import { EntityType } from './entity-type';

@Pipe({ name: 'entityIcon' })
export class EntityIconPipe implements PipeTransform {
  
  transform(icon: string, type?: EntityType): string {
    return !type ? icon : EntityIcons[type];
  }

}