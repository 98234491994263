import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject } from 'rxjs';
import { filter, map, pairwise, takeUntil, throttleTime } from 'rxjs/operators';
import { DeletedFavoriteListArticle } from '../../models';

@Component({
  selector: 'mpcm-deleted-favorites-list',
  templateUrl: './deleted-favorites-list.component.html',
  styleUrls: ['./deleted-favorites-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    ScrollingModule,

    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class DeletedFavoritesListComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') readonly class = 'mpcm-deleted-favorites-list';

  @Input() isLoading = false;

  @Input() set deletedArticles(deletedArticles: DeletedFavoriteListArticle[]) {
    this._deletedArticles = deletedArticles;
    this.setListHeight();
  }

  get deletedArticles(): Array<DeletedFavoriteListArticle> {
    return this._deletedArticles;
  }

  @Input() totalRows = 0;
  @Output() readonly articleDelete = new EventEmitter<string>();
  @Output() readonly nextPageScroll = new EventEmitter<void>();
  @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;

  listHeight = '';

  get hasMoreRows(): boolean {
    return this.totalRows > this.deletedArticles.length;
  }

  readonly ITEM_SIZE = 45;

  private readonly INTERVAL_DELAY_MS = 200;
  private readonly MAX_ELEMENTS_VISIBLE = 5;
  private readonly NEXT_PAGE_SCROLL_POSITION = this.ITEM_SIZE * 2;

  private _deletedArticles: Array<DeletedFavoriteListArticle> = [];
  private destroy$ = new Subject<void>();

  constructor(private readonly ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.setScrollerSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onArticleDelete(articleRecordId: string): void {
    this.articleDelete.emit(articleRecordId);
  }

  trackByFn(index: number, item: DeletedFavoriteListArticle): string {
    return item.articleRecordId;
  }

  private setListHeight(): void {
    const height =
      Math.min(this.deletedArticles.length, this.MAX_ELEMENTS_VISIBLE) * this.ITEM_SIZE;
    this.listHeight = `${height}px`;
  }

  private isScrolledToNextPage(prevScrollPos: number, currScrollPos: number): boolean {
    return currScrollPos < prevScrollPos && currScrollPos < this.NEXT_PAGE_SCROLL_POSITION;
  }

  private setScrollerSubscription(): void {
    this.scroller
      .elementScrolled()
      .pipe(
        filter(() => this.hasMoreRows && !this.isLoading),
        map(() => this.scroller.measureScrollOffset('bottom') || 0),
        pairwise(),
        filter(([prevScrollPos, currScrollPos]) =>
          this.isScrolledToNextPage(prevScrollPos, currScrollPos)
        ),
        throttleTime(this.INTERVAL_DELAY_MS),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.ngZone.run(() => this.nextPageScroll.emit());
      });
  }
}
