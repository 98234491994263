import { Injectable } from '@angular/core';

type ScrollPositions = Record<string, number>;

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionService {
  private readonly key = 'ScrollTopPosition';

  get scrollPositions(): ScrollPositions {
    return JSON.parse(
      window.sessionStorage.getItem(this.key) ||
        JSON.stringify(this.defaultScrollPositions)
    );
  }

  saveScrollPosition(obj: ScrollPositions): void {
    this.save({
      ...this.scrollPositions,
      ...obj,
    });
  }

  private save(obj: ScrollPositions): void {
    window.sessionStorage.setItem(this.key, JSON.stringify(obj));
  }

  private get defaultScrollPositions(): ScrollPositions {
    return { filters: 0, content: 0 };
  }
}
