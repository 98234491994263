import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PageResponse, Pagination } from '@mp/shared/data-access';
import { PlatformSupplier, PlatformSupplierBasic, UpdatePlatformSupplier } from './platformsupplier';
import { QueryParams } from '@core/shared/util';


@Injectable()
export class PlatformSupplierService {

  private readonly baseApiPath = '/api/contentmanagement/suppliers/platformsuppliers';

  constructor (private readonly httpClient: HttpClient) {
  }

  getAll(searchTerm?: string, pagination?: Partial<Pagination>): Observable<PageResponse<PlatformSupplierBasic>> {
    const params = QueryParams.build<PlatformSupplierBasic>();

    if (searchTerm) {
      params.param('searchTerm', searchTerm);
    }

    params
      .page(pagination?.page ?? 1)
      .pageSize(pagination?.pageSize ?? 250)
      .sort('name');

    return this.httpClient.get<PageResponse<PlatformSupplierBasic>>(
      `${this.baseApiPath}`,
      { params: params.toHttpParams() }
    );
  }

  get(id: string): Observable<PlatformSupplier> {
    return this.httpClient.get<PlatformSupplier>(`${this.baseApiPath}/${id}`);
  }

  update(id: string, dto: UpdatePlatformSupplier): Observable<PlatformSupplier> {
    return this.httpClient.put<PlatformSupplier>(`${this.baseApiPath}/${id}`, dto);
  }

}
