import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { DropdownButtonComponent } from './dropdown-button';

@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatButtonModule,
    MatMenuModule
  ],
  declarations: [DropdownButtonComponent],
  exports: [DropdownButtonComponent]
})
export class DropdownButtonModule {

}