import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { HierarchieEditorFacade } from './hierarchie-editor.facade';
import { OrganisationsHierarchieNodeComponent } from './node/organisationshierarchie-node.component';
import { OrganisationsHierarchienFlyoutFacade } from './organisationshierarchien-flyout.facade';
import { OrganisationshierarchieEditorComponent } from './editor/organisationshierarchie-editor.component';
import { ReactiveComponentModule } from '@ngrx/component';

@NgModule({
  exports: [OrganisationshierarchieEditorComponent],
  declarations: [
    OrganisationshierarchieEditorComponent,
    OrganisationsHierarchieNodeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ReactiveComponentModule
  ],
  providers: [
    HierarchieEditorFacade,
    OrganisationsHierarchienFlyoutFacade
  ]
})
export class OrganisationshierarchieEditorModule { }