import { NgModule } from '@angular/core';

import { NoPropagationDirective } from './no-propagation.directive';

@NgModule({
  declarations: [
    NoPropagationDirective
  ],
  exports: [
    NoPropagationDirective
  ]
})
export class NoPropagationModule {}
