import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { AlertIconDirective, AlertTitleDirective } from './alert.directive';
import { AlertComponent } from './alert.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [
    AlertComponent,
    AlertIconDirective,
    AlertTitleDirective
  ],
  exports: [AlertComponent]
})
export class AlertModule { }
