import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ORGANISATIONEN_FEATURE_KEY, OrganisationenState } from './organisationen.reducer';

const getOrganisationenState = createFeatureSelector<OrganisationenState>(ORGANISATIONEN_FEATURE_KEY);

const IS_LOADING = createSelector(
  getOrganisationenState,
  state => state.isLoading
);

const LIST = createSelector(
  getOrganisationenState,
  state => state.entities
);

const SELECTED = createSelector(
  getOrganisationenState,
  state => state.selected
);

export const organisationenSelectors = {
  IS_LOADING,
  SELECTED,
  LIST
};
