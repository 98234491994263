import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ReactiveComponentModule } from '@ngrx/component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FilterItemSelectorComponent } from './component/filter-item-selector.component';
import { SearchFieldModule } from '../search-field/search-field.module';
import { UtilPipesModule } from '@core/shared/util';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveComponentModule,

    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    ScrollingModule,

    UtilPipesModule,
    SearchFieldModule
  ],
  declarations: [FilterItemSelectorComponent],
  exports: [FilterItemSelectorComponent]
})
export class FilterItemSelectorModule {

}
