import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterFacade } from '@core/shared/data-access';
import { SearchFieldModule } from '@core/ui';
import { DashboardService } from '@mp/dashboard/data-access';
import { AnforderungenService } from '@mp/medical-shop/auftraege/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { BenutzerService } from '@mp/system/benutzer/data-access';
import {
  ArticleCountsBaseWidgetComponent,
  ContentManagementDashboardModule,
  CountUpStatComponent,
  DatasourceUploadWidgetModule,
} from '@mpcm/shared';
import { DocumentManagementDashboardModule } from '@mpdm/shared';
import { MedicalShopDashboardModule } from '@mpms/shared';
import { DashboardComponent } from './dashboard.component';
import { DashboardFacade } from './dashboard.facade';
import { HasRequisitionsWidgetRightsPipe } from './has-requisitions-widget-rights.pipe';
import { NewsfeedFacade } from './newsfeed.facade';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';

import { AnforderungenHistorieWidgetComponent } from './widgets/anforderungen-historie/anforderungen-historie-widget.component';
import { AnforderungenOffenWidgetComponent } from './widgets/anforderungen-offen/anforderungen-offen-widget.component';
import { MedicalShopWidgetComponent } from './widgets/medical-shop/medical-shop-widget.component';
import { MeineOrganisationWidgetComponent } from './widgets/meine-organisation/meine-organisation-widget.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,

    SearchFieldModule,
    UserInfoModule,
    DatasourceUploadWidgetModule,
    DashboardWidgetComponent,
    ContentManagementDashboardModule,
    DocumentManagementDashboardModule,
    MedicalShopDashboardModule,
    HasRequisitionsWidgetRightsPipe,
    CountUpStatComponent,
    ArticleCountsBaseWidgetComponent,
    AnforderungenHistorieWidgetComponent,
    AnforderungenOffenWidgetComponent,
    MedicalShopWidgetComponent,
    MeineOrganisationWidgetComponent,
  ],
  declarations: [DashboardComponent, NewsfeedComponent],
  exports: [DashboardComponent],
  providers: [
    AnforderungenService,
    BenutzerService,
    DashboardFacade,
    DashboardService,
    NewsfeedFacade,
    RouterFacade,
  ],
})
export class DashboardModule {}
