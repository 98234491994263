import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerModule } from '@core/ui';
import { ApiUrlService } from '@mp/shared/data-access';
import { ArticleIdentifier } from '../../../models';

export enum ArticleImageState {
  Loading,
  Loaded,
  Error,
}

/**
 * Component to display a (currently the first) image of an article.
 */
@Component({
  selector: 'mpcm-article-image',
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,

    MatIconModule,

    SpinnerModule,
  ],
})
export class ArticleImageComponent {

  public readonly ArticleImageState = ArticleImageState;
  private _articleId?: ArticleIdentifier | null;

  @HostBinding('class')
  readonly class = 'mpcm-article-image';

  /**
   * The identifier of the article.
   */
  get articleId(): ArticleIdentifier | null {
    return this._articleId || null;
  }
  @Input()
  set articleId(value: ArticleIdentifier | null | undefined) {
    this._articleId = value || null;
    this.state = this._articleId ? ArticleImageState.Loading : ArticleImageState.Error;
  }

  /**
   * The alternative text to display.
   */
  @Input() alternateText?: string;

  state = ArticleImageState.Loading;

  constructor(
    private readonly apiUrlService: ApiUrlService) {
  }

  get imagePath(): string {
    if (!this._articleId) {
      return '';
    }

    const { articleRecordId, articleType } = this._articleId;
    return this.apiUrlService.getApiUrl(`/api/contentmanagement/articles/images/${articleRecordId}?articleType=${articleType}`, true);
  }

  onImgLoad(): void {
    if (this.state === ArticleImageState.Loading) {
      this.state = ArticleImageState.Loaded;
    }
  }

  onImgError(): void {
    this.state = ArticleImageState.Error;
  }
}
