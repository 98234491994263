import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';

import { InputModule } from '../input/input.module';
import { OverviewComponent } from './overview.component';
import { OverviewItemComponent } from './overview-item.component';
import { RelationListModule } from '../relation-list/relation-list.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ViewModule } from '../view/view.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    ScrollingModule,
    MatRippleModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    SpinnerModule,
    ViewModule
  ],
  declarations: [
    OverviewItemComponent,
    OverviewComponent
  ],
  exports: [
    LayoutModule,
    ScrollingModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    SpinnerModule,
    InputModule,
    ViewModule,
    RelationListModule,
    OverviewItemComponent,
    OverviewComponent
  ]
})
export class OverviewModule { }
