<mpcm-article-counts-base-widget
  *ngIf="articleCounts$ | async as articleCounts"
  title="Medical Shop"
  icon="store"
  [featurePageLink]="['/medical-shop2/']"
  [data]="articleCounts"
  [enabledByPermission]="true"
  (searchTermChange)="handleSearch($event)"
>
</mpcm-article-counts-base-widget>
