import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { KatalogDatenquelle } from './katalog-datenquelle';
import { PageResponse } from '@mp/shared/data-access';

@Injectable({ providedIn: 'root' })
export class KatalogDatenquellenService {
  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<PageResponse<KatalogDatenquelle>> {
    return this.http.get<PageResponse<KatalogDatenquelle>>('/api/core/datenquellen');
  }
}
