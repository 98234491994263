import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatAutocomplete } from '@angular/material/autocomplete';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { SelectionItem } from '../flyout/selector/selection-item/selection-item';

@Component({
  selector: 'mp-entity-autocomplete',
  exportAs: 'mpEntityAutocomplete',
  templateUrl: './entity-autocomplete.component.html',
})
export class EntityAutocompleteComponent<T = unknown> implements OnInit, OnDestroy {
  readonly minSearchTermLength = 3;
  readonly class = 'mp-entity-autocomplete';

  // TODO: Document why { static: true } is needed here (ExpressionChangedAfterItHasBeenCheckedError)
  @ViewChild(MatAutocomplete, { static: true })
  public matAutocomplete!: MatAutocomplete;

  @Input() isLoading = false;

  @Input()
  get autoActiveFirstOption() {
    return this._autoActiveFirstOption;
  }
  set autoActiveFirstOption(value: BooleanInput) {
    this._autoActiveFirstOption = coerceBooleanProperty(value);
  }
  private _autoActiveFirstOption = false;

  @Input() panelWidth?: string | number;

  @Input() set className(className: string) {
    this.classObj = { [className]: true };
  }

  classObj: { [key: string]: boolean } = {};

  @Input() optionTemplate?: TemplateRef<unknown>;
  @Input() set options(newOptions: Array<SelectionItem<T>>) {
    if (newOptions) {
      this._options$.next(newOptions);
    }
  }

  /**
   * Debounce time, between two search terms.
   *
   * ---
   * **NOTE:** Changing this property after component instanciation does NOT have any effect!
   *
   * This property will only be set once during initialization.
   */
  @Input() debounceTime = 0;

  @Output() readonly optionSelected = new EventEmitter<SelectionItem<T>>();
  @Output() readonly optionActivated = new EventEmitter<SelectionItem<T>>();

  lastSelected?: SelectionItem<T>;

  private readonly _options$ = new BehaviorSubject<Array<SelectionItem<T>>>([]);
  readonly options$ = this._options$.asObservable();
  readonly searchTerm$ = new BehaviorSubject('');

  private readonly isDestroyed$ = new Subject<void>();

  @Input() displayFn(item?: SelectionItem<T>): string | undefined {
    return item?.header;
  }

  @Input() optionsFetcher(
    searchTerm: string | undefined,
    options?: Array<SelectionItem<T>>
  ): Array<SelectionItem<T>> | Observable<Array<SelectionItem<T>>> {
    if (!options) {
      return [];
    }
    if (!searchTerm) {
      return options;
    }

    return options.filter((item) => {
      const lowercaseHeader = item.header.toLowerCase();
      const lowercaseSubheader = item.subheader?.toLowerCase() ?? '';
      const lowercaseSearchTerm = searchTerm.toLowerCase();

      return (
        lowercaseHeader.includes(lowercaseSearchTerm) ||
        lowercaseSubheader.includes(lowercaseSearchTerm)
      );
    });
  }

  ngOnInit(): void {
    this.initSearchTermListener();
  }

  private initSearchTermListener(): void {
    this.searchTerm$
      .pipe(
        tap(() => (this.isLoading = true)),
        debounceTime(this.debounceTime),
        switchMap((searchTerm) => {
          if (searchTerm.length < this.minSearchTermLength) {
            return of([]);
          }

          const fetcherResult$ = this.optionsFetcher(searchTerm, this._options$.getValue());
          return Array.isArray(fetcherResult$)
            ? of(fetcherResult$).pipe(delay(3000))
            : fetcherResult$;
        }),
        takeUntil(this.isDestroyed$)
      )
      .subscribe({
        next: (options) => {
          this.isLoading = false;
          this._options$.next(options);
        },
      });
  }

  filterBy(searchTerm: string | undefined): void {
    this.searchTerm$.next(searchTerm ?? '');
  }

  emitOptionSelected(item: SelectionItem<T>) {
    this.optionSelected.next(item);
    this.lastSelected = item;
  }

  emitOptionActivated(item: SelectionItem<T>) {
    this.optionActivated.next(item);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
