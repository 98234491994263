import { Routes } from '@angular/router';
import { tabTitleResolver } from '@mp/shared/data-access';

import { LandingPageComponent } from './landing-page/landing-page.component';

export const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  component: LandingPageComponent,
  title: tabTitleResolver('Startseite')
}];
