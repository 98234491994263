<div class="mp-active-organisation-selector">
  <div *ngIf="profil$ | async as profil" class="card-wrapper">
    <mp-avatar id="user-avatar"
      [name]="profil.vorname + '' + profil.nachname"
      [image]="profil.bild ? 'api/uploads/images/' + profil.bild : undefined"
      size="large"
      isPresentingOnly>
    </mp-avatar>
    <mat-card>
      <div class="header">

        <h2>Hallo {{ profil.vorname }} {{ profil.nachname}},</h2>
        <p>wir haben mehrere dir zugeordnete Organisationen gefunden.</p>
        <hr>
        <p>
          <strong>
            Im Namen welcher Organisation möchtest du dich anmelden?
          </strong>
        </p>
      </div>
      <div class="card-body">
        <mat-checkbox #notice
                      class="notice-organisation"
                      [(ngModel)]="rememberSelectedOrganisation">
          Ausgewählte Organisation merken
        </mat-checkbox>
        <mat-nav-list>
          <mat-list-item  *ngFor="let organisation of profil.organisationen; last as last;"
                          (click)="switchToOrganisation(organisation.id)">

            <!-- This is here so that the divider inset works -->
            <img mat-list-avatar style="display: none">

            <ng-container mat-list-avatar>
              <mp-avatar
                [image]="organisation.bild"
                [name]="organisation.name"
                size="small">
              </mp-avatar>
            </ng-container>

            <h3 mat-line>{{ organisation.name }}</h3>
            <p mat-line class="truncate">{{ organisation.rollen | map:getRolleName | prettyPrintList:2 }}</p>

            <button mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>

            <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-card>
  </div>
</div>
