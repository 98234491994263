<mp-dashboard-widget
  title="Meine Organisation"
  icon="home_work"
  [featurePageLink]="['/organisation/benutzer']"
>
  <ng-container *ngIf="data">
    <div class="content-row">
      <mpcm-count-up-stat
        class="benutzer-col"
        [statValue]="data.benutzerAnzahl"
        [statLabel]="'Benutzer'"
      ></mpcm-count-up-stat>

      <div class="lizenz-col">
        <div class="stat">{{ data.lizenz }}</div>
        <span class="label">Lizenz</span>
      </div>
    </div>
  </ng-container>
</mp-dashboard-widget>
