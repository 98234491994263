import { createAction, props } from '@ngrx/store';

import { CreateOrganisation, Organisation, UpdateOrganisation } from '../organisation';
import { PageResponse } from '@mp/shared/data-access';
import { QueryParams } from '@core/shared/util';

const loadAll = createAction(
  '[Organisationen List Component] Load ALL Organisationen',
  props<{ queryParams?: QueryParams<Organisation, Partial<Organisation>> }>()
);

const loadedAllSuccessfully = createAction(
  '[Organisationen Service] Loaded All Organisationen',
  props<{ loadedOrganisationenPage: PageResponse<Organisation> }>()
);

const create = createAction(
  '[Organisationen Create Component] Create Organisation',
  props<{ organisationToCreate: CreateOrganisation }>()
);

const createdSuccessfully = createAction(
  '[Organisationen Service] Created Organisation',
  props<{ createdOrganisation: Organisation }>()
);

const loadSingle = createAction(
  '[Organisationen Update Component] Load Organisation',
  props<{ queryParams?: QueryParams<Organisation> }>()
);

const loadedSingleSuccessfully = createAction(
  '[Organisationen Service] Loaded Organisation',
  props<{ loadedOrganisation: Organisation }>()
);

const update = createAction(
  '[Organisationen Update Component] Update Organisation',
  props<{ organisationToUpdate: UpdateOrganisation }>()
);

const updatedSuccessfully = createAction(
  '[Organisationen Service] Updated Organisation',
  props<{ updatedOrganisation: Organisation }>()
);

const cancel = createAction(
  '[Organisationen Component] Cancel Process'
);

const canceled = createAction(
  '[Organisationen Service] Process Canceled'
);

export namespace OrganisationenActions {
  export const API = {
    loadedSingleSuccessfully,
    loadedAllSuccessfully,
    createdSuccessfully,
    updatedSuccessfully,
    canceled
  };

  export const COMPONENT = {
    loadSingle,
    loadAll,
    create,
    update,
    cancel
  };
}
