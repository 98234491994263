<div class="avatar-wrapper">

  <ng-template #placeholderTemplate>
    <img  src="assets/Avatar_Placeholder.jpeg"
          class="avatar"
          [class.tiny]="size === 'tiny'"
          [class.small]="size === 'small'"
          [class.medium]="size === 'medium'"
          [class.large]="size === 'large'">
  </ng-template>

  <ng-template #initialsTemplate>
    <ng-container *ngIf="_initials; else placeholderTemplate">
      <div  class="avatar"
            [class.tiny]="size === 'tiny'"
            [class.small]="size === 'small'"
            [class.medium]="size === 'medium'"
            [class.large]="size === 'large'">
        {{ _initials }}
      </div>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="image; else initialsTemplate">
    <img  class="avatar"
          (error)="handleImageLoadError($event)"
          [class.tiny]="size === 'tiny'"
          [class.small]="size === 'small'"
          [class.medium]="size === 'medium'"
          [class.large]="size === 'large'"
          [src]="image">
  </ng-container>

  <ng-container *ngIf="_isUpload">

    <ng-template #loading>
      <mp-spinner></mp-spinner>
    </ng-template>
    <ng-container *ngIf="!isLoading; else loading">
      <button mat-button
              color="accent"
              [matMenuTriggerFor]="menu">
        <mat-icon>add_photo_alternate</mat-icon>
        {{ buttonLabel }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item
              (click)="fileInput.click()">
          {{ selectLabel }}
        </button>
        <button *ngIf="image"
                mat-menu-item
                (click)="clearImage()">
            {{ clearLabel }}
        </button>
      </mat-menu>
    </ng-container>

    <input  #fileInput
            hidden
            type="file"
            [accept]="acceptedFormats"
            (change)="onFileChange($event)">

  </ng-container>

</div>
