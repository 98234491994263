import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { CreateOrganisationBenutzer, CreateRolleBenutzer, RolleBenutzer, UpdateOrganisationBenutzer } from '../organisation-benutzer';
import { EffectsBase, NotificationService, PageResponse } from '@mp/shared/data-access';
import { OrganisationBenutzer } from '../..';
import { OrganisationBenutzerActions } from './organisation-benutzer.actions';
import { OrganisationBenutzerService } from '../organisation-benutzer.service';
import { getProp } from '@core/shared/util';


@Injectable()
export class OrganisationBenutzerEffects extends EffectsBase {

  loadSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.loadSingle),
      exhaustMap(({ queryParams }) =>
        this.getRouterParamIdByKey('benutzerId').pipe(
          switchMap(id => this.service.get(id, queryParams)),
          map((benutzer: Partial<OrganisationBenutzer>) => ({ benutzer, error: null })),
          catchError((error: unknown) => of({ benutzer: null, error }))
        )
      ),
      map(({ benutzer, error }) => {
        const loadedBenutzer: OrganisationBenutzer = benutzer as OrganisationBenutzer;
        return !error ?
          OrganisationBenutzerActions.API.loadedSingleSuccessfully({ loadedBenutzer }) :
          OrganisationBenutzerActions.API.loadedSingleUnsuccessfully({ error });
      })
    );
  });

  loadedSingleUnsuccessfully$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.API.loadedSingleUnsuccessfully)
    );
  }, { dispatch: false });

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.loadAll),
      exhaustMap(({ queryParams }) => this.service.getAll(queryParams)),
      map((loadedPage: PageResponse<Partial<OrganisationBenutzer>>) => {
        const loadedBenutzerPage = loadedPage as PageResponse<OrganisationBenutzer>;
        return OrganisationBenutzerActions.API.loadedAllSuccessfully({ loadedBenutzerPage });
      })
    );
  });

  loadedAllUnsuccessfully$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.API.loadedAllUnsuccessfully)
    );
  }, { dispatch: false });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.create),
      mergeMap(({ benutzerToCreate, rollenToAdd, kostenstellenToAdd }) =>
        this.createBenutzerAndRelations(
          benutzerToCreate,
          rollenToAdd,
          kostenstellenToAdd
        )
      ),
      map(([createdBenutzer, addedRollen, addedKostenstellen]) =>
        OrganisationBenutzerActions.API.createdSuccessfully({
          createdBenutzer,
          addedRollen,
          addedKostenstellen
        })),
      tap(() => {
        this.navigateBack();
      })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.update),
      mergeMap(({ benutzerToUpdate, rollenToAdd, rollenToRemove, kostenstellenToAdd, kostenstellenToRemove }) =>
        this.updateBenutzerAndRelations(
          benutzerToUpdate,
          rollenToAdd,
          rollenToRemove,
          kostenstellenToAdd,
          kostenstellenToRemove
        ).pipe(
          map(result => ({ result, error: null })),
          catchError((error: unknown) => of({ result: [], error }))
        )
      ),
      map(({ result, error }) => {
        const [updatedBenutzer, addedRollen, removedRollen, addedKostenstellen, removedKostenstellen] = result;

        return !error ? OrganisationBenutzerActions.API.updatedSuccessfully({
            updatedBenutzer,
            addedRollen,
            removedRollen,
            addedKostenstellen,
            removedKostenstellen
          }) :
          OrganisationBenutzerActions.API.updatedUnsuccessfully({ error });
      }),
      tap(() => {
        this.navigateBack();
      })
    );
  });

  cancelUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.cancelUpdate),
      map(OrganisationBenutzerActions.API.canceledUpdate),
      tap(() => {
        this.navigateBack();
      })
    );
  }
  );

  cancelCreate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationBenutzerActions.COMPONENT.cancelCreate),
      map(OrganisationBenutzerActions.API.canceledCreate),
      tap(() => {
        this.navigateBack();
      })
    );
  });

  constructor(
    injector: Injector,
    private readonly actions$: Actions,
    private readonly service: OrganisationBenutzerService,
    private readonly toaster: NotificationService
  ) {
    super(injector);
  }

  private createBenutzerAndRelations(
    benutzerToCreate: CreateOrganisationBenutzer,
    rollenToAdd: Array<CreateRolleBenutzer> = [],
    kostenstellenToAdd: Array<number> = []
  ): Observable<[OrganisationBenutzer, Array<boolean>, Array<boolean>]> {
    return this.service
      .createBenutzer(benutzerToCreate)
      .pipe(
        getProp('data'),
        tap(response => {
          if (response.statusCode === 200) this.toaster.toastSuccess(response.statusMessage);
          else if (response.statusCode === 400) { this.toaster.toastDanger(response.statusMessage); }
        }),
        map(response => response.entity),
        mergeMap((createdBenutzer: OrganisationBenutzer) => forkJoin([
          of(createdBenutzer),
          this.addRollenToBenutzer(createdBenutzer.id, rollenToAdd),
          this.addKostenstellenToBenutzer(createdBenutzer.id, kostenstellenToAdd)
        ]))
      );
  }

  private updateBenutzerAndRelations(
    benutzerToUpdate: UpdateOrganisationBenutzer,
    rollenToAdd: Array<CreateRolleBenutzer> = [],
    rollenToRemove: Array<RolleBenutzer> = [],
    kostenstellenToAdd: Array<number> = [],
    kostenstellenToRemove: Array<number> = []
  ): Observable<[OrganisationBenutzer, Array<boolean>, Array<boolean>, Array<boolean>, Array<boolean>]> {
    return forkJoin([
      this.service.update(benutzerToUpdate),
      this.addRollenToBenutzer(benutzerToUpdate.id, rollenToAdd),
      this.removeRolleFromBenutzer(benutzerToUpdate.id, rollenToRemove),
      this.addKostenstellenToBenutzer(benutzerToUpdate.id, kostenstellenToAdd),
      this.removeKostenstelleFromBenutzer(benutzerToUpdate.id, kostenstellenToRemove)
    ]);
  }

  private addRollenToBenutzer(benutzerId: number, rollenToAdd: Array<CreateRolleBenutzer>): Observable<Array<boolean>> {
    const noRollenToAdd = !rollenToAdd || rollenToAdd.length === 0;
    if (noRollenToAdd) {
      return of([]);
    }

    return this.service.addRollenToBenutzer(rollenToAdd.map(rollen => ({ ...rollen, benutzerId: benutzerId })));
  }

  private removeRolleFromBenutzer(benutzerId: number, rollenIds: Array<RolleBenutzer>): Observable<Array<boolean>> {
    return !rollenIds || rollenIds.length === 0
      ? of([])
      : this.service.removeRollenFromBenutzer(benutzerId, rollenIds);
  }

  private addKostenstellenToBenutzer(benutzerId: number, kostenstellenIds: Array<number>): Observable<Array<boolean>> {
    const noKostenstellenToAdd = !kostenstellenIds || kostenstellenIds.length === 0;
    if (noKostenstellenToAdd) {
      return of([]);
    }

    return this.service.addKostenstellenToBenutzer(benutzerId, kostenstellenIds);
  }

  private removeKostenstelleFromBenutzer(benutzerId: number, kostenstellenIds: Array<number>): Observable<Array<boolean>> {
    const noKostenstellenToRemove = !kostenstellenIds || kostenstellenIds.length === 0;
    if (noKostenstellenToRemove) {
      return of([]);
    }

    return this.service.removeKostenstellenFromBenutzer(benutzerId, kostenstellenIds);
  }
}
