import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

import { AvatarModule } from '../../avatar/avatar.module';

import { EntityItemComponent } from './entity-item.component';

@NgModule({
  imports: [
    CommonModule,

    MatButtonModule,
    MatChipsModule,
    MatIconModule,

    AvatarModule
  ],
  declarations: [
    EntityItemComponent
  ],
  exports: [
    EntityItemComponent
  ]
})

export class EntityItemModule { }
