import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KatalogCovinBadgeComponent } from './katalog-covin-badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [KatalogCovinBadgeComponent],
  exports: [KatalogCovinBadgeComponent]
})
export class KatalogCovinBadgeModule {}
