import { Injectable } from '@angular/core';

import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { EMPTY, from, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  onSessionError$?: Observable<OAuthEvent>;
  onSessionTerminated$?: Observable<OAuthEvent>;

  private readonly _userSuccessfullyLoggedIn$: ReplaySubject<void> =
    new ReplaySubject(1);
  userSuccessfullyLoggedIn$: Observable<void> =
    this._userSuccessfullyLoggedIn$.asObservable();

  constructor(private readonly oauthService: OAuthService) {}

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  setupUsingOAuthConfiguration(authConfig: AuthConfig): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.setupSessionEventHandlers();
  }

  private setupSessionEventHandlers(): void {
    this.onSessionTerminated$ = this.oauthService.events.pipe(
      filter((event) => event.type === 'session_terminated')
    );

    this.onSessionError$ = this.oauthService.events.pipe(
      filter((event) => event.type === 'session_error')
    );
  }

  async executeCodeFlow(): Promise<boolean | undefined> {
    return from(
      // Note: This will actually **exit** the app if no token is present
      this.oauthService.loadDiscoveryDocumentAndLogin()
    )
      .pipe(
        catchError(() => {
          // Error-Handling: Unavailable authorization server
          this.redirectToServerNotAvailablePage();

          return EMPTY;
        })
      )
      .toPromise();
  }

  async executeCodeFlowWithState<S>(state: S): Promise<S | undefined> {
    return from(
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then((success) => {
        if (success) {
          if (!this.oauthService.hasValidAccessToken()) {
            // Note: This will actually **exit** the app if no token is present
            this.oauthService.initCodeFlow(
              state === undefined ? undefined : JSON.stringify(state)
            );
          } else {
            this._userSuccessfullyLoggedIn$.next();
            return this.oauthService.state
              ? (JSON.parse(decodeURIComponent(this.oauthService.state)) as S)
              : state;
          }
        }

        return undefined;
      })
    )
      .pipe(
        catchError(() => {
          // Error-Handling: Unavailable authorization server
          this.redirectToServerNotAvailablePage();

          return EMPTY;
        })
      )
      .toPromise();
  }

  // TODO: Ins Shared-Modul umziehen
  redirectToServerNotAvailablePage(code = 500): void {
    window.location.assign(`/assets/error.html?code=${code}`);
  }

  logout(noRedirectToLogoutUrl = false): void {
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }
}
