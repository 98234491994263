import { Component, HostBinding, Input } from '@angular/core';
import { IndustryCatalogType } from '@mp/content-manager/industriekataloge/data-access';
import { katalogtypIconLookupTable } from './katalogtyp-icon-lookuptable';

@Component({
  selector: 'mp-katalogtyp-icon',
  template: `
    <mat-icon class="material-icons-outlined">
      {{ iconTable[typ ?? 'Unknown'] }}
    </mat-icon>
  `
})
export class KatalogtypIconComponent {
  @HostBinding('class') readonly class = 'mp-katalogtyp-icon';

  @Input() typ?: IndustryCatalogType;

  readonly iconTable = katalogtypIconLookupTable;
}
