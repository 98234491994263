import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { EntityAutocompleteComponent } from './entity-autocomplete.component';
import { UtilPipesModule } from '@core/shared/util';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  imports: [CommonModule, MatAutocompleteModule, UtilPipesModule, SpinnerModule],
  exports: [EntityAutocompleteComponent],
  declarations: [EntityAutocompleteComponent],
})
export class EntityAutocompleteModule {}
