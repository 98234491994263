import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FilterInputToggleComponent } from './filter-input-toggle.component';

@NgModule({
  imports: [
    CommonModule,

    MatCardModule,
    MatIconModule,
    MatSlideToggleModule
  ],
  declarations: [FilterInputToggleComponent],
  exports: [FilterInputToggleComponent]
})
export class FilterInputToggleModule { }