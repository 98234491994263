import { Component, HostBinding } from '@angular/core';
import { Profil } from '@mp/shared/profil/data-access';

import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AppFacade } from '../app.facade';

@Component({
  selector: 'mp-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  @HostBinding('class') readonly classes = 'mp-landing-page';

  readonly profil$: Observable<{ vorname: string; nachname: string; }>;

  constructor(private readonly facade: AppFacade) {
    this.profil$ =
      this.facade
        .profil$
        .pipe(
          filter(profil => !!profil),
          map(profil => profil as Profil),
          map(({ vorname, nachname }) => ({ vorname, nachname }))
        );
  }
}
