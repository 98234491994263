<mat-card>
  <div class="pknfo__row">
    <div class="pknfo__col">
      <div class="pknfo__row">
        <div class="pknfo__col">
          <div class="pknfo__unit">
            <span class="label label--nogap">Einheit</span>

            <span class="val val--big val--inline">{{
              packungsinfo?.unit
            }}</span>
            <span class="val--secondary--big">{{
              packungsinfo?.mappedLongName
            }}</span>
          </div>
        </div>
        <div class="pknfo__col">
          <div class="pknfo__unit">
            <span class="label label--nogap">Faktor in BME</span>

            <span class="val val--medium val--inline"
              >{{ packungsinfo?.quantityOfBaseUnit }}
              {{ packungsinfo?.baseUnit }}</span
            >
            <span class="val--secondary--medium">{{
              packungsinfo?.baseUnitMappedLongName
            }}</span>
          </div>
        </div>
      </div>
      <div class="pknfo__unit">
        <span class="pknfo__unit-grp">
          <ng-container *ngIf="isBaseUnit">
            <mat-icon>check</mat-icon>
          </ng-container>
          <span class="val truncate" *ngIf="isBaseUnit"
            >Basismengeneinheit</span
          >
        </span>

        <span class="pknfo__unit-grp">
          <ng-container *ngIf="isStandardOrderUnit">
            <mat-icon>check</mat-icon>
          </ng-container>
          <span class="val truncate" *ngIf="isStandardOrderUnit"
            >Standardbestelleinheit</span
          >
        </span>

        <span class="pknfo__unit-grp">
          <ng-container *ngIf="isOrderableUnit">
            <mat-icon>check</mat-icon>
          </ng-container>
          <span class="val truncate" *ngIf="isOrderableUnit"
            >Bestellbare Einheit</span
          >
        </span>
      </div>
    </div>
    <div class="pknfo__col">
      <div class="pknfo__unit">
        <span class="label truncate">Abmessung (HxBxT)</span>
        <span class="val">{{ measurementsNet }}</span>
      </div>
      <div class="pknfo__unit">
        <span class="label truncate">Verpackung (HxBxT)</span>
        <span class="val">{{ measurementsGross }}</span>
      </div>
      <div class="pknfo__unit">
        <span class="label">Gewicht</span>

        <span class="pknfo__unit-grp">
          <span class="label">Netto: </span>
          <span class="val">{{ weightNet }}</span>
        </span>

        <span class="pknfo__unit-grp">
          <span class="label">Brutto: </span>
          <span class="val">{{ weightGross }}</span>
        </span>
      </div>
    </div>
    <div class="pknfo__col">
      <span class="pknfo__unit pknfo__unit--list">
        <span class="label truncate">Packungsidentifikationen</span>

        <span
          *ngFor="let identifikation of packungsinfo?.identifications"
          class="pknfo__unit-grp"
        >
          <span class="label">{{ identifikation.type }}</span>
          <span class="val truncate">{{ identifikation.value }}</span>
        </span>
      </span>
    </div>
  </div>
</mat-card>
