import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/shared/data-access';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PurchaseRequisitionsHistoryCounts, PurchaseRequisitionsPeriod } from '../../../models';
import { RequisitionsMetricsService } from '../../services';

export interface RequisitionsHistoryWidgetState {
  purchaseRequisitionsHistoryCounts: PurchaseRequisitionsHistoryCounts | undefined;
}

export const INITIAL_STATE: RequisitionsHistoryWidgetState = {
  purchaseRequisitionsHistoryCounts: undefined,
};

const SELECTED_REQUSITIONS_PERIOD_METADATA = 'SELECTED_REQUSITIONS_PERIOD_METADATA';

@Injectable()
export class RequisitionsHistoryWidgetStore extends ComponentStore<RequisitionsHistoryWidgetState> {
  readonly defaultRequisitionsPeriod: PurchaseRequisitionsPeriod = this.localStorageService.read(
    SELECTED_REQUSITIONS_PERIOD_METADATA,
    PurchaseRequisitionsPeriod.Last30Days
  );

  constructor(
    private requisitionsMetricsService: RequisitionsMetricsService,
    private readonly localStorageService: LocalStorageService
  ) {
    super(INITIAL_STATE);
  }

  readonly purchaseRequisitionsHistoryCounts$: Observable<
    PurchaseRequisitionsHistoryCounts | undefined
  > = this.select((state) => state.purchaseRequisitionsHistoryCounts);

  readonly fetchPurchaseRequisitionsHistoryCounts = this.effect(
    (
      numberOfDays$: Observable<PurchaseRequisitionsPeriod>
    ): Observable<PurchaseRequisitionsHistoryCounts> => {
      return numberOfDays$.pipe(
        tap((numberOfDays) =>
          this.localStorageService.write(SELECTED_REQUSITIONS_PERIOD_METADATA, numberOfDays)
        ),
        switchMap((numberOfDays) =>
          this.requisitionsMetricsService.fetchPurchaseRequisitionsHistoryCounts(numberOfDays).pipe(
            tap((purchaseRequisitionsHistoryCounts) =>
              this.setRequisitionCounts(purchaseRequisitionsHistoryCounts)
            ),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  private readonly setRequisitionCounts = this.updater<PurchaseRequisitionsHistoryCounts>(
    (state, purchaseRequisitionsHistoryCounts): RequisitionsHistoryWidgetState => ({
      ...state,
      purchaseRequisitionsHistoryCounts,
    })
  );
}
