import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PendingPurchaseRequisitionCounts } from '../../../models';
import { RequisitionsMetricsService } from '../../services';

export interface PendingRequisitionsCountsWidgetState {
  pendingPurchaseRequisitionCounts: PendingPurchaseRequisitionCounts | undefined;
}

export const INITIAL_STATE: PendingRequisitionsCountsWidgetState = {
  pendingPurchaseRequisitionCounts: undefined,
};

@Injectable()
export class PendingRequisitionsCountsWidgetStore extends ComponentStore<PendingRequisitionsCountsWidgetState> {
  constructor(private requisitionsMetricsService: RequisitionsMetricsService) {
    super(INITIAL_STATE);
  }

  readonly pendingPurchaseRequisitionCounts$: Observable<
    PendingPurchaseRequisitionCounts | undefined
  > = this.select((state) => state.pendingPurchaseRequisitionCounts);

  readonly fetchPendingPurchaseRequisitionCounts = this.effect(
    (trigger$: Observable<void>): Observable<PendingPurchaseRequisitionCounts> => {
      return trigger$.pipe(
        switchMap(() =>
          this.requisitionsMetricsService.fetchPendingPurchaseRequisitionCounts().pipe(
            tap((pendingPurchaseRequisitionCounts) =>
              this.setRequisitionCounts(pendingPurchaseRequisitionCounts)
            ),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  private readonly setRequisitionCounts = this.updater<PendingPurchaseRequisitionCounts>(
    (state, pendingPurchaseRequisitionCounts): PendingRequisitionsCountsWidgetState => ({
      ...state,
      pendingPurchaseRequisitionCounts,
    })
  );
}
