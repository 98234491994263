import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerModule } from '@core/ui';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DeletedFavoriteListArticle } from '../../models';
import { DeleteArticlesLabelPipe } from '../../pipes';
import { DeletedFavoritesListComponent } from '../deleted-favorites-list/deleted-favorites-list.component';

@Component({
  selector: 'mpcm-deleted-favorites',
  templateUrl: './deleted-favorites.component.html',
  styleUrls: ['./deleted-favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,

    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,

    SpinnerModule,
    DeletedFavoritesListComponent,
    DeleteArticlesLabelPipe,
    MatCardModule,
  ],
})
export class DeletedFavoritesComponent implements OnDestroy {
  @HostBinding('class') readonly class = 'mpcm-deleted-favorites';

  @Input()
  deletedFavorites: DeletedFavoriteListArticle[] = [];

  @Input()
  deletedFavoritesLoading = false;

  @Input()
  filteredDeleted = 0;

  @Input()
  totalDeleted = 0;

  @Input()
  set searchTerm(value: string) {
    // emitEvent flag is used here to prevent emitting assigned value to the search term after clearing
    this.searchTermControl.setValue(value, { emitEvent: false });
  }

  @Output() readonly articleSearch: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly articleDelete: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly allArticlesDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly nextPageLoad: EventEmitter<void> = new EventEmitter<void>();

  searchTermControl = new FormControl<string>('');

  private readonly INTERVAL_DELAY_MS = 200;

  private destroy$ = new Subject<void>();

  constructor() {
    this.searchTermControl.valueChanges
      .pipe(
        debounceTime(this.INTERVAL_DELAY_MS),
        tap((searchTerm) => this.articleSearch.emit(searchTerm ?? '')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  clearSearch(): void {
    this.searchTermControl.setValue('');
  }

  deleteAllArticles(): void {
    this.allArticlesDelete.emit();
  }

  deleteArticle(articleRecordId: string): void {
    this.articleDelete.emit(articleRecordId);
  }

  loadNextPage(): void {
    this.nextPageLoad.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
