import { Component, Input } from '@angular/core';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-nav-profile',
  templateUrl: './navigation-profile.component.html'
})
export class NavigationProfileComponent extends ElementDirective {
  
  @Input() user?: string | null;
  @Input() company?: string | null;
  @Input() picture?: string | null;

  constructor() {
    super();
    this.class = 'mp-nav-profile';
  }
}
