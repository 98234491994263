import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AvatarModule, NavigationModule, OverviewModule } from '@core/ui';
import { ImageLoaderModule, ImageLoaderService } from '@core/shared/data-access';
import { ProfilEffects, ProfilService, profilReducer } from '@mp/shared/profil/data-access';
import { ChangePasswordInputModule } from '@core/ui';
import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { FormsModule } from '@core/shared/util';
import { ProfilActionsFacade } from './profil-actions.facade';
import { ProfilDisplayComponent } from './display/profil-display.component';
import { ProfilEditComponent } from './edit/profil-edit.component';
import { ProfilFacade } from './profil.facade';
import { tabTitleResolver } from '@mp/shared/data-access';

@NgModule({
  declarations: [
    ProfilDisplayComponent,
    ProfilEditComponent,
    FeedbackButtonComponent
  ],
  exports: [
    ProfilDisplayComponent,
    ProfilEditComponent,
    FeedbackButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild([{
      path: 'profil/edit',
      component: ProfilEditComponent,
      title: tabTitleResolver('Profil')
    }]),

    StoreModule.forFeature('profil', profilReducer),
    EffectsModule.forFeature([ProfilEffects]),
    MatTabsModule,
    NavigationModule,
    OverviewModule,
    AvatarModule,
    FormsModule,
    ImageLoaderModule.forRoot({uploadUrl: '/api/uploads/images/'}),
    ChangePasswordInputModule,
    MatTooltipModule
  ],
  providers: [
    ProfilService,
    ImageLoaderService,
    ProfilFacade,
    ProfilActionsFacade
  ]
})
export class ProfilModule { }
