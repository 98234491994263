import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { NavigationModule } from '../navigation/navigation.module';
import { ProfilModule } from '@mp/shared/profil/feature';
import { ShellComponent } from './shell.component';
import { UserInfoModule } from '@mp/shared/profil/data-access';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    NavigationModule,
    ProfilModule,
    UserInfoModule
  ],
  declarations: [
    ShellComponent
  ],
  exports: [
    NavigationModule,
    ShellComponent
  ]
})
export class ShellModule { }
