import { NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { LocalStorageService } from '@core/shared/data-access';
import {
  CategoriesModule,
  categoryTypeDescriptions,
} from '../../../categories';
import { ArticleTag } from '../../../models';
import {
  ArticleCategoriesWithProperties,
  ArticleMappedTag,
  ArticleProperty,
} from '../../models';
import { buildTagArray } from '../../utils';
import { ColumnName } from './column-name';
import { MatTooltipModule } from '@angular/material/tooltip';

const ARTIKEL_DETAIL_PROPERTIES = 'ARTIKEL_DETAIL_PROPERTIES';
const ARTIKEL_DETAIL_METADATA = 'ARTIKEL_DETAIL_METADATA';
const ARTIKEL_DETAIL_TAGS = 'ARTIKEL_DETAIL_TAGS';

@Component({
  standalone: true,
  selector: 'mpcm-tab-merkmale',
  imports: [
    NgIf,
    NgForOf,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    CategoriesModule,
  ],
  templateUrl: './tab-merkmale.component.html',
  styleUrls: ['./tab-merkmale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabMerkmaleComponent implements OnInit {
  @HostBinding('class') readonly class = 'mpcm-tab-merkmale';

  @Input() categoriesWithProperties?: Array<ArticleCategoriesWithProperties>;
  @Input() metadataProperties?: Array<ArticleProperty>;
  @Input() articleTags?: Array<ArticleTag>;

  @Input() basePath: Array<string> = [];

  get categories(): Array<ArticleCategoriesWithProperties> {
    return this.categoriesWithProperties ?? [];
  }

  get metadata(): Array<ArticleProperty> {
    return this.metadataProperties ?? [];
  }

  get tagsForArticle(): Array<ArticleMappedTag> {
    return this.tags ?? [];
  }

  tags: Array<ArticleMappedTag> = [];

  displayedCols: Array<ColumnName> = ['name', 'wert'];

  categoryTypeDescriptions = categoryTypeDescriptions;

  isOpened = new Map<string, boolean>();

  constructor(private readonly localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.tags = buildTagArray(this.articleTags);
    this.isOpened.set('properties', this.IsExpansionOpened('properties'));
    this.isOpened.set('metadata', this.IsExpansionOpened('metadata'));
    this.isOpened.set('tags', this.IsExpansionOpened('tags'));
  }

  openExpansionPanel(value: string, panel: MatExpansionPanel): void {
    panel.expanded = !panel.expanded;
    this.isOpened.set(value, panel.expanded);

    if (value === 'properties') {
      this.localStorageService.write(ARTIKEL_DETAIL_PROPERTIES, panel.expanded);
    }
    if (value === 'metadata') {
      this.localStorageService.write(ARTIKEL_DETAIL_METADATA, panel.expanded);
    }
    if (value === 'tags') {
      this.localStorageService.write(ARTIKEL_DETAIL_TAGS, panel.expanded);
    }
  }

  IsExpansionOpened(value: string): boolean {
    let expansionState = true;
    if (value === 'properties') {
      expansionState = this.localStorageService.read(
        ARTIKEL_DETAIL_PROPERTIES,
        ''
      ) as boolean;
    }
    if (value === 'metadata') {
      expansionState = this.localStorageService.read(
        ARTIKEL_DETAIL_METADATA,
        ''
      ) as boolean;
    }
    if (value === 'tags') {
      expansionState = this.localStorageService.read(
        ARTIKEL_DETAIL_TAGS,
        ''
      ) as boolean;
    }
    return expansionState ?? true;
  }
}
