import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export class FlyoutManager {

  protected flyoutCurrentlyOpened = false;
  private afterClosedSubscription?: Subscription;

  protected markFlyoutAsOpen(): void {
    this.flyoutCurrentlyOpened = true;
  }

  protected markFlyoutAsClosed(): void {
    this.flyoutCurrentlyOpened = false;
  }

  protected subscribeToAfterClosed(flyoutRef: { afterClosed$: Observable<unknown> }, callback: () => void): void {
    this.afterClosedSubscription = flyoutRef
      .afterClosed$
        .pipe(take(1))
        .subscribe({ next: () => {
          callback();
          this.afterClosedSubscription?.unsubscribe();
        } });
  }
}