<ng-container>
  <div *ngIf="categories.length; else categoriesPlaceholder" class="property__spacer">
    <ng-container *ngFor="let category of categories">
      <h3 class="mat-h3">{{categoryTypeDescriptions[category.type]}} Eigenschaften</h3>
      <mat-card class="card--no-bot-radius">
        <div class="categories-card">
          <div class="categories-header">
            <mat-icon class="warengruppen-path__type-icon material-icons-outlined">category</mat-icon>
            <span class="warengruppen-path__type">{{categoryTypeDescriptions[category.type]}}</span>
            <span *ngIf="category.version" class="warengruppen-path__type-version"> {{category.version}}</span>
          </div>
          <div class="breadcrumb-items" *ngIf="category.category">
            <mpcm-breadcrumb-item [categoriesPath]="category.category" [showParents]="true" [showAllCategoryCodes]="true"
              [showIcons]="true" [basePath]="basePath">
            </mpcm-breadcrumb-item>
          </div>
        </div>
      </mat-card>

      <ng-container *ngIf="category.properties as properties; else propertiesPlaceholder">
        <div class="details-header__card">
          <mat-card class="card--no-radius">
            <span class="details-header__card-name">Name</span>
            <span class="details-header__card-value">Wert</span>
          </mat-card>
        </div>
        <div class="details__expansion-panel">
          <mat-expansion-panel #propertiesPanel [expanded]="IsExpansionOpened('properties')">
            <div class="details__card">
              <div class="tbl" *ngFor="let property of properties">
                <span class="tbl__text-label truncate">
                  <span [matTooltip]="property.featureCode">{{property.featureReadable}}</span>
                </span>
                <span class="tbl__text-value">
                  <span [matTooltip]="property.valueCode">{{property.valueReadable}}</span>
                </span>
              </div>
            </div>
          </mat-expansion-panel>
          <div class="details__expansion-btn">
            <button mat-button (click)="openExpansionPanel('properties', propertiesPanel)">
              <span class="details__expansion-btn-title truncate">Alle {{categoryTypeDescriptions[category.type]}} Eigenschaften</span>
              <span *ngIf="!isOpened.get('properties')" class="details__expansion-btn-title-extra"> anzeigen</span>
              <span *ngIf="isOpened.get('properties')" class="details__expansion-btn-title-extra"> ausblenden</span>
              <mat-icon *ngIf="!isOpened.get('properties')">expand_more</mat-icon>
              <mat-icon *ngIf="isOpened.get('properties')">expand_less</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #propertiesPlaceholder>
        <mat-card>
          <span class="no-result">
            Keine {{categoryTypeDescriptions[category.type]}} Eigenschaften vorhanden.
          </span>
        </mat-card>
      </ng-template>
    </ng-container>

  </div>
  <ng-template #categoriesPlaceholder>
    <mat-card>
      <span class="no-result">
        Keine Warengruppen vorhanden.
      </span>
    </mat-card>
  </ng-template>
</ng-container>

<ng-container>
  <div class="property__spacer">
    <ng-container *ngIf="metadata.length; else metaPlaceholder">
      <h3 class="mat-h3">Allgemeine Eigenschaften</h3>
      <div class="details-header__card">
        <mat-card>
          <span class="details-header__card-name">Name</span>
          <span class="details-header__card-value">Wert</span>
        </mat-card>
      </div>
      <div class="details__expansion-panel">
        <mat-expansion-panel #metaPanel [expanded]="IsExpansionOpened('metadata')">
          <div class="details__card">
            <div class="tbl" *ngFor="let metadaten of metadata">
              <span class="tbl__text-label truncate">
                <span [matTooltip]="metadaten.name">{{metadaten.name}}</span>
              </span>
              <span class="tbl__text-value">{{metadaten.value}}</span>
            </div>
          </div>
        </mat-expansion-panel>
        <div class="details__expansion-btn">
          <button mat-button (click)="openExpansionPanel('metadata', metaPanel)">
            <span class="details__expansion-btn-title truncate">Alle allgemeinen Eigenschaften</span>
            <span *ngIf="!isOpened.get('metadata')" class="details__expansion-btn-title-extra"> anzeigen</span>
            <span *ngIf="isOpened.get('metadata')" class="details__expansion-btn-title-extra"> ausblenden</span>
            <mat-icon *ngIf="!isOpened.get('metadata')">expand_more</mat-icon>
            <mat-icon *ngIf="isOpened.get('metadata')">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

  </div>
  <ng-template #metaPlaceholder>
    <mat-card>
      <span class="no-result">
        Keine allgemeinen Eigenschaften vorhanden.
      </span>
    </mat-card>
  </ng-template>
</ng-container>

<ng-container>
  <div class="property__spacer">
    <ng-container *ngIf="tagsForArticle.length; else tagsPlaceholder">
      <h3 class="mat-h3">Kennzeichen</h3>
      <div class="details-header__card">
        <mat-card class="no-radius">
          <span class="details-header__card-name">Name</span>
          <span class="details-header__card-value">Wert</span>
        </mat-card>
      </div>
      <div class="details__expansion-panel">
        <mat-expansion-panel #tagsPanel [expanded]="IsExpansionOpened('tags')">
          <div class="details__card">
            <div class="tbl" *ngFor="let tag of tagsForArticle">
              <span class="tbl__text-label">{{tag.name}}</span>
              <span class="tbl__text-value">{{tag.value}}</span>
            </div>
          </div>
        </mat-expansion-panel>
        <div class="details__expansion-btn">
          <button mat-button (click)="openExpansionPanel('tags', tagsPanel)">
            <span class="details__expansion-btn-title truncate">Alle Kennzeichen</span>
            <span *ngIf="!isOpened.get('tags')" class="details__expansion-btn-title-extra"> anzeigen</span>
            <span *ngIf="isOpened.get('tags')" class="details__expansion-btn-title-extra"> ausblenden</span>
            <mat-icon *ngIf="!isOpened.get('tags')">expand_more</mat-icon>
            <mat-icon *ngIf="isOpened.get('tags')">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

  </div>
  <ng-template #tagsPlaceholder>
    <mat-card>
      <span class="no-result">
        Keine Kennzeichen vorhanden.
      </span>
    </mat-card>
  </ng-template>
</ng-container>
