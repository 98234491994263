import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

// TODO: Remove this import:
import { OrganisationenService } from '@mp/system/organisationen/data-access';

import { Benutzer, CreateBenutzer, UpdateBenutzer } from '../benutzer';
import { EffectsBase, NotificationService, PageResponse } from '@mp/shared/data-access';
import { BenutzerActions } from './benutzer.actions';
import { BenutzerService } from '../benutzer.service';
import { getProp } from '@core/shared/util';

@Injectable()
export class BenutzerEffects extends EffectsBase {

  loadSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.loadSingle),
      exhaustMap(({ queryParams }) =>
        this.getRouterParamIdByKey('benutzerId').pipe(
          switchMap(id => this.service.get(id, queryParams))
        )
      ),
      map((benutzer: Partial<Benutzer>) => {
        const loadedBenutzer: Benutzer = benutzer as any;
        return BenutzerActions.API.loadedSingleSuccessfully({ loadedBenutzer });
      })
    );
  });

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.loadAll),
      exhaustMap(({ queryParams }) => this.service.getAll(queryParams)),
      map((benutzerPage: PageResponse<Partial<Benutzer>>) => {
        const loadedPage: PageResponse<Benutzer> = benutzerPage as any;
        return BenutzerActions.API.loadedAllSuccessfully({ loadedBenutzerPage: loadedPage });
      })
    );
  }
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.create),
      mergeMap(action =>
        this.createUserAndRelations(
          action.benutzerToCreate,
          action.organisationenToAdd ?? []
        )
      ),
      map(([createdBenutzer, added]) => BenutzerActions.API.createdSuccessfully({ createdBenutzer, added })),
      tap(() => { this.navigateBack(); })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.update),
      mergeMap(action =>
        this.updateUserAndRelations(
          action.benutzerToUpdate,
          action.organisationenToAdd ?? [],
          action.organisationenToRemove ?? []
        )
      ),
      map(([updatedBenutzer, added, removed]) => BenutzerActions.API.updatedSuccessfully({ updatedBenutzer, added, removed })),
      tap(() => { this.navigateBack(); })
    );
  });

  cancelUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.cancelUpdate),
      map(BenutzerActions.API.canceledUpdate),
      tap(() => { this.navigateBack(); })
    );
  });

  cancelCreate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BenutzerActions.COMPONENT.cancelCreate),
      map(BenutzerActions.API.canceledCreation),
      tap(() => { this.navigateBack(); })
    );
  });

  constructor(
    injector: Injector,
    private readonly actions$: Actions,
    private readonly service: BenutzerService,
    private readonly organisationenService: OrganisationenService,
    private readonly toaster: NotificationService
  ) {
    super(injector);
  }

  private createUserAndRelations(
    userToCreate: CreateBenutzer,
    organisationsToAdd: Array<number>
  ): Observable<[Benutzer, Array<boolean>]> {

    return this.service
      .createBenutzer(userToCreate)
      .pipe(
        getProp('data'),
        tap(response => {
          if (response.statusCode === 200) this.toaster.toastSuccess(response.statusMessage);
          else if (response.statusCode === 400) { this.toaster.toastDanger(response.statusMessage); }
        }),
        map(response => response.entity),
        mergeMap((createdUser: Benutzer) =>
          forkJoin([
            of(createdUser),
            this.addUserToOrganisations(organisationsToAdd, createdUser.id)
          ])
        )
      );
  }

  private updateUserAndRelations(
    userToUpdate: UpdateBenutzer,
    organisationsToAdd: Array<number>,
    organisationsToRemove: Array<number>
  ): Observable<[Benutzer, Array<boolean>, Array<boolean>]> {

    return forkJoin([
      this.service.update(userToUpdate),
      this.addUserToOrganisations(organisationsToAdd, userToUpdate.id),
      this.removeUserFromOrganisations(organisationsToRemove, userToUpdate.id)
    ]);
  }

  private addUserToOrganisations(organisationsToAdd: Array<number>, userId: number): Observable<Array<boolean>> {
    return !organisationsToAdd || organisationsToAdd.length === 0 ?
      of([]) : this.organisationenService.addUserToOrganisationen(organisationsToAdd, userId);
  }

  private removeUserFromOrganisations(organisationesToRemove: Array<number>, userId: number): Observable<Array<boolean>> {
    return !organisationesToRemove || organisationesToRemove.length === 0 ?
      of([]) : this.organisationenService.removeUserFromOrganisation(organisationesToRemove, userId);
  }
}
