import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { LieferantIconComponent } from './lieferant-icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [LieferantIconComponent],
  exports: [LieferantIconComponent]
})
export class LieferantIconModule { }