import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import { ActiveOrganisationSelectorFacade } from '../active-organisation-selector.facade';
import { LocalStorageService } from '@core/shared/data-access';
import { Profil } from '@mp/shared/profil/data-access';

@Component({
  selector: 'mp-active-organisation-selector',
  templateUrl: './active-organisation-selector.component.html'
})
export class ActiveOrganisationSelectorComponent implements OnDestroy {

  private referrerUrl: string;
  rememberSelectedOrganisation: boolean;
  profil$: Observable<Profil | undefined>;

  @Output() readonly selection = new EventEmitter<number>();

  private readonly isDestroyed$ = new Subject<void>();

  constructor(
    private readonly facade: ActiveOrganisationSelectorFacade,
    private readonly route: ActivatedRoute,
    localStorageService: LocalStorageService
  ) {
    this.profil$ = facade.profil$;
    this.rememberSelectedOrganisation = localStorageService.read('keepOrganisationIdAfterLogout', false);
    this.referrerUrl = this.buildReferrerURLFromRouterParams();
  }

  private buildReferrerURLFromRouterParams(): string {
    return this.route.snapshot.queryParams.switch
      ? '/'
      : self.location.href;
  }

  getRolleName(mapping: { name: string } ) {
    return mapping ? mapping.name : undefined;
  }



  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  switchToOrganisation(organisationId: number): void {
    this.facade.switchOrganisation(organisationId, this.rememberSelectedOrganisation, this.referrerUrl);
    this.selection.emit(organisationId);
  }

}
