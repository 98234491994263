import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { InputGroupDirective } from './input-group.directive';
import { ViewColumnDirective } from './column.directive';
import { ViewComponent } from './view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    InputGroupDirective,
    ViewColumnDirective,
    ViewComponent
  ],
  exports: [
    InputGroupDirective,
    ViewColumnDirective,
    ViewComponent
  ]
})
export class ViewModule { }
