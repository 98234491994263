import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { RouterEventsService, ViewportService } from '@core/shared/util';
import { IsLoadingService } from '@service-work/is-loading';

@Component({
  selector: 'mp-shell',
  templateUrl: './shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellComponent implements OnInit, OnDestroy {
  @Input()
  appTitle = '';

  @Input()
  showAppLogo = false;

  @HostBinding('class')
  private class = 'mp-shell';

  readonly isLoading$ = this.isLoadingService.isLoading$();
  readonly isMediumViewport$ = this.viewportService.isMediumViewport$;

  readonly feedbackRight$: RechtFilter = { resource: Resources.Feedback, action: Actions.Use };

  private destroy$ = new Subject<void>();

  isOpened = false;

  constructor(
    private viewportService: ViewportService,
    private routerEventsService: RouterEventsService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit(): void {
    this.routerEventsService.navigationStart$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoadingService.add();
      });

    this.routerEventsService.navigationEndCancelError$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoadingService.remove();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
