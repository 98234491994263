import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output } from '@angular/core';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'mp-selector-item',
  templateUrl: './selector-item.component.html',
  styleUrls: ['./selector-item.component.scss']
})
export class SelectorItemComponent implements OnDestroy {

  @HostBinding('class') readonly class = 'mp-selector-item';

  @Output() readonly toggled = new EventEmitter<boolean>();

  @Input()
  get selected(): BooleanInput { return this._selected; }
  set selected(value: BooleanInput) {
    this._selected = coerceBooleanProperty(value);
  }
  private _selected = false;

  @Input()
  get disabled(): BooleanInput { return this._disabled; }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostListener('click') click(): void {
    if (!this.disabled) {
      this.selected = !this.selected;
      this.toggled.next(this.selected);
    }
  }

  ngOnDestroy(): void {
    this.toggled.complete();
  }
}
