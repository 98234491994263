import { Injectable } from '@angular/core';
import { RouterFacade } from '@core/shared/data-access';
import { filterUndefined, getProp } from '@core/shared/util';
import {
  AnforderungenHistorieWidgetData,
  AnforderungenOffenWidgetData,
  DashboardService,
  MedicalShopKennzahlenDto,
  MeineOrganisationWidgetData,
} from '@mp/dashboard/data-access';
import {
  AnforderungenService,
  AnforderungStatusCode,
} from '@mp/medical-shop/auftraege/data-access';

import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { profilSelectQuery, UserInfoFacade } from '@mp/shared/profil/data-access';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { map, mergeMap, take } from 'rxjs/operators';

namespace AnforderungStatusString {
  export const OFFEN = 'New';
  export const PROBLEM = 'Error';
  export const FREIGEGEBEN = 'Transferred';
  export const ABGELEHNT = 'Declined';
}

@Injectable()
export class DashboardFacade {

  private readonly _medicalShopWidgetData$ = new BehaviorSubject(undefined as MedicalShopKennzahlenDto | undefined);
  readonly medicalShopWidgetData$ = this._medicalShopWidgetData$.asObservable();

  private readonly _meineOrganisationWidgetData$ = new BehaviorSubject(undefined as MeineOrganisationWidgetData | undefined);
  readonly meineOrganisationWidgetData$ = this._meineOrganisationWidgetData$.asObservable();

  private readonly _anforderungenOffenWidgetData$ = new BehaviorSubject(undefined as AnforderungenOffenWidgetData | undefined);
  readonly anforderungenOffenWidgetData$ = this._anforderungenOffenWidgetData$.asObservable();

  private readonly _anforderungenHistorieWidgetData$ = new BehaviorSubject(undefined as AnforderungenHistorieWidgetData | undefined);
  readonly anforderungenHistorieWidgetData$ = this._anforderungenHistorieWidgetData$.asObservable();

  private readonly _anforderungenGesamtwertData$ = new BehaviorSubject(undefined as number | undefined);
  readonly anforderungenGesamtwertData$ = this._anforderungenGesamtwertData$.asObservable();

  readonly leserecht4Anforderungen: RechtFilter = { resource: Resources.Anforderungen, action: Actions.Read } as const;
  readonly leserecht4AlleAnforderungen: RechtFilter = { resource: Resources.AlleAnforderungen, action: Actions.Read } as const;
  readonly leserecht4myOrg: RechtFilter = { resource: Resources.MyOrganisationWidget, action: Actions.Read } as const;
  readonly leserecht4MedicalShopDaten: RechtFilter = { resource: Resources.Carts, action: Actions.Write } as const;

  constructor(
    private readonly anforderungenService: AnforderungenService,
    private readonly dashboardService: DashboardService,
    private readonly routerFacade: RouterFacade,
    private readonly userInfo: UserInfoFacade,
    private readonly store$: Store
  ) {}

  loadAllWidgetData(): void {
    this.getAnforderungenGesamtwertData();
    this.getAnforderungenHistorieWidgetData();
    this.getAnforderungenOffenWidgetData();
    this.getMedicalShopWidgetData();
    this.getMeineOrganisationWidgetData();
  }

  getMedicalShopWidgetData(): void {
    if(!this.userInfo.hasRecht(this.leserecht4MedicalShopDaten)) { return; }

    this.dashboardService
    .getStatistics()
    .subscribe({ next: widgetData => this._medicalShopWidgetData$.next(widgetData) });
  }

  navigateToShopArtikelsuche(searchTerm?: string): void {
    this.routerFacade.navigate((router, route) => router.navigate(
      ['./medical-shop/artikelsuche/list'],
      { relativeTo: route.firstChild, queryParams: { query: searchTerm ?? null } }
    ));
  }

  getMeineOrganisationWidgetData(): void {
    if(!this.userInfo.hasRecht(this.leserecht4myOrg)) { return; }

    this.store$
      .select(profilSelectQuery.PROFIL)
      .pipe(
        take(1),
        filterUndefined(),
        getProp('activeLizenz'),
        mergeMap(lizenz =>
          this.dashboardService
            .getAnzahlBenutzer()
            .pipe(
              map(benutzerAnzahl => ({ benutzerAnzahl, lizenz }) as MeineOrganisationWidgetData)
            )
        ),
        map(widgetData => widgetData.lizenz ? widgetData : undefined)
      )
      .subscribe({ next: widgetData => this._meineOrganisationWidgetData$.next(widgetData) });
  }

  getAnforderungenOffenWidgetData(): void {
    if(!this.userInfo.hasRecht(this.leserecht4Anforderungen) && !this.userInfo.hasRecht(this.leserecht4AlleAnforderungen)) { return; }

    this.anforderungenService
      .getCount()
      .pipe(
        map(anforderungen => {
          const anforderungenOffenAnzahl = anforderungen[AnforderungStatusString.OFFEN];
          const anforderungenProblemAnzahl = anforderungen[AnforderungStatusString.PROBLEM];
          return { anforderungenOffenAnzahl, anforderungenProblemAnzahl };
        })
      )
      .subscribe({ next: widgetData => { this._anforderungenOffenWidgetData$.next(widgetData); } });
  }

  getAnforderungenHistorieWidgetData(): void {
    if(!this.userInfo.hasRecht(this.leserecht4Anforderungen) && !this.userInfo.hasRecht(this.leserecht4AlleAnforderungen)) { return; }

    this.anforderungenService
      .getCount(this.getFirstDayOfThisMonth())
      .pipe(
        map(anforderungen => {
          const anforderungenFreigegebenAnzahl = anforderungen[AnforderungStatusString.FREIGEGEBEN];
          const anforderungenAbgelehntAnzahl = anforderungen[AnforderungStatusString.ABGELEHNT];
          return { anforderungenFreigegebenAnzahl, anforderungenAbgelehntAnzahl };
        })
      )
      .subscribe({ next: widgetData => { this._anforderungenHistorieWidgetData$.next(widgetData); } });
  }

  getAnforderungenGesamtwertData(): void {
    if(!this.userInfo.hasRecht(this.leserecht4Anforderungen) && !this.userInfo.hasRecht(this.leserecht4AlleAnforderungen)) { return; }

    this.anforderungenService
      .getTotalPrice(this.getFirstDayOfThisMonth(), [AnforderungStatusCode.TRANSFERRED])
      .subscribe({ next: totalPrice => { this._anforderungenGesamtwertData$.next(totalPrice); } });
  }

  private getFirstDayOfThisMonth(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  }
}
