import { Component, HostBinding, Inject, OnDestroy, TemplateRef } from '@angular/core';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { DefaultSearchLambdas, DefaultSortLambdas } from './lambdas';
import { FlyoutAnimation } from '../../component/basic-flyout/basic-flyout-animation';
import { FlyoutComponent } from '../../component/flyout-component';
import { OVERLAY_DATA } from '../../custom-overlay/custom-overlay-tokens';
import { SelectionItem } from '../selection-item/selection-item';
import { SelectorFlyoutData } from '../selector-flyout-config';


@Component({
  selector: 'mp-flyout-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  animations: FlyoutAnimation.Named('openClose')
})
export class SelectorComponent<ItemReturnType> implements FlyoutComponent<Array<ItemReturnType>>, OnDestroy {

  @HostBinding('class') readonly class = 'mp-flyout-selector';
  @HostBinding('@openClose') animationState: FlyoutAnimation.State = 'open';

  readonly template?: TemplateRef<unknown>;

  readonly displayedItems$: Observable<Array<ItemReturnType>>;
  private readonly items$: Observable<Array<ItemReturnType>>;
  private readonly selectionModel: SelectionModel<ItemReturnType>;

  private readonly searchTerm$: Observable<string>;
  readonly searchField: UntypedFormControl = new UntypedFormControl('');

  private readonly _afterClosed$ = new Subject<Array<ItemReturnType> | undefined>();
  readonly afterClosed$ = this._afterClosed$.pipe(delay(FlyoutAnimation.AnimationDuration));

  private readonly isDestroyed$ = new Subject<void>();

  get title(): string {
    return this.data.title;
  }

  /* Selection Properties */

  get selectionChange$(): Observable<SelectionChange<ItemReturnType>> {
    return this.selectionModel.changed.asObservable();
  }

  get selection(): Array<ItemReturnType> {
    return this.selectionModel.selected;
  }

  constructor(@Inject(OVERLAY_DATA) private readonly data: SelectorFlyoutData<ItemReturnType>) {
    this.template = this.data.itemTemplate;

    this.selectionModel = new SelectionModel<ItemReturnType>(
      this.data.multiple,
      this.data.initiallySelected
    );

    this.items$ = this.buildItems$(data.items);
    this.searchTerm$ = this.buildSearchTerm$(this.searchField);
    this.displayedItems$ = this.buildDisplayedItems$(this.items$, this.searchTerm$, this.selectionChange$);
  }

  private buildItems$(items$: Array<ItemReturnType> | Observable<Array<ItemReturnType>>): Observable<Array<ItemReturnType>> {
    return items$ instanceof Observable ? items$ : new BehaviorSubject(items$);
  }

  private buildDisplayedItems$(
    items$: Observable<Array<ItemReturnType>>,
    searchTerm$: Observable<string>,
    selectionChange$: Observable<SelectionChange<ItemReturnType>>
  ): Observable<Array<ItemReturnType>> {
    return combineLatest([
      items$,
      searchTerm$,
      selectionChange$.pipe(startWith(null))
    ]).pipe(
      map(([items, searchTerm]) => this.filterItems(items, searchTerm)),
      map(filteredItems => this.sortItems(filteredItems)),
      takeUntil(this.isDestroyed$)
    );
  }

  private buildSearchTerm$(searchField: UntypedFormControl): Observable<string> {
    return searchField.valueChanges.pipe(
      debounceTime(500),
      map((searchTerm: string) => searchTerm.trim()),
      distinctUntilChanged(),
      startWith(this.searchField.value),
      takeUntil(this.isDestroyed$)
    );
  }

  cancel(): void {
    this.close();
  }

  confirm(): void {
    this.close(this.selection);
  }

  close(returnValue?: Array<ItemReturnType>): void {
    this.animationState = 'closed';

    this._afterClosed$.next(returnValue);
    this._afterClosed$.complete();
  }

  isSelected(item: ItemReturnType): boolean {
    return this.selectionModel.isSelected(item);
  }

  toggleSelect(item: ItemReturnType): void {
    this.selectionModel.toggle(item);
  }

  clearSearch(): void {
    this.searchField.setValue('');
  }

  private filterItems(items: Array<ItemReturnType>, searchTerm: string): Array<ItemReturnType> {
    const selectedItems = this.selectionModel.selected;
    const unselectedItems = items.filter(item => !selectedItems.includes(item));

    const defaultSearchLambda = items[0] instanceof SelectionItem ?
      DefaultSearchLambdas.selectionItem as any :
      DefaultSearchLambdas.any;

    return [
      ...selectedItems,
      ...unselectedItems.filter(item => (this.data.searchLambda ?? defaultSearchLambda)(item, searchTerm))
    ];
  }

  private sortItems(items: Array<ItemReturnType>): Array<ItemReturnType> {
    const selectedItems = this.selectionModel.selected;
    const unselectedItems = items.filter(item => !selectedItems.includes(item));

    const defaultSortLambda = items[0] instanceof SelectionItem ?
      DefaultSortLambdas.selectionItem as any :
      DefaultSortLambdas.unsorted;

    return [
      ...selectedItems.sort(this.data.sortLambda ?? defaultSortLambda),
      ...unselectedItems.sort(this.data.sortLambda ?? defaultSortLambda)
    ];
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
  }
}
