import { Injectable } from '@angular/core';

/**
 * Stores selected facets to adjust the facet components.
 */
@Injectable()
export class FacetSelectionService {
  private readonly _states: Record<string, FacetSelectionService.SelectionState> = {};

  /**
   * Retuns the selection state for the given key.
   * @param key A unique key of a facet.
   */
  public getSelectionState<TValue extends string = string>(key: string): FacetSelectionService.SelectionState<TValue> {
    return (this._states[key] ??= new FacetSelectionService.SelectionState<TValue>()) as FacetSelectionService.SelectionState<TValue>;
  }
}

export namespace FacetSelectionService {
  /**
   * Describes the selection of one facet.
   */
  export class SelectionState<TValue extends string = string> {
    private _selected: Partial<Record<TValue, boolean>> = {};

    /**
     * Retunrs whether the given facet value is selected.
     * @param val The value of the facet.
     */
    isSelected(val: TValue): boolean {
      return this._selected[val] === true;
    }

    /**
     * Returns whether the selection state for the given value is set at least once.
     * @param val The value of the facet.
     */
    isSet(val: TValue): boolean {
      return this._selected[val] != null;
    }

    /**
     * Toggles the selection state of the given facet value.
     * @param val The value of the facet.
     * @param selected The target state of the value. If not given, the current state ist toggled.
     * @returns Whether the value is selected AFTER the call.
     */
    toggleSelected(val: TValue, selected?: boolean): boolean {
      return this._selected[val] = selected ?? !this.isSelected(val);
    }

    /**
     * Sets and overwrites the selection state.
     * @param selected The values with their selection states.
     */
    setSelected(selected: Partial<Record<TValue, boolean>>): void {
      this._selected = {
        ...selected,
      };
    }

    /**
     * Returns the selected values.
     */
    *getSelectedValues(): Iterable<TValue> {
      for (const [v, s] of Object.entries(this._selected)) {
        if (s) {
          yield v as TValue;
        }
      }
    }
  }
}
