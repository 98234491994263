import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import {
  AnforderungenHistorieWidgetData,
  AnforderungenOffenWidgetData,
  MedicalShopKennzahlenDto,
  MeineOrganisationWidgetData,
} from '@mp/dashboard/data-access';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { Observable } from 'rxjs';
import { DashboardFacade } from './dashboard.facade';

@Component({
  selector: 'mp-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  @HostBinding('class') readonly class = 'mp-dashboard';

  medicalShopWidgetData$: Observable<MedicalShopKennzahlenDto | undefined> =
    this.facade.medicalShopWidgetData$;
  meineOrganisationWidgetData$: Observable<MeineOrganisationWidgetData | undefined> =
    this.facade.meineOrganisationWidgetData$;
  anforderungenOffenWidgetData$: Observable<AnforderungenOffenWidgetData | undefined> =
    this.facade.anforderungenOffenWidgetData$;
  anforderungenHistorieWidgetData$: Observable<AnforderungenHistorieWidgetData | undefined> =
    this.facade.anforderungenHistorieWidgetData$;
  anforderungenGesamtwertData$: Observable<number | undefined> =
    this.facade.anforderungenGesamtwertData$;

  readonly readRequisitionsRights: RechtFilter = {
    resource: Resources.Anforderungen,
    action: Actions.Read,
  } as const;
  readonly readAllRequisitionsRights: RechtFilter = {
    resource: Resources.AlleAnforderungen,
    action: Actions.Read,
  } as const;
  readonly readOrganizationRights: RechtFilter = {
    resource: Resources.MyOrganisationWidget,
    action: Actions.Read,
  } as const;
  readonly medicalShopWriteRights: RechtFilter = {
    resource: Resources.Carts,
    action: Actions.Write,
  } as const;

  readonly showContentManagementWidgetRight: RechtFilter = {
    resource: Resources.ContentManagementArticle,
    action: Actions.Read,
  } as const;
  readonly showDatasourceUploadWidgetRight: RechtFilter = {
    resource: Resources.FileUploadCM,
    action: Actions.Use,
  };
  readonly showMedicalShopWidgetRight: RechtFilter = {
    resource: Resources.MedicalShopWidget,
    action: Actions.Show,
  } as const;
  readonly showPendingRequisitionsWidgetRight: RechtFilter = {
    resource: Resources.PendingRequisitionsWidget,
    action: Actions.Show,
  } as const;
  readonly showRequisitionsHistoryWidgetRight: RechtFilter = {
    resource: Resources.RequisitionsHistoryWidget,
    action: Actions.Show,
  } as const;

  constructor(private readonly facade: DashboardFacade) {
    this.facade.loadAllWidgetData();
  }
}
