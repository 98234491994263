import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { ElementDirective } from '../core/element';

@Directive()
export class ValueElementDirective<T = any> extends ElementDirective implements ControlValueAccessor {
  /**
   * Gets or sets the value of the input element.
   */
  @Input() set value(value: T | undefined) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      if (this.valueChanged) {
        this.valueChanged(value);
      }
    }
  }
  get value(): T | undefined {
    return this.innerValue;
  }
  /**
   * Updates the current value of the input component in data model.
   */
  private valueChanged?: (_: any) => void;
  /**
   * Indicates the current input component is touched.
   */
  private touched?: () => void;
  /**
   * Property for internal handling of the input component value.
   */
  private innerValue?: T;
  /**
   * Writes a new value to the element.
   * This method will be called by the forms API to write to the view when programmatic (model -> view) changes are requested.
   * @param value - New value to the element.
   */
  writeValue(value: any): void {
    if (value !== undefined) {
      this.innerValue = value;
    }
  }
  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * This is called by the forms API on initialization so it can update the form model when values propagate from the view (view -> model).
   * @param fn - Callback function that should be called when the control´s value changes.
   */
  registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }
  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * This is called by the forms API on initialization so it can update the form model on blur.
   * @param fn - Callback function that should be called when the control receives a blur event.
   */
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  /**
   * This function is called by the forms API when the control status changes to or from "DISABLED".
   * Depending on the value, it should enable or disable the appropriate DOM element.
   * @param isDisabled - Value if form element is disabled.
   */
  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
