import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../models';

@Pipe({ name: 'mpcmCategoryBreadcrumb' })
export class CategoryBreadcrumbPipe implements PipeTransform {
  transform(crumb?: Category): Array<Category> | undefined {
    if (!crumb) {
      return undefined;
    }

    const crumbs: Array<Category> = [];

    do {
      crumbs.unshift(crumb);
      crumb = crumb.parent;
    } while (crumb);

    return crumbs;
  }
}