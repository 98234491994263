import { OverlayRef } from '@angular/cdk/overlay';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { CustomOverlayRef } from '../custom-overlay/custom-overlay-ref';
import { FlyoutComponent } from './flyout-component';

type ObservedType<T> = T extends Observable<infer U> ? U : never;

export class ComponentFlyoutRef<T extends FlyoutComponent<any>> extends CustomOverlayRef<T> {

  private readonly _afterClosed$ = new Subject<ObservedType<T['afterClosed$']>>();
  readonly afterClosed$ = this._afterClosed$.pipe(take(1));

  private _flyoutComponentInstance?: T;

  override set componentInstance(instance: T | undefined) {
    this._flyoutComponentInstance = instance;

    if (instance) {
      this.setupAfterClosed$(instance);
    }
  }

  override get componentInstance(): T | undefined {
    return this._flyoutComponentInstance;
  }

  /* This constructor is needed for Angulars dependency injection */
  constructor(overlayRef: OverlayRef) {
    super(overlayRef);
  }

  override close(returnValue?: ObservedType<T['afterClosed$']>): void {
    if (!this.componentInstance) {
      throw Error('"componentInstance" is undefined!');
    }

    this.componentInstance.close(returnValue);
  }

  private setupAfterClosed$(componenInstance: T): void {
    componenInstance
      .afterClosed$
        .pipe(take(1))
        .subscribe({ next: returnValue => {
          this.disposeAndFreeFromMemory();

          this._afterClosed$.next(returnValue);
          this._afterClosed$.complete();
        } });
  }
}
