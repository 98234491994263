import { ModuleWithProviders } from '@angular/core';

import { provideMockStore } from '@ngrx/store/testing';

import { RechtFilter, Rolle } from '@mp/shared/data-access';
import { PROFIL_FEATURE_KEY } from './store/profil.reducer';
import { Profil } from './profil';
import { ProfilService } from './profil.service';
import type { ProfilState } from './store/profil.reducer';
import { UserInfoFacade } from './user-info.facade';
import { UserInfoModule } from './user-info.module';


export const MOCK_PROFIL: Omit<Profil, 'activeOrganisationRechte' | 'activeOrganisationRollen'> = {
  id: 1,

  vorname: 'Mock',
  nachname: 'Profil',
  email: 'mock.profil@plattform.local',

  bild: '<BILD>',
  activeOrganisation: 'Mock-Organisation',
  activeOrganisationId: 1,
  activeLizenz: '<LIZENZ>',
  activeLizenzStart: new Date(),
  activeLizenzEnde: new Date(),
  organisationen: [],
};

/**
 * Testing-Modul für das Mocken / Simulieren der Rechte-/ Rollenprüfung.
 * 
 * Erlaubt -- je nach Situation -- das (automatisierte) Mocken von User-Profil, -Rollen und -Rechten.
 * 
 * ---
 * Die Rechteprüfung wird durch die {@link UserInfoFacade} realisiert.
 * Diese verwendet intern allerdings den alten NgRx-Store, weshalb dieser bei jedem Unit-Test
 * gemocked werden müsste.
 * 
 * Um diesen Aufwand zu kompensieren, kann -- statt des {@link UserInfoModule}s -- einfach dieses Modul importiert werden.
 * 
 * Mittels der statischen {@link UserInfoTestingModule.forMockUser} Methode wird dann automatisch
 * ein Mock-Store mit passendem Initial-State in das Modul provided (siehe {@link provideMockStore}).
 * 
 * ---
 * @example
 * TestBed.configureTestingModule({
 *   imports: [
 *     ...,
 *     UserInfoTestingModule.forMockUser({
 *       profil: {
 *         vorname: 'Organisations',
 *         nachname: 'Besitzer'
 *       },
 *       rechte: [
 *         { 'resource': 'Organisationen', 'action': 'Lesen' },
 *         { 'resource': 'Organisationen', 'action': 'Schreiben' }
 *       ],
 *       rollen: [
 *         'Organisationsbesitzer'
 *       ]
 *     })
 *   ]
 * });
*/
export class UserInfoTestingModule {
  static forMockUser(config?: MockInitialStateConfig): ModuleWithProviders<UserInfoModule> {
    return {
      ngModule: UserInfoModule,
      providers: [

        ProfilService,
        UserInfoFacade,

        provideMockStore({
          initialState: { [PROFIL_FEATURE_KEY]: {
            isLoading: false,
            profil: buildMockInitialState(config) as Profil
          } },
        })
      ]
    };
  }
}

/** Konfiguration für die Vorinitialisierung eines Mock-{@link ProfilState}s. */
type MockInitialStateConfig = {
  /**
   * Optionaler Wert, der für {@link ProfilState.profil} verwendet werden soll.
   * 
   * Falls `null` oder `undefined`, wird `MOCK_USER` verwendet. */
  profil?: Partial<Profil>;
  /**
   * Optionaler Wert, der für {@link Profil.activeOrganisationRollen} verwendet werden soll.
   * 
   * Falls `null` oder `undefined`, wird `[]` verwendet. */
  rollen?: Array<Rolle>;
  /**
   * Optionaler Wert, der für {@link Profil.activeOrganisationRechte} verwendet werden soll.
   *
   * Optional, falls `null` oder `undefined`, wird `[]` verwendet. */
  rechte?: Array<RechtFilter>;
};
function buildMockInitialState(config?: MockInitialStateConfig): Partial<Profil> {
  return {
    ...(config?.profil ?? MOCK_PROFIL),
    activeOrganisationRechte: config?.rechte ?? [],
    activeOrganisationRollen: config?.rollen ?? []
  };
}