import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { FormsManager, TypedForm, filterUndefined } from '@core/shared/util';
import { ImageTypeUtils } from '@core/shared/data-access';
import { Profil } from '@mp/shared/profil/data-access';
import { ProfilFacade } from '../profil.facade';
import { takeUntil } from 'rxjs/operators';

type EditProfil = ImageTypeUtils.WithImageFile<Pick<Profil, 'vorname' | 'nachname' | 'bild' | 'email'>>;
type ProfilEditFormsState = { 'profil-edit': EditProfil };

@Component({
  selector: 'mp-profil-edit',
  templateUrl: './profil-edit.component.html'
})
export class ProfilEditComponent implements OnInit, OnDestroy {

  readonly form: TypedForm<EditProfil>;

  private lastUploadedImageId?: string;

  readonly isLoading$: Observable<boolean>;
  readonly profil$: Observable<Profil | undefined>;
  readonly name$: Observable<string>;
  readonly image$: Observable<string | undefined>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    fb: UntypedFormBuilder,
    private readonly formsManager: FormsManager<ProfilEditFormsState>,
    private readonly facade: ProfilFacade
  ) {
    this.form = this.buildForm(fb);

    this.isLoading$ = this.facade.isLoading$;
    this.name$ = this.facade.name$;
    this.image$ = this.facade.image$;
    this.profil$ = this.facade.profil$.pipe(filterUndefined());
  }

  private buildForm(fb: UntypedFormBuilder): TypedForm<EditProfil> {
    const editForm = fb.group({
      id: [null],
      vorname: [null, Validators.required],
      nachname: [null, Validators.required],
      email: [{ value: '', disabled: true }],
      bild: [null]
    });

    return this.formsManager.createForm('profil-edit', editForm);
  }

  ngOnInit(): void {
    this.form
      .valueChanges$<File>('bild')
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (bild: File) => bild ? this.uploadImage(bild) : this.resetImage()
      });
    this.reload();
  }

  private uploadImage(imageFile: File): void {
    if (imageFile && imageFile instanceof File) {
      this.facade.uploadProfilePictureAndGetImageId(imageFile)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (imageId: string) => {
            if (imageId) {
              this.lastUploadedImageId = imageId;
            }
          }
        });
    } else if (imageFile && typeof imageFile === 'string') {
      this.lastUploadedImageId = imageFile;
    }
  }

  private resetImage(): void {
    this.lastUploadedImageId = undefined;
  }

  private reload(): void {
    this.facade.reload();
  }

  update(): void {
    const formValue: EditProfil = this.form.value();

    this.facade.update({
      ...formValue,
      bild: this.lastUploadedImageId!
    });
  }

  cancel(): void {
    this.facade.cancelUpdate();
  }

  changePassword(passwort: string): void {
    this.facade.changePassword(passwort);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
