import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Category } from '../../models/category';

@Component({
  selector: 'mpcm-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbItemComponent {
  private _categoriesPath!: Category;

  get categoriesPath(): Category {
    return this._categoriesPath;
  }
  @Input()
  set categoriesPath(value: Category) {
    this._categoriesPath = value;
  }

  /**
   * Variablen zum Anzeigen/Ausblenden der einzelnen Komponenten-Elemente
   * Variables for showing/hiding individual component elements
   */
  @Input() showParents = false;
  @Input() showIcons = false;
  @Input() showAllCategoryCodes = false;
  @Input() showLastCategoryCode = false;

  @Input() basePath!: Array<string>;
}
