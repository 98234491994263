import { Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

@Injectable()
export class AppInsightsFacade {
  constructor(private appInsights: AppInsightsService) {}

  trackEvent(
    eventName: InsightsEvent,
    customProperties?: InsightsEventProperties
  ) {
    this.appInsights.trackEvent(eventName, customProperties);
  }
}

export enum InsightsEvent {
  EXPORT_STARTED = 'Export started',
  EXPORT_ITEM_DOWNLOAD = 'Export item download',
}

export enum InsightsEventScope {
  ARTIKELSUCHE = 'ARTIKELSUCHE',
  FAVOURITIES = 'FAVOURITIES',
  EXPORTS_HISTORY = 'EXPORTS_HISTORY',
}

export type InsightsEventProperties = {
  scope?: InsightsEventScope;
  [key: string]: unknown;
};
