import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { InfiniteScrollComponent } from './infinite-scroll.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { UtilPipesModule } from '@core/shared/util';


@NgModule({
  imports: [
    CommonModule,

    MatCardModule,
    MatButtonModule,
    MatIconModule,
    ScrollingModule,

    SpinnerModule,
    UtilPipesModule
  ],
  declarations: [InfiniteScrollComponent],
  exports: [InfiniteScrollComponent]
})
export class InfiniteScrollModule { }