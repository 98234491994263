/* eslint-disable @typescript-eslint/member-ordering */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'mp-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent implements OnDestroy {
  @HostBinding('class') get classes() {
    return ['mp-search-field', this.appearance];
  }

  @Input() appearance: 'flat' | 'raised' = 'flat';
  @Input() placeholder = 'Durchsuchen';

  @Input()
  @HostBinding('class.mp-search-field--disabled')
  get disabled(): BooleanInput {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.searchField[this._disabled ? 'disable' : 'enable']();
  }

  private _disabled = false;

  @Input()
  get value(): string {
    return this.searchField.value;
  }

  set value(newValue: string) {
    if (newValue == null) return;
    if (this.value === newValue) return;
    this.searchField.setValue(newValue);
  }

  @Input()
  set displayValue(newValue: string) {
    this.searchField.setValue(newValue, { emitEvent: false });
  }

  @Output() readonly cleared = new EventEmitter<void>();
  @Output() readonly searched = new EventEmitter<string>();
  @Output() readonly searchTerm = new EventEmitter<string>();
  @Output() readonly valueChanges = new EventEmitter<string>();

  readonly searchField = new UntypedFormControl('');
  private readonly isDestroyed$ = new Subject<void>();

  constructor() {
    this.setupValueChangesEmitter();
    this.setupSearchTermEmitter();
    this.setupClearedEmitter();
  }

  private setupValueChangesEmitter(): void {
    this.searchField.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({ next: (value) => this.valueChanges.next(value) });
  }

  private setupSearchTermEmitter(): void {
    this.searchField.valueChanges
      .pipe(
        debounceTime(500),
        map((searchTerm: string) => searchTerm.trim()),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      )
      .subscribe({ next: (searchTerm) => this.searchTerm.next(searchTerm) });
  }

  private setupClearedEmitter(): void {
    this.searchTerm
      .pipe(
        filter((searchTerm) => searchTerm.length === 0),
        takeUntil(this.isDestroyed$)
      )
      .subscribe({ next: () => this.cleared.next() });
  }

  search(searchTerm: string): void {
    this.value = searchTerm;
    this.emitSearchEvent();
  }

  clear(): void {
    this.value = '';
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  emitSearchEvent(): void {
    this.searched.emit(this.searchField.value);
  }
}
