import { Pipe, PipeTransform } from '@angular/core';
import { ArticleStatus } from '../../models';

const ARTICLE_STATUS_NAME_MAP: Record<ArticleStatus, string> = {
  [ArticleStatus.Available]: 'Aktiv',
  [ArticleStatus.Changed]: 'Geändert',
  [ArticleStatus.Discontinued]: 'Außer Handel',
  [ArticleStatus.New]: 'Neu',
  };

@Pipe({ name: 'mpcmArticleStatusTranslation' })
export class ArticleStatusTranslationPipe implements PipeTransform {
  transform(articleStatus: ArticleStatus): string {
    return ARTICLE_STATUS_NAME_MAP[articleStatus];
  }
}
