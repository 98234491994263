export enum CategoryType {
  /**
   * ECLASS anzeigen (Standard)
   */
  ECLASS = 'ECLASS',

  /**
   * EKK anzeigen
   */
  EKK = 'EKK',

  /**
   * ATC anzeigen
   */
  ATC = 'ATC',

  /**
   * AGKAMED anzeigen
   */
  AGKAMED = 'AGKAMED',
}

export const categoryTypeDescriptions : Record<CategoryType, string> = {
  [CategoryType.ECLASS]: 'ECLASS',
  [CategoryType.ATC]: 'ATC-DDD',
  [CategoryType.AGKAMED]: 'AGKclassMedical',
  [CategoryType.EKK]: 'ClassEKK+',
};
