import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilPipesModule } from '@core/shared/util';
import { ArticleTypeIconModule } from '@mpcm/shared';
import {
  ArticleItemDetailsComponent,
  ArticleItemPriceComponent,
  ArticleItemUnitComponent,
} from './components';
import { ArticleItemUnitPricePipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    ArticleTypeIconModule,
    UtilPipesModule,
  ],
  declarations: [
    ArticleItemPriceComponent,
    ArticleItemUnitComponent,
    ArticleItemDetailsComponent,
    ArticleItemUnitPricePipe,
  ],
  exports: [
    ArticleItemPriceComponent,
    ArticleItemUnitComponent,
    ArticleItemDetailsComponent,
  ],
})
export class ArticleItemModule {}
