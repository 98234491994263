import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { NumericSpinner } from './numeric-spinner';

@NgModule({
  imports: [
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    CommonModule
  ],
  declarations: [
    NumericSpinner
  ],
  exports: [
    NumericSpinner
  ]
})
export class NumericSpinnerModule {}
