import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveComponentModule } from '@ngrx/component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FilterBadgeModule } from '@core/ui';
import { UtilDirectivesModule } from '@core/shared/util';

import {
  AttributeFacetComponent,
  BoolFacetComponent,
  ListBaseFacetComponent,
  ListFacetBucketDirective,
  MultiSelectFacetComponent,
  SingleSelectFacetComponent,
  TreeFacetComponent,
  TreeFacetSearchResultDirective,
} from './components';
import { FacetSelectionService } from './services';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveComponentModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSelectModule,

    UtilDirectivesModule,
    FilterBadgeModule,
  ],
  declarations: [
    AttributeFacetComponent,
    BoolFacetComponent,
    MultiSelectFacetComponent,
    SingleSelectFacetComponent,
    TreeFacetComponent,

    // Internal
    ListBaseFacetComponent,
    ListFacetBucketDirective,
    TreeFacetSearchResultDirective,
  ],
  exports: [
    AttributeFacetComponent,
    BoolFacetComponent,
    MultiSelectFacetComponent,
    SingleSelectFacetComponent,
    TreeFacetComponent
  ],
  providers: [
    // Default instance of FacetSelectionService
    { provide: FacetSelectionService, useClass: FacetSelectionService },
  ],
})
export class FacetsModule {
}
