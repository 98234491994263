import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { EntityIconPipe } from '../entity-icon.pipe';
import { EntityListGroupComponent } from '../entity-list-group/entity-list-group.component';
import { EntityListHeadModule } from '../entity-list-head/entity-list-head.module';
import { EntityListModule } from '../entity-list/entity-list.module';

import { UtilPipesModule } from '@core/shared/util';


@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    EntityListModule,
    EntityListHeadModule,

    UtilPipesModule
  ],
  declarations: [
    EntityListGroupComponent,
    EntityIconPipe
  ],
  exports: [
    EntityListGroupComponent,
    UtilPipesModule
  ],
  providers: [EntityIconPipe]
})

export class EntityListGroupModule { }
