import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { KatalogtypIconComponent } from './katalogtyp-icon.component';


@NgModule({
    imports: [MatIconModule],
    declarations: [KatalogtypIconComponent],
    exports: [KatalogtypIconComponent]
})
export class KatalogtypIconModule {}