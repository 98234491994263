<ng-container *ngrxLet="value$ as currentValue">
  <mat-form-field [appearance]="appearance">

    <mat-label>{{ label }}</mat-label>

    <mat-chip-list
      #chipList
      [formControl]="control">

      <mat-chip
        *ngIf="currentValue"
        class="mat-chip-selected truncate"
        color="accent"
        selected>
        <span class="lieferant-nummer" *ngIf="currentValue.externeNummer">{{ currentValue.externeNummer }}</span>
        <span class="lieferant-name truncate">{{ currentValue.name }}</span>
      </mat-chip>
      <input
        #input
        [placeholder]="placeholder"
        [class.hidden]="currentValue"
        [matAutocomplete]="autocomplete.matAutocomplete"
        [matChipInputFor]="chipList"
        (input)="autocomplete.filterBy($event.target.value)">
    </mat-chip-list>

    <mat-icon
      matPrefix
      style="margin: 0 16px 0 8px"
      [class.material-icons-outlined]="!((autocomplete.lastSelected?.entity?.name === currentValue?.name) && currentValue?.name)">
      {{ icon }}
    </mat-icon>

    <button
      mat-icon-button
      matSuffix
      [disabled]="(!currentValue && !input.value) || _disabled"
      (click)="clearUI(input)">
      <mat-icon>clear</mat-icon>
    </button>

    <mat-error *ngIf="errors">{{ errors }}</mat-error>

  </mat-form-field>

</ng-container>

<mp-entity-autocomplete
  #autocomplete="mpEntityAutocomplete"
  autoActiveFirstOption
  [optionsFetcher]="optionsFetcher"
  [optionTemplate]="lieferantTemplate"
  [className]="'lieferant-option'"
  (optionSelected)="updateValue($event?.entity)">

  <ng-template #lieferantTemplate let-item>
    <div class="lieferant-item-template">
      <div class="lieferant-item-icon">
        <mp-lieferant-icon [supplier]="item.entity"></mp-lieferant-icon>
      </div>
      <div class="lieferant-item-text">
        <h4>{{ item.header }}</h4>
        <span *ngIf="item.subheader">{{ item.subheader }}</span>
      </div>
    </div>
  </ng-template>

</mp-entity-autocomplete>
