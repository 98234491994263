import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { EffectsBase, PageResponse } from '@mp/shared/data-access';
import { Organisation } from '../organisation';
import { OrganisationenActions } from './organisationen.actions';
import { OrganisationenService } from '../organisationen.service';

@Injectable()
export class OrganisationenEffects extends EffectsBase {

  loadSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationenActions.COMPONENT.loadSingle),
      exhaustMap(({ queryParams }) =>
        this.getRouterParamIdByKey('organisationId').pipe(
          switchMap(id => this.service.get(id, queryParams))
        )
      ),
      map((organisation: Partial<Organisation>) => {
        const loadedOrganisation: Organisation = organisation as Organisation;
        return OrganisationenActions.API.loadedSingleSuccessfully({ loadedOrganisation });
      })
    );
  }
);

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationenActions.COMPONENT.loadAll),
      exhaustMap(({ queryParams }) => this.service.getAll(queryParams)),
      map((organisationenPage: PageResponse<Partial<Organisation>>) => {
        const loadedOrganisationenPage: PageResponse<Organisation> = organisationenPage as PageResponse<Organisation>;
        return OrganisationenActions.API.loadedAllSuccessfully({ loadedOrganisationenPage });
      })
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationenActions.COMPONENT.create),
      mergeMap(action => this.service.create(action.organisationToCreate)),
      map((createdOrganisation: Organisation) => OrganisationenActions.API.createdSuccessfully({ createdOrganisation })),
      tap(() => { this.navigateBack(); })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationenActions.COMPONENT.update),
      mergeMap(action => this.service.update(action.organisationToUpdate)),
      map((updatedOrganisation: Organisation) => OrganisationenActions.API.updatedSuccessfully({ updatedOrganisation })),
      tap(() => { this.navigateBack(); })
    );
  });

  cancel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationenActions.COMPONENT.cancel),
      map(OrganisationenActions.API.canceled),
      tap(() => { this.navigateBack(); })
    );
  });

  constructor(
    injector: Injector,
    private readonly actions$: Actions,
    private readonly service: OrganisationenService
  ) {
    super(injector);
  }
}
