import { Action, createReducer, on } from '@ngrx/store';

import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';
import { Rolle } from '../rolle';
import { RollenActions } from './rollen.actions';

export const ROLLEN_FEATURE_KEY = 'rollen';

export interface RollenPartialState {
  readonly [ROLLEN_FEATURE_KEY]: RollenState;
}

export type RollenState = BasicState<Rolle>;
export const initialState: RollenState = buildInitialBasicState();

const reducer = createReducer(
  initialState,

  on(
    RollenActions.COMPONENT.loadAll,
    RollenActions.COMPONENT.loadSingle,
    RollenActions.COMPONENT.create,
    RollenActions.COMPONENT.update,
    RollenActions.COMPONENT.deleteSingle,
    (state): RollenState => ({
    ...state,
    isLoading: true
  })),

  on(RollenActions.API.loadedAllSuccessfully, (state, action): RollenState => ({
    ...state,
    isLoading: false,
    entities: action.loadedRollenPage.data,
    pagination: action.loadedRollenPage.pagination
  })),

  on(RollenActions.API.createdSuccessfully, (state, action): RollenState => ({
    ...state,
    isLoading: false,
    entities: [action.createdRolle, ...(state.entities ?? [])]
  })),

  on(RollenActions.API.loadedSingleSuccessfully, (state, action): RollenState => ({
    ...state,
    isLoading: false,
    selected: action.loadedRolle
  })),

  on(RollenActions.API.updatedSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    entities: [
      ...(state.entities ?? []).map((entity: Rolle) =>
        entity.id === action.updatedRolle.id ? action.updatedRolle : entity
      )
    ],
    selected: undefined
  })),

  on(RollenActions.API.deletedSingleSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    entities: [
      ...(state.entities ?? []).filter((entity: Rolle) =>
        entity.id !== action.deletedRolle.id
      )
    ],
    selected: state.selected && (action.deletedRolle.id === state.selected.id) ? undefined : state.selected
  })),

  on(
    RollenActions.API.loadedAllUnsuccessfully,
    RollenActions.API.loadedSingleUnsuccessfully,
    RollenActions.API.deletedSingleUnsuccessfully,
    RollenActions.API.createdUnsuccessfully,
    RollenActions.API.updatedUnsuccessfully,
    (state): RollenState => ({
    ...state,
    isLoading: false
  })),

  on(
    RollenActions.API.canceledCreate,
    RollenActions.API.canceledUpdate,
    (state): RollenState => ({
    ...state,
    isLoading: false,
    selected: undefined
  }))
);

export function rollenReducer(
  state: RollenState | undefined,
  action: Action
): RollenState {
  return reducer(state, action);
}
