import { AsyncPipe, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { SpinnerModule, UploadFormComponent, UploadSchedulerService } from '@core/ui';
import { Actions, Pagination, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { Observable } from 'rxjs';
import { UploadEntryStatus } from '../../models';
import { DatasourceUploadListItemActionsMenuComponent } from '../datasource-upload-list-item-actions-menu/datasource-upload-list-item-actions-menu.component';
import { DatasourceUploadListItemComponent } from '../datasource-upload-list-item/datasource-upload-list-item.component';
import { FileUploadHandlerComponent } from '../file-upload-handler/file-upload-handler.component';
import { DatasourceUploadSchedulerService } from './datasource-upload-scheduler.service';
import { DatasourceUploadWidgetViewModel } from './datasource-upload-widget-view-model';
import { DatasourceUploadWidgetStore } from './datasource-upload-widget.store';

@Component({
  selector: 'mpcm-datasource-upload-widget',
  templateUrl: './datasource-upload-widget.component.html',
  styleUrls: ['./datasource-upload-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SlicePipe,
    NgIf,
    NgForOf,
    AsyncPipe,

    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,

    UploadFormComponent,
    FileUploadHandlerComponent,
    DatasourceUploadListItemComponent,
    DatasourceUploadListItemActionsMenuComponent,
    DashboardWidgetComponent,
    SpinnerModule,
    UserInfoModule,
  ],
  providers: [
    DatasourceUploadWidgetStore,
    { provide: UploadSchedulerService, useClass: DatasourceUploadSchedulerService },
  ],
})
export class DatasourceUploadWidgetComponent {
  @HostBinding('class') readonly class = 'mpcm-datasource-upload-widget';

  disabled = false;
  listExpanded = false;

  readonly vm$: Observable<DatasourceUploadWidgetViewModel> = this.store.vm$;

  readonly collapsedListLength = 3;
  readonly acceptedFileTypes: string = '.zip, .rar, .xlsx, .xslm, .xls, .csv, .xml';
  readonly maxFileBytes: number = 500 * 1024 * 1024;

  readonly editFileRight: RechtFilter = {
    resource: Resources.FileUploadCM,
    action: Actions.Edit,
  } as const;

  constructor(
    private readonly store: DatasourceUploadWidgetStore,
    private readonly uploadSchedulerService: UploadSchedulerService
  ) {
    this.store.fetchUploadedFiles();
  }

  toggleListExpand(): void {
    this.listExpanded = !this.listExpanded;
  }

  onPageChange(pageEvent: PageEvent): void {
    const { pageIndex, pageSize } = pageEvent;
    const paginationParams: Pagination = {
      page: pageIndex + 1,
      pageSize: pageSize,
    };
    this.store.fetchUploadedFiles(paginationParams);
  }

  onFileDelete(fileId: string): void {
    this.store.deleteUploadedFile(fileId);
  }

  onFileStatusChange(fileId: string, status: UploadEntryStatus): void {
    this.store.updateUploadedFileStatus({ fileId, status });
  }

  onFileAttach(fileId: string): void {
    this.uploadSchedulerService.triggerUpload({ fileId });
  }
}
