import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KatalogLieferantTitelComponent } from './katalog-lieferant-titel.component';
import { KatalogtypIconModule } from '../katalogtyp-icon/katalogtyp-icon.module';
import { LieferantAutocompleteInputModule } from '../lieferanten-autocomplete-input/lieferanten-autocomplete-input.module';

@NgModule({
  imports: [
    CommonModule,
    LieferantAutocompleteInputModule,
    KatalogtypIconModule
  ],
  declarations: [KatalogLieferantTitelComponent],
  exports: [KatalogLieferantTitelComponent]
})
export class KatalogLieferantTitelModule {}
