import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneNonAlphanumericCharacter,
  atLeastOneUppercaseLetter,
  atLeastSixCharacters,
  passwordsMatchValidator } from './password-validators';
import { ElementDirective } from '../core/element';

type Requirement = { met: boolean, text: string };

@Component({
  selector: 'mp-change-password-input',
  templateUrl: './change-password-input.component.html'
})
export class ChangePasswordInputComponent extends ElementDirective {

  @Output() readonly changePassword = new EventEmitter<string>();

  form: UntypedFormGroup;
  passwordRequirements$: Observable<Array<Requirement>>;

  constructor() {
    super();
    this. class = 'mp-change-password-input';
    this.form = this.buildForm();
    this.passwordRequirements$ = this.buildPasswordRequirements$();
  }

  private buildPasswordRequirements$(): Observable<Array<Requirement>> {
    return this.form
      .valueChanges
      .pipe(
        startWith(() => this.buildPasswordRequirements()),
        map(() => this.buildPasswordRequirements()),
        map((reqs: Array<Requirement>) => reqs.every(({ met }) => met) ? [] : reqs)
      );
  }

  private buildPasswordRequirements(): Array<Requirement> {
    return [
      {
        met: !this.form.getError('atLeastSixCharacters', 'password'),
        text: 'Mindestens 6 Zeichen'
      },
      {
        met: !this.form.getError('atLeastOneLowercaseLetter', 'password'),
        text: 'Mindestens ein Kleinbuchstabe'
      },
      {
        met: !this.form.getError('atLeastOneUppercaseLetter', 'password'),
        text: 'Mindestens ein Großbuchstabe'
      },
      {
        met: !this.form.getError('atLeastOneDigit', 'password'),
        text: 'Mindestens eine Zahl'
      },
      {
        met: !this.form.getError('atLeastOneNonAlphanumericCharacter', 'password'),
        text: 'Mindestens ein Sonderzeichen'
      }
    ];
  }

  private buildForm(): UntypedFormGroup {
    const passwordValidators = [
      atLeastSixCharacters,
      atLeastOneDigit,
      atLeastOneLowercaseLetter,
      atLeastOneUppercaseLetter,
      atLeastOneNonAlphanumericCharacter
    ];

    return new UntypedFormGroup({
      password: new UntypedFormControl('Platzhalter', passwordValidators),
      passwordConfirmation: new UntypedFormControl('Platzhalter')
    }, passwordsMatchValidator);
  }

  onButtonClick(): void {
    const password = this.form.get('password')?.value;
    this.changePassword.emit(password);
  }

  onFirstPasswordInputClick(): void {
    const passwordInput = this.form.get('password');

    if (passwordInput?.pristine) {
      passwordInput.setValue('');
    }
  }

  onFirstPasswordConfirmationInputClick(): void {
    const passwordConfirmationInput = this.form.get('passwordConfirmation');

    if (passwordConfirmationInput?.pristine) {
      passwordConfirmationInput.setValue('');
    }
  }
}
