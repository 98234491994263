import { Directive, Input, OnDestroy } from '@angular/core';

import { Form } from '../typed-wrappers/form';

@Directive({
  selector: '[mpForm]'
})
export class FormsDirective<T> implements OnDestroy {

  /**
   * The form to manage with this directive. 
   * If no form was provided, all passed values are silently ignored.
   */
  @Input('mpForm') form?: Form<T>;

  @Input() set setInitialValue(value: any) {
    if (value) {
      setTimeout(() => {
        this.form?.reset();
        this.form?.setValue(value);
        this.form?.setInitialValue();
      });
    }
  }

  @Input() set patchInitialValue(value: any) {
    if (value) {
      setTimeout(() => {
        this.form?.reset();
        this.form?.patchValue(value);
        this.form?.setInitialValue();
      });
    }
  }

  // TODO: Check if setting this to false solves the *ngIf issues ?!
  @Input() destroyWhenRemovedFromDOM: boolean;

  constructor() {
    this.destroyWhenRemovedFromDOM = true;
  }

  ngOnDestroy(): void {
    if (this.destroyWhenRemovedFromDOM) {
      this.form?.destroy();
    }
  }
}
