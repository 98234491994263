import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';

import { Action, createReducer, on } from '@ngrx/store';

import { Benutzer } from '../benutzer';
import { BenutzerActions } from './benutzer.actions';

export const BENUTZER_FEATURE_KEY = 'benutzer';

export interface BenutzerPartialState {
  readonly [BENUTZER_FEATURE_KEY]: BenutzerState;
}

export type BenutzerState = BasicState<Benutzer>;
export const initialState: BenutzerState = buildInitialBasicState();

const reducer = createReducer(
  initialState,

  on(
    BenutzerActions.COMPONENT.loadSingle,
    BenutzerActions.COMPONENT.loadAll,
    BenutzerActions.COMPONENT.create,
    BenutzerActions.COMPONENT.update,

    (state): BenutzerState => ({
      ...state,
      isLoading: true
    })
  ),

  on(
    BenutzerActions.API.loadedSingleSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      selected: action.loadedBenutzer
    })
  ),

  on(
    BenutzerActions.API.loadedAllSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      entities: action.loadedBenutzerPage.data,
      pagination: action.loadedBenutzerPage.pagination
    })
  ),

  on(
    BenutzerActions.API.createdSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      entities: [action.createdBenutzer, ...(state.entities ?? [])],
      selected: undefined
    })
  ),

  on(
    BenutzerActions.API.updatedSuccessfully,

    (state, action): BenutzerState => {
      const entitiesWithUpdatedEntityReplaced = [
        ...(state.entities ?? []).map(entity =>
          entity.id === action.updatedBenutzer.id ?
            action.updatedBenutzer :
            entity
        )
      ];

      return {
        ...state,
        isLoading: false,
        entities: entitiesWithUpdatedEntityReplaced,
        selected: undefined
      };
    }
  ),

  on(
    BenutzerActions.API.canceledUpdate,
    BenutzerActions.API.canceledCreation,

    (state): BenutzerState => ({
      ...state,
      isLoading: false,
      selected: undefined
    })
  )
);

export function benutzerReducer(
  state: BenutzerState | undefined,
  action: Action
): BenutzerState {
  return reducer(state, action);
}
