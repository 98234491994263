/* eslint-disable rxjs/finnish */
import { Injectable, Injector, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SelectorFlyoutConfig, SelectorFlyoutData } from './selector-flyout-config';
import { CustomOverlayConfig } from '../custom-overlay/custom-overlay-config';
import { CustomOverlayService } from '../custom-overlay/custom-overlay.service';
import { SelectorComponent } from './component/selector.component';
import { SelectorFlyoutRef } from './selector-flyout-ref';


const SELECTOR_FLYOUT_CONFIG: CustomOverlayConfig = {
  hasBackdrop: true,
  overlayConfig: {}
};

@Injectable({ providedIn: 'root' })
export class SelectorFlyoutService extends CustomOverlayService {

  private currentlyOpenedRef?: SelectorFlyoutRef<any>;
  private readonly router: Router;

  constructor(injector: Injector) {
    super(injector);

    this.router = injector.get(Router);
  }

  openMultipleSelection<T>(
    title: string,
    items: Array<T> | Observable<Array<T>>,
    initiallySelected: Array<T> = [],
    itemTemplate?: TemplateRef<unknown>,
    overlayConfig: CustomOverlayConfig = {}
  ): SelectorFlyoutRef<T> {
    const data: SelectorFlyoutData<T> = {
      title,
      items,
      initiallySelected,
      itemTemplate,

      multiple: true
    };

    return this.open<T>(data, overlayConfig);
  }

  openSingleSelection<T>(
    title: string,
    items: Array<T> | Observable<Array<T>>,
    itemTemplate?: TemplateRef<T>,
    overlayConfig: CustomOverlayConfig = {}
  ): SelectorFlyoutRef<T> {
    const data: SelectorFlyoutData<T> = {
      title,
      items,
      itemTemplate,

      multiple: false
    };

    return this.open<T>(data, overlayConfig);
  }

  open<T>(selectionData: SelectorFlyoutData<T>, overlayConfig: CustomOverlayConfig = {}): SelectorFlyoutRef<T> {
    const selectorConfig = this.buildSelectorConfigFromData(selectionData, overlayConfig);

    const overlayRef = this.createOverlay(selectorConfig);
    const selectorRef = new SelectorFlyoutRef<T>(overlayRef);

    const flyoutComponentRef = this.attachOverlayContainer(
      SelectorComponent,
      overlayRef,
      selectorConfig,
      selectorRef
    );

    // TODO: If this any is removed the unit test no longer works?
    selectorRef.initializeWithComponentRef(flyoutComponentRef as any);

    this.registerRouterListener(selectorRef);
    if (selectorConfig.hasBackdrop) {
      this.registerBackdropListener(selectorRef, overlayRef);
    }

    this.currentlyOpenedRef = selectorRef;
    selectorRef
      .afterClosed$
      .pipe(take(1))
      .subscribe({ next: () => this.currentlyOpenedRef = undefined });

    return selectorRef;
  }

  private buildSelectorConfigFromData<T>(
    selectionData: SelectorFlyoutData<T>,
    overlayConfig: CustomOverlayConfig
  ): SelectorFlyoutConfig<T> {
    return {
      ...SELECTOR_FLYOUT_CONFIG,
      ...overlayConfig,
      payload: { ...selectionData }
    };
  }

  private registerRouterListener<T>(selectorRef: SelectorFlyoutRef<T>): void {
    this.router.events
      .pipe(takeUntil(selectorRef.afterClosed$))
      .subscribe({ next: event => {
        if (event instanceof NavigationStart) {
          selectorRef.close();
        }
    }});
  }

  private registerBackdropListener<T>(selectorRef: SelectorFlyoutRef<T>, overlayRef: OverlayRef) {
    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe({ next: () => selectorRef.close() });
  }

  protected override createOverlay<T>(selectorConfig: SelectorFlyoutConfig<T>): OverlayRef {
    const overlayConfig = this.buildOverlayConfig(selectorConfig.overlayConfig);

    return this.overlay.create(overlayConfig);
  }

  private buildOverlayConfig(overlayConfigOverrides: OverlayConfig = {}): OverlayConfig {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay.position()
      .global()
      .right()
      .top();

    const overlayConfig: OverlayConfig = {
      panelClass: 'mp-flyout',
      scrollStrategy,
      positionStrategy,

      ...overlayConfigOverrides
    };

    return overlayConfig;
  }

  isCurrentlyOpened(): boolean {
    return !!this.currentlyOpenedRef?.componentInstance;
  }
}
