import { Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalErrorHandlerService } from '@core/shared/util';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AppInsightsConfig } from '../domain/app-insights-config';
import { AppInsightsConfigProvider } from './app-insights-config-provider';

@Injectable()
export class AppInsightsService implements OnDestroy {
  private _appInsightsInstance?: ApplicationInsights;

  private _appInsightsErrorHandlerInstance?: ApplicationinsightsAngularpluginErrorService;

  private appInsights$: Observable<ApplicationInsights> =
    this.appInsightsConfigProvider.appInsightsConfig$.pipe(
      map((appInsightsConfig) => this.createAppInsights(appInsightsConfig)),
      tap((appInsights) => {
        this.addAppInsightsErrorHandler();
        this.loadAppInsights(appInsights);
      }),
      shareReplay(1)
    );

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private appInsightsConfigProvider: AppInsightsConfigProvider,
    @Optional() private globalErrorHandler?: GlobalErrorHandlerService
  ) {
    // Init application insights upon service creation
    this.appInsights$.subscribe();
  }

  ngOnDestroy() {
    this.destroyAppInsights();
    this.removeAppInsightsErrorHandler();
  }

  trackEvent(
    name: string,
    customProperties?: { [key: string]: unknown }
  ): void {
    this.dispatchInsightsAction((appInsights) =>
      appInsights.trackEvent({ name }, customProperties)
    );
  }

  private createAppInsights(
    insightsConfig: AppInsightsConfig
  ): ApplicationInsights {
    const angularPlugin: AngularPlugin = new AngularPlugin();

    this._appInsightsInstance = new ApplicationInsights({
      config: {
        connectionString: insightsConfig.connectionString,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: this.router,
          },
        },
      },
    });

    return this._appInsightsInstance;
  }

  private loadAppInsights(appInsights: ApplicationInsights): void {
    this.ngZone.runOutsideAngular(() => appInsights.loadAppInsights());
  }

  private destroyAppInsights(): void {
    if (!this._appInsightsInstance) {
      return;
    }

    this._appInsightsInstance.unload();
    this._appInsightsInstance = undefined;
  }

  private dispatchInsightsAction(action: AppInsightsAction): void {
    this.appInsights$.subscribe((appInsights) =>
      this.ngZone.runOutsideAngular(() => action(appInsights))
    );
  }

  private addAppInsightsErrorHandler() {
    if (this.globalErrorHandler) {
      this._appInsightsErrorHandlerInstance =
        new ApplicationinsightsAngularpluginErrorService();
      this.globalErrorHandler.addErrorHandler(
        this._appInsightsErrorHandlerInstance
      );
    }
  }

  private removeAppInsightsErrorHandler() {
    if (!this._appInsightsErrorHandlerInstance) {
      return;
    }

    this.globalErrorHandler?.removeErrorHandler(
      this._appInsightsErrorHandlerInstance
    );

    this._appInsightsErrorHandlerInstance = undefined;
  }
}

type AppInsightsAction = (appInsights: ApplicationInsights) => void;
