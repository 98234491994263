import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ImageGalleryOverlayComponent } from './overlay/image-gallery-overlay';
import { ImageGalleryViewerComponent } from './image-gallery-viewer.component';
import { UtilPipesModule } from '@core/shared/util';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    UtilPipesModule
  ],
  declarations: [
    ImageGalleryViewerComponent,
    ImageGalleryOverlayComponent
  ],
  exports: [
    ImageGalleryViewerComponent,
    ImageGalleryOverlayComponent
  ]
})
export class ImageViewerModule {}
