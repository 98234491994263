import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { RechtFilter, Rolle } from '@mp/shared/data-access';
import { Profil } from './profil';
import { ProfilActions } from './store/profil.actions';
import { profilSelectQuery } from './store/profil.selectors';

@Injectable({ providedIn: 'root' })
export class UserInfoFacade {
  private readonly _profil$ = new BehaviorSubject(
    undefined as undefined | Profil
  );
  readonly profil$: Observable<Profil | undefined>;

  readonly rechte$: Observable<undefined | Array<RechtFilter>>;
  readonly rollen$: Observable<undefined | Array<Rolle>>;

  get profil(): Profil | undefined {
    return this._profil$.getValue();
  }
  get profilId(): number {
    return this.profil?.id ?? 0;
  }

  constructor(private readonly store$: Store) {
    this.profil$ = this.store$
      .select(profilSelectQuery.PROFIL)
      .pipe(first((profil) => !!profil));

    this.rechte$ = this.profil$.pipe(
      map((profil) => profil?.activeOrganisationRechte)
    );
    this.rollen$ = this.profil$.pipe(
      map((profil) => profil?.activeOrganisationRollen)
    );

    this.profil$.subscribe({ next: (profil) => this._profil$.next(profil) });
  }

  protected load(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.loadInitial());
  }

  /** Alias for {@link load()}. */
  reload(): void {
    this.load();
  }

  private tryGettingProfil(): Profil | undefined {
    const profil = this._profil$.getValue();
    if (profil === undefined) {
      return profil;
    }

    return profil;
  }

  hasRecht(recht: RechtFilter): boolean {
    const profil = this.tryGettingProfil();

    if (!profil) {
      return false;
    }

    return profil.activeOrganisationRechte.some((benutzerRecht) =>
      rechteMatch(benutzerRecht, recht)
    );
  }

  /**
   * Returns an observable that emits true if the user has the given Recht.
   * If the previous backend-request failed or wasn't executed, `undefined` is returned. */
  watchRecht$(recht: RechtFilter): Observable<undefined | boolean> {
    return this.rechte$.pipe(
      map((rechte) =>
        rechte
          ? rechte.some((benutzerRecht) => rechteMatch(benutzerRecht, recht))
          : undefined
      )
    );
  }

  hasRolle(rolle: Rolle): boolean {
    const profil = this.tryGettingProfil();

    if (!profil) {
      return false;
    }

    return profil.activeOrganisationRollen.some(
      (benutzerRolle) => benutzerRolle === rolle
    );
  }

  /**
   * Returns an observable that emits true if the user has the given Rolle.
   * If the previous backend-request failed or wasn't executed, `undefined` is returned. */
  watchRolle$(rolle: Rolle): Observable<undefined | boolean> {
    return this.rollen$.pipe(
      map((rollen) =>
        rollen
          ? rollen.some((benutzerRolle) => benutzerRolle === rolle)
          : undefined
      )
    );
  }
}

function rechteMatch(a: RechtFilter, b: RechtFilter): boolean {
  return a.resource === b.resource && a.action === b.action;
}
