import { Identifiable } from '@core/shared/data-access';
import { RelationEntityState } from '@mp/shared/data-access';

type Organisation = Identifiable;

type OrganisationDatenquelle = {
  id: number;
  organisationId: number;
  datenquelleId: string;
  istAktiv: boolean;
  typ: Katalogtyp;
  name: string;
  state: RelationEntityState;
}

export enum Katalogtyp {
  UNKNOWN = 0,
  KLINIK = 1,
  HERSTELLER = 2,
  HAENDLER = 3,
  DATENPROVIDER = 4
}

export interface KatalogDatenquelle {
  katalog: string;
  typ: Katalogtyp;
  id: string;
}

// TODO: Replace this hacky conversion:
export function katalogToOrganisationDatenquelle(
  datenquelle: KatalogDatenquelle,
  istAktiv: boolean,
  state: RelationEntityState,
  organisation?: Organisation
): OrganisationDatenquelle {
  return {
    id: undefined,
    organisationId: organisation?.id,
    datenquelleId: datenquelle?.id,
    istAktiv,
    typ: datenquelle.typ,
    name: datenquelle.katalog,
    state
  } as unknown as OrganisationDatenquelle;
}
