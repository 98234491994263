import { createAction, props } from '@ngrx/store';

import {
  CreateOrganisationBenutzer,
  CreateRolleBenutzer,
  OrganisationBenutzer,
  RolleBenutzer,
  UpdateOrganisationBenutzer
} from '../organisation-benutzer';
import { PageResponse } from '@mp/shared/data-access';
import { QueryParams } from '@core/shared/util';

type UpdateRelations = {
  rollenToAdd?: Array<CreateRolleBenutzer>,
  rollenToRemove?: Array<RolleBenutzer>,

  kostenstellenToAdd?: Array<number>,
  kostenstellenToRemove?: Array<number>
};

type UpdatedRelations = {
  addedRollen?: Array<boolean>,
  removedRollen?: Array<boolean>,

  addedKostenstellen?: Array<boolean>,
  removedKostenstellen?: Array<boolean>
};

type CreateRelations = Pick<UpdateRelations, 'rollenToAdd' | 'kostenstellenToAdd'>;
type CreatedRelations = Pick<UpdatedRelations, 'addedRollen' | 'addedKostenstellen'>;

const loadSingle = createAction(
  '[Organisation-Benutzer Edit Component] Load Organisation-Benutzer',
  props<{ queryParams?: QueryParams<OrganisationBenutzer, Partial<OrganisationBenutzer>> }>()
);

const loadedSingleSuccessfully = createAction(
  '[Organisation-Benutzer Service] Loaded Organisation-Benutzer',
  props<{ loadedBenutzer: OrganisationBenutzer }>()
);

const loadedSingleUnsuccessfully = createAction(
  '[Organisation-Benutzer Service] Loaded Single Organisations-Benutzer Unsuccessfully',
  props<{ error: unknown }>()
);

const loadAll = createAction(
  '[Organisation-Benutzer List Component] Load All Organisation-Benutzer',
  props<{ queryParams?: QueryParams<OrganisationBenutzer, Partial<OrganisationBenutzer>> }>()
);

const loadedAllSuccessfully = createAction(
  '[Organisation-Benutzer Service] Loaded All Organisation-Benutzer',
  props<{ loadedBenutzerPage: PageResponse<OrganisationBenutzer> }>()
);

const loadedAllUnsuccessfully = createAction(
  '[Organisations-Benutzer Service] Loaded All Organisations-Benutzer Unsuccessfully',
  props<{ error: unknown }>()
);

const create = createAction(
  '[Organisation-Benutzer Create Component] Create Organisation-Benutzer',
  props<{ benutzerToCreate: CreateOrganisationBenutzer } & CreateRelations>()
);

const createdSuccessfully = createAction(
  '[Organisation-Benutzer Service] Created Organisation-Benutzer',
  props<{ createdBenutzer: OrganisationBenutzer } & CreatedRelations>()
);

const createdUnsuccessfully = createAction(
  '[Organisations-Benutzer Service] Could Not Create Organisations-Benutzer',
  props<{ error: unknown }>()
);

const update = createAction(
  '[Organisation-Benutzer Edit Component] Update Organisation-Benutzer',
  props<{ benutzerToUpdate: UpdateOrganisationBenutzer } & UpdateRelations>()
);

const updatedSuccessfully = createAction(
  '[Organisation-Benutzer Service] Updated Organisation-Benutzer',
  props<{ updatedBenutzer: OrganisationBenutzer } & UpdatedRelations>()
);

const updatedUnsuccessfully = createAction(
  '[Organisations-Benutzer Service] Could Not Update Organisations-Benutzer',
  props<{ error: unknown }>()
);

const cancelUpdate = createAction(
  '[Organisation-Benutzer Edit Component] Cancel Organisation-Benutzer Update'
);

const canceledUpdate = createAction(
  '[Organisation-Benutzer Edit Component] Canceled Organisation-Benutzer Update'
);

const cancelCreate = createAction(
  '[Organisation-Benutzer Create Component] Cancel Organisation-Benutzer Create'
);

const canceledCreate = createAction(
  '[Organisation-Benutzer Create Component] Canceled Organisation-Benutzer Create'
);

export namespace OrganisationBenutzerActions {
  export const API = {
    loadedSingleSuccessfully,
    loadedSingleUnsuccessfully,
    loadedAllSuccessfully,
    loadedAllUnsuccessfully,
    updatedSuccessfully,
    updatedUnsuccessfully,
    createdSuccessfully,
    createdUnsuccessfully,
    canceledUpdate,
    canceledCreate
  };

  export const COMPONENT = {
    loadSingle,
    loadAll,
    update,
    create,
    cancelUpdate,
    cancelCreate
  };
}
