import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CountUpOptions } from '@core/shared/util';

import { AnforderungenHistorieWidgetData } from '@mp/dashboard/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';

@Component({
  selector: 'mp-anforderungen-historie-widget',
  templateUrl: './anforderungen-historie-widget.component.html',
  styleUrls: ['./anforderungen-historie-widget.component.scss'],
  standalone: true,
  imports: [NgClass, DatePipe, NgIf, DashboardWidgetComponent, CountUpStatComponent],
})
export class AnforderungenHistorieWidgetComponent implements OnInit {
  @Input() numbers?: AnforderungenHistorieWidgetData;
  @Input() value?: number;

  timeNow?: Date;

  readonly countUpOptionsCurrency = CountUpOptions.Currency;

  ngOnInit(): void {
    this.timeNow = new Date();
  }
}
