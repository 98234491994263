import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

import { AvatarModule } from '../avatar/avatar.module';
import { EntityItemModule } from './entity-item/entity-item.module';
import { EntityItemSlotsModule } from './entity-item-slots/entity-item-slots.module';
import { EntityListGroupModule } from './entity-list-group/entity-list-group.module';
import { EntityListHeadModule } from './entity-list-head/entity-list-head.module';
import { EntityListModule } from './entity-list/entity-list.module';

@NgModule({
  imports: [
    CommonModule,

    MatButtonModule,
    MatChipsModule,
    MatIconModule,

    AvatarModule,

    EntityItemModule,
    EntityListModule,
    EntityListHeadModule,
    EntityListGroupModule,
    EntityItemSlotsModule,
  ],
  exports: [
    EntityItemModule,
    EntityListModule,
    EntityListHeadModule,
    EntityItemSlotsModule,
    EntityListGroupModule,
  ]
})

export class EntityItemsModule { }
