import { ComponentRef, Injector } from '@angular/core';

import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

import { CustomOverlayConfig } from './custom-overlay-config';
import { CustomOverlayRef } from './custom-overlay-ref';
import { OVERLAY_DATA } from './custom-overlay-tokens';

export abstract class CustomOverlayService {

  protected readonly overlay: Overlay;

  constructor(protected injector: Injector) {
    this.overlay = this.injector.get(Overlay);
  }

  protected attachOverlayContainer<T>(
    componentType: ComponentType<T>,
    overlayRef: OverlayRef,
    customOverlayConfig: CustomOverlayConfig,
    customOverlayRef: CustomOverlayRef<T>
  ): ComponentRef<T> {
    const injector = this.createInjector(customOverlayConfig, customOverlayRef);

    const containerPortal = new ComponentPortal(componentType, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);

    return containerRef;
  }

  protected createInjector<T>(config: CustomOverlayConfig, customOverlayRef: CustomOverlayRef<T>): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(CustomOverlayRef, customOverlayRef);
    injectionTokens.set(OVERLAY_DATA, config.payload);

    return new PortalInjector(this.injector, injectionTokens);
  }

  protected createOverlay(config: OverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }
}
