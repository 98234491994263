import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { ActionbarComponent } from './actionbar.component';
import { ActionbarItemDirective } from './actionbar-item.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule
  ],
  declarations: [
    ActionbarComponent,
    ActionbarItemDirective
  ],
  exports: [
    ActionbarComponent,
    ActionbarItemDirective
  ]
})
export class ActionbarModule { }
