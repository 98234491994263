import { NgModule } from '@angular/core';

import { CharacterLimitDirective } from './character-limit.directive';
import { DigitsDirective } from './digits.directive';

@NgModule({
  declarations: [
    CharacterLimitDirective,
    DigitsDirective
  ],
  exports: [
    CharacterLimitDirective,
    DigitsDirective
  ]
})
export class InputModule { }
