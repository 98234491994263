import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  AppConfigService,
  Applikation,
  ApplikationenService,
} from '@mp/shared/data-access';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppFacade } from './app.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  applications: Array<Applikation> = [];
  showOrganisationSelector = false;

  appTitle$: Observable<string> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.appTitle)
  );

  showAppLogo$: Observable<boolean | undefined> =
    this.appConfigService.envConfig$.pipe(
      map((envConfig) => envConfig.showAppLogo)
    );

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly applicationsService: ApplikationenService,
    private readonly appConfigService: AppConfigService,
    private readonly facade: AppFacade,
  ) { }

  ngOnInit(): void {
    this.facade.shouldSwitch$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (switchVal) => {
        this.showOrganisationSelector = !!switchVal;
      },
    });

    this.applications = this.applicationsService.applications;
    this.facade.loadProfileAndStoreActiveOrganisationId();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
