<img
  *ngIf="state === ArticleImageState.Loaded || state === ArticleImageState.Loading; else errorTemplate"
  class="article-image"
  [class.article-image--loading]="state === ArticleImageState.Loading"
  [alt]="alternateText"
  [src]="imagePath"
  (load)="onImgLoad()"
  (error)="onImgError()"
/>

<div
  *ngIf="state === ArticleImageState.Loading"
  class="placeholder"
>
  <mp-spinner class="placeholder__spinner"></mp-spinner>
</div>

<ng-template #errorTemplate>
  <div class="placeholder">
    <mat-icon class="placeholder__image-icon" svgIcon="mdi:image"></mat-icon>
  </div>
</ng-template>
