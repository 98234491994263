import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';

import { Organisation } from '../organisation';
import { OrganisationsHierarchieData } from '@mp/system/organisationshierarchien/data-access';

export type OrganisationAddedEvent = { parentOrganisationId: number | null, organisationen: Array<Organisation> };

@Component({
  selector: 'mp-organisationshierarchie-node',
  templateUrl: './organisationshierarchie-node.component.html',
  styleUrls: ['./organisationshierarchie-node.component.scss']
})
export class OrganisationsHierarchieNodeComponent implements OnDestroy {

  @HostBinding() readonly class = 'mp-organisationshierarchie-node';

  @Input() readonly = false;

  @Input() data?: OrganisationsHierarchieData;
  @Input() organisationen: Array<Organisation> = [];

  @Output() readonly addNodes = new EventEmitter<number>();
  @Output() readonly removeNode = new EventEmitter<number>();

  ngOnDestroy(): void {
    this.addNodes.complete();
    this.removeNode.complete();
  }
}
