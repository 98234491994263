<ng-container *ngIf="(news$ | async) as news">
    <div class="create-button">
      <ng-container *ngIf="schreibrecht | rechtIsHeldByUser | async">
        <mat-card>
          <ng-container *ngIf="!createNewMessage">
            <button
              mat-button
              color="accent"
              (click)="create()">
              <mat-icon>add</mat-icon>
              <span>&nbsp;Neue Nachricht erstellen</span>
            </button>
          </ng-container>

          <ng-container *ngIf="createNewMessage">
            <div class="edit-toolbar flex justify-between flex-wrap">
              <button
                mat-button
                color="accent"
                (click)="createNews()"
                [disabled]="!titel || !nachricht">
                <mat-icon>done</mat-icon>
                <span>&nbsp;Speichern</span>
              </button>
              <button
                mat-button
                color="accent"
                (click)="cancel(false)">
                <mat-icon>edit_off</mat-icon>
                <span>&nbsp;Abbrechen</span>
              </button>
            </div>

            <mat-form-field class="edit-header">
              <input
                matInput
                required
                placeholder="Überschrift"
                maxlength="50"
                [(ngModel)]="titel">
              <mat-error *ngIf="!titel">
                Bitte den Titel angeben.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="edit-message">
              <textarea
                matInput
                required
                placeholder="Nachricht eingeben"
                maxlength="1000"
                [(ngModel)]="nachricht">
              </textarea>
              <mat-error *ngIf="!nachricht">
                Bitte die Nachricht angeben.
              </mat-error>
            </mat-form-field>
          </ng-container>
        </mat-card>
      </ng-container>
    </div>

  <ng-container *ngIf="news.length === 0;">
    <p>Keine neuen Nachrichten.</p>
  </ng-container>

  <mat-accordion multi>
    <ng-container *ngIf="news.length > 0;">
      <ng-container *ngFor="let benachrichtigung of news;">
        <ng-container *ngIf="!(userCanOnlyReadNewsfeed$ | async); then orgaBesitzerTemplate; else benutzerTemplate;">
        </ng-container>

        <ng-template #orgaBesitzerTemplate>
          <ng-container *ngIf="editMode.get(benachrichtigung.id); then editTemplate; else readTemplate;"></ng-container>
        </ng-template>

        <ng-template #readTemplate>
          <div class="read-row">
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title class="header-title">
                  {{ benachrichtigung.titel }}
                </mat-panel-title>
                <div class="createdAt">{{ benachrichtigung.createdAt | date }}</div>
                <button
                  *ngIf="schreibrecht | rechtIsHeldByUser | async"
                  mat-icon-button
                  class="header-icon-edit"
                  (click)="setEditMode(true, benachrichtigung.id)">
                  <mat-icon>edit</mat-icon>
                </button>
              </mat-expansion-panel-header>

              <span class="read-row--content" [innerText]="benachrichtigung.nachricht"></span>
            </mat-expansion-panel>
          </div>
        </ng-template>

        <ng-template #editTemplate>
          <mat-card>
            <div class="edit-toolbar flex justify-between flex-wrap">
              <button
                mat-button
                color="accent"
                [disabled]="!benachrichtigung.titel || !benachrichtigung.nachricht"
                (click)="updateNews(benachrichtigung.id, benachrichtigung.titel, benachrichtigung.nachricht)">
                <mat-icon>done</mat-icon>
                <span class="hidden lg:inline-block">&nbsp;Speichern</span>
              </button>
              <button
                mat-button
                color="accent"
                (click)="cancel(true, benachrichtigung.id)">
                <mat-icon>edit_off</mat-icon>
                <span class="hidden lg:inline-block">&nbsp;Abbrechen</span>
              </button>
              <button
                mat-button
                color="accent"
                (click)="deleteNews(benachrichtigung.id)">
                <mat-icon>close</mat-icon>
                <span class="hidden lg:inline-block">&nbsp;Löschen</span>
              </button>
            </div>

            <mat-form-field class="edit-header">
              <input
                matInput
                required
                placeholder="Überschrift"
                maxlength="50"
                [(ngModel)]="benachrichtigung.titel">
              <mat-error *ngIf="!benachrichtigung.titel">
              Bitte den Titel angeben.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="edit-message">
              <textarea
                matInput
                required
                placeholder="Nachricht eingeben"
                maxlength="1000"
                [(ngModel)]="benachrichtigung.nachricht">
              </textarea>
              <mat-error *ngIf="!benachrichtigung.nachricht">
                Bitte die Nachricht angeben.
              </mat-error>
            </mat-form-field>
          </mat-card>
        </ng-template>
        <ng-template #benutzerTemplate>
          <div class="read-row">
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title class="header-title">
                  {{ benachrichtigung.titel }}
                </mat-panel-title>
                <div class="createdAt">{{ benachrichtigung.createdAt | date }}</div>
              </mat-expansion-panel-header>
              <span [innerText]="benachrichtigung.nachricht"></span>
            </mat-expansion-panel>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </mat-accordion>
  <ng-container *ngIf="pagination$ | async as pagination">
    <mat-paginator
    *ngIf="pagination.totalRowCount>10"
      showFirstLastButtons
      [length]="pagination.totalRowCount"
      [pageIndex]="pagination.currentPage - 1"
      [pageSize]="pagination.limit"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="onPageChange($event)">
    </mat-paginator>
  </ng-container>
</ng-container>

