import { Injectable } from '@angular/core';
import { PageResponse, Pagination, PaginationMetadata } from '@mp/shared/data-access';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EMPTY, filter, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LoadingStatus } from '../../../models';
import { UploadEntry, UploadEntryStatus } from '../../models';
import { DatasourceUploadFacade } from '../../services';
import { DatasourceUploadWidgetViewModel } from './datasource-upload-widget-view-model';

type UploadedFilesEntityState = EntityState<UploadEntry>;

export const uploadedFilesEntityAdapter = createEntityAdapter<UploadEntry>({
  selectId: (x) => x.uploadEntryId,
});

const { selectAll: selectAllUploadedFiles } = uploadedFilesEntityAdapter.getSelectors();

export interface DatasourceUploadWidgetState {
  uploadedFilesStatus: LoadingStatus;
  uploadedFiles: UploadedFilesEntityState;
  uploadedFilesPaginationMetadata: PaginationMetadata | undefined;
}

export const INITIAL_STATE: DatasourceUploadWidgetState = {
  uploadedFilesStatus: LoadingStatus.Loaded,
  uploadedFiles: uploadedFilesEntityAdapter.getInitialState(),
  uploadedFilesPaginationMetadata: undefined,
};

@Injectable()
export class DatasourceUploadWidgetStore extends ComponentStore<DatasourceUploadWidgetState> {
  constructor(private readonly datasourceUploadFacade: DatasourceUploadFacade) {
    super(INITIAL_STATE);
  }

  readonly uploadedFilesStatus$: Observable<LoadingStatus> = this.select(
    (state) => state.uploadedFilesStatus
  );

  readonly uploadedFilesLoading$: Observable<boolean> = this.select(
    (state) => state.uploadedFilesStatus === LoadingStatus.Loading
  );

  readonly uploadedFiles$: Observable<UploadEntry[]> = this.select((state) =>
    selectAllUploadedFiles(state.uploadedFiles)
  );

  readonly uploadedFilesPaginationMetadata$: Observable<PaginationMetadata | undefined> =
    this.select((state) => state.uploadedFilesPaginationMetadata);

  readonly vm$: Observable<DatasourceUploadWidgetViewModel> = this.select(
    this.uploadedFilesLoading$,
    this.uploadedFiles$,
    this.uploadedFilesPaginationMetadata$,
    (isLoading, uploadedFiles, uploadedFilesPaginationMetadata) => ({
      isLoading,
      uploadedFiles,
      uploadedFilesPaginationMetadata,
    })
  );

  readonly fetchUploadedFiles = this.effect(
    (pagination$: Observable<Pagination | void>): Observable<PageResponse<UploadEntry>> => {
      return pagination$.pipe(
        map((pagination) => pagination ?? this.getCurrentPaginationParams()),
        tap(() => this.initFetchUploadedFiles()),
        switchMap((pagination) =>
          this.datasourceUploadFacade.fetchUploadEntries(pagination).pipe(
            tapResponse(
              (pageResponse: PageResponse<UploadEntry>) =>
                this.setUploadedFilesSuccess(pageResponse),
              () => this.setUploadedFilesError()
            )
          )
        )
      );
    }
  );

  readonly deleteUploadedFile = this.effect((fileId$: Observable<string>): Observable<void> => {
    return fileId$.pipe(
      concatLatestFrom(() => this.uploadedFilesPaginationMetadata$),
      filter(([, paginationMetadata]) => !!paginationMetadata),
      switchMap(([fileId, paginationMetadata]) =>
        this.datasourceUploadFacade.deleteUploadEntry(fileId).pipe(
          tap(() => {
            const pagination = this.getPaginationParamsAfterDeletion(
              paginationMetadata as PaginationMetadata
            );
            this.fetchUploadedFiles(pagination);
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  readonly updateUploadedFileStatus = this.effect(
    (
      fileStatusUpdate$: Observable<{ fileId: string; status: UploadEntryStatus }>
    ): Observable<UploadEntry> => {
      return fileStatusUpdate$.pipe(
        switchMap(({ fileId, status }) =>
          this.datasourceUploadFacade.updateUploadEntryStatus(fileId, status).pipe(
            tap(({ status }) =>
              this.updateUploadedFile({ fileId, uploadedFileChanges: { status } })
            ),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  private readonly initFetchUploadedFiles = this.updater(
    (state): DatasourceUploadWidgetState => ({
      ...state,
      uploadedFilesStatus: LoadingStatus.Loading,
      uploadedFiles: uploadedFilesEntityAdapter.getInitialState(),
      uploadedFilesPaginationMetadata: undefined,
    })
  );

  private readonly setUploadedFilesSuccess = this.updater(
    (state, { data, pagination }: PageResponse<UploadEntry>): DatasourceUploadWidgetState => ({
      ...state,
      uploadedFilesStatus: LoadingStatus.Loaded,
      uploadedFiles: uploadedFilesEntityAdapter.setAll(data, state.uploadedFiles),
      uploadedFilesPaginationMetadata: pagination,
    })
  );

  private readonly setUploadedFilesError = this.updater(
    (state): DatasourceUploadWidgetState => ({
      ...state,
      uploadedFilesStatus: LoadingStatus.Error,
    })
  );

  private readonly updateUploadedFile = this.updater(
    (
      state,
      { fileId, uploadedFileChanges }: { fileId: string; uploadedFileChanges: Partial<UploadEntry> }
    ): DatasourceUploadWidgetState => ({
      ...state,
      uploadedFiles: uploadedFilesEntityAdapter.updateOne(
        { id: fileId, changes: uploadedFileChanges },
        state.uploadedFiles
      ),
    })
  );

  onFileUploadComplete(): void {
    this.fetchUploadedFiles();
  }

  onAttachmentFileUploadComplete(parentFileId: string, updatedUploadEntry: UploadEntry): void {
    this.updateUploadedFile({ fileId: parentFileId, uploadedFileChanges: updatedUploadEntry });
  }

  private getCurrentPaginationParams(): Pagination | undefined {
    const paginationMetadata: PaginationMetadata | undefined =
      this.get().uploadedFilesPaginationMetadata;
    if (!paginationMetadata) return undefined;
    return {
      page: 1,
      pageSize: paginationMetadata.limit,
    };
  }

  private getPaginationParamsAfterDeletion(
    currentPaginationMetadata: PaginationMetadata
  ): Pagination {
    const { currentPage, currentRowCount, limit } = currentPaginationMetadata;
    const page = currentRowCount === 1 ? currentPage - 1 : currentPage;

    return { page, pageSize: limit };
  }
}
