import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInsightsConfig } from '@mp/shared/app-insights/data-access';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfig } from './app-config';

export const APP_CONFIG_DIRECTORY = 'config/app.config.json';

export const APP_INSIGHTS_CONFIG_DIRECTORY = 'config/app-insights.config.json';

@Injectable()
export class ConfigLoaderService {
  constructor(private readonly httpClient: HttpClient) {}

  loadAppConfig(): Observable<AppConfig> {
    return this.httpClient.get<AppConfig>(APP_CONFIG_DIRECTORY).pipe(
      catchError(() => {
        // Error-Handling: Missing config-file
        throw new Error('"app.config.json" fehlt!');
      })
    );
  }

  loadAppInsightsConfig(): Observable<AppInsightsConfig> {
    return this.httpClient
      .get<AppInsightsConfig>(APP_INSIGHTS_CONFIG_DIRECTORY)
      .pipe(
        catchError(() => {
          // Error-Handling: Missing config-file
          throw new Error('"app-insights.config.json" fehlt!');
        })
      );
  }
}
