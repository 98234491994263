import { AuthConfig } from 'angular-oauth2-oidc';

import { Required } from 'utility-types';

import { EnvironmentConfig } from './environment-config';

type RequiredAuthFields = 'issuer' | 'redirectUri' | 'clientId' | 'scope' | 'responseType';
export type AuthConfigWithRequiredFields = Required<AuthConfig, RequiredAuthFields>;

type RequiredEnvFields = 'appTitle' | 'supportMailSubject' | 'supportMailBody' | 'exportSize' | 'favoritesListSize';
export type EnvConfigWithRequiredFields = Required<EnvironmentConfig, RequiredEnvFields>;

export interface AppConfig {
  auth: AuthConfig;
  env: EnvironmentConfig;
}

export const authConfigHasRequiredFields = (object: AuthConfig): object is AuthConfigWithRequiredFields => {
  const config: AuthConfig = object;

  if (!config) {
    return false;
  }

  return (
    config.issuer != null &&
    config.redirectUri != null &&
    config.clientId != null &&
    config.scope != null &&
    config.responseType === 'code'
  );
};

export const envConfigHasRequiredFields = (object: EnvironmentConfig): object is EnvConfigWithRequiredFields => {
  const config: EnvironmentConfig = object;

  if (!config) {
    return false;
  }

  return (
    config.appTitle != null &&
    config.supportMailSubject != null &&
    config.supportMailBody != null &&
    config.exportSize != null &&
    config.favoritesListSize != null
  );
};
