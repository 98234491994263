import { Component } from '@angular/core';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent extends ElementDirective {
  constructor() {
    super();
    this.class = 'mp-spinner';
  }
}
