import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { NgControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[mpCharacterLimit][formControlName],[mpCharacterLimit][formControl]',
  exportAs: 'characterLimit'
})
export class CharacterLimitDirective implements OnInit, OnDestroy {
  
  private readonly unsubscribe$: Subject<void>;

  @HostBinding('attr.maxlength') maxLength?: string;
  @Input('mpCharacterLimit') limit?: string;

  constructor(
    private readonly formField: MatFormField,
    private readonly control: NgControl
  ) {
    this.unsubscribe$ = new Subject();
  }

  ngOnInit(): void {
    this.maxLength = this.limit;
    this.control
      .valueChanges
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe({ next: (value: string) => {
        this.updateHintLabel(value ? value.length : 0); }
      });

    this.updateHintLabel(this.control.value ? this.control.value.length : 0);
  }

  private updateHintLabel(length: number): void {
    this.formField.hintLabel = `${length} / ${this.limit}`;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
