import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CreateNewsfeed, DashboardService, Newsfeed, UpdateNewsfeed } from '@mp/dashboard/data-access';
import { NotificationService, Pagination, PaginationMetadata } from '@mp/shared/data-access';

@Injectable()
export class NewsfeedFacade {

  private readonly _news$ = new BehaviorSubject([] as Array<Newsfeed>);
  readonly news$: Observable<Array<Newsfeed>> = this._news$.asObservable();

  private readonly _pagination$ = new BehaviorSubject(
    undefined as PaginationMetadata | undefined
  );
  readonly pagination$ = this._pagination$.asObservable();

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly toaster: NotificationService
  ) { }

  getNews(requestParams?: Partial<Pagination>): void {
    this.dashboardService.getNews(requestParams).subscribe({
      next: ({ data, pagination }) => {
        this._news$.next(data);
        this._pagination$.next(pagination);
      },
    });
  }

  createNews(news: CreateNewsfeed): void {
    this.dashboardService
      .createNews(news)
      .subscribe({ next: () => {
        this.toaster.toastSuccess('Die Nachricht wurde gespeichert.');
        this.getNews(this.pageLimit);
      }});
  }

  updateNews(news: UpdateNewsfeed): void {
    this.dashboardService
      .updateNews(news)
      .subscribe({ next: () => {
        this.toaster.toastSuccess('Die Nachricht wurde aktualisiert.');
        this.getNews(this.pageLimit);
      }});
  }

  deleteNews(newsId: number): void {
    this.dashboardService
      .deleteNews(newsId)
      .subscribe({ next: () => {
        this.toaster.toastSuccess('Die Nachricht wurde gelöscht.');
        this.getNews(this.paginationParamsAfterDeletion);
      }});
  }

  private get pageLimit(): Partial<Pagination> {
    const pagination: PaginationMetadata | undefined = this._pagination$.getValue();
    const pageSize = pagination?.limit ?? 10;
    return { pageSize };
  }

  private get paginationParamsAfterDeletion(): Partial<Pagination>{
    const pagination: PaginationMetadata | undefined = this._pagination$.getValue();
    if (!pagination) return {page: 1};
      const {currentPage, currentRowCount, limit} = pagination;
      const page = currentRowCount - 1 === 0 ? currentPage - 1 : currentPage;
      return { page, pageSize: limit};
  }
}