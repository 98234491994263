import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnDestroy,
} from '@angular/core';

import { ScrollPositionService } from '../../scroll-position-service';
import { Subject } from 'rxjs';
import { observeElementInViewport } from '../../observe-element-in-viewport/observe-element-in-viewport';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[mpRestoreScrolling]',
})
export class RestoreScrollingDirective implements OnDestroy {
  @Input() itemKey = '';
  private readonly isDestroyed$ = new Subject<void>();
  constructor(
    private readonly _host: ElementRef<HTMLElement>,
    private readonly _scrollPositionService: ScrollPositionService
  ) {
    observeElementInViewport(this._host.nativeElement, [0])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: () => {
          this._host.nativeElement.scrollTo(
            0,
            this._scrollPositionService.scrollPositions[this.itemKey]
          );
        },
      });
  }

  ngOnDestroy() {
    this._scrollPositionService.saveScrollPosition({
      [this.itemKey]: this._host.nativeElement.scrollTop,
    });
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}

@NgModule({
  declarations: [RestoreScrollingDirective],
  exports: [RestoreScrollingDirective],
})
export class RestoreScrollingDirectiveModule {}
