import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfilActionsFacade } from '../profil-actions.facade';
import { ProfilFacade } from '../profil.facade';

@Component({
  selector: 'mp-profil-display',
  templateUrl: './profil-display.component.html',
  styleUrls: ['./profil-display.component.scss']
})
export class ProfilDisplayComponent {

  get name$(): Observable<string> {
    return this.profilFacade.name$;
  }

  get activeOrganisation$(): Observable<string> {
    return this.profilFacade.activeOrganisation$;
  }

  get image$(): Observable<string | undefined> {
    return this.profilFacade.image$;
  }

  get organisationenLength$(): Observable<number> {
    return this.profilFacade.organisationLength$;
  }

  constructor(
    private readonly profilFacade: ProfilFacade,
    private readonly actionsFacade: ProfilActionsFacade
  ) { }

  logout(): void {
    // TODO: Add actual logout logic
    this.actionsFacade.logout();
  }
}
