import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ImageTypeUtils } from '@core/shared/data-access';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AvatarError } from './avatar-error.enums';
import { DisplaySize } from './display-size.type';
import { ValueElementDirective } from '../core/value-element';
import { takeUntil } from 'rxjs/operators';

const AVATAR_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AvatarComponent),
  multi: true
};

@Component({
  selector: 'mp-avatar',
  templateUrl: './avatar.component.html',
  providers: [AVATAR_CONTROL_ACCESSOR]
})
export class AvatarComponent extends ValueElementDirective<File | string> implements OnInit, OnDestroy {

  /* Content Properties */
  @Input() image?: string | null;

  _initials?: string;
  @Input() set name(value: string | undefined | null) {
    this._initials = this.buildInititalsFor(value);
  }

  /* Formatting Properties */
  @Input() size: DisplaySize = 'large';
  @Input() buttonLabel = 'BILD ÄNDERN';
  @Input() selectLabel = 'Bild auswählen';
  @Input() clearLabel = 'Bild entfernen';

  /* Technical Properties */
  _isUpload = false;
  @Input() set upload(value: boolean) {
    this._isUpload = value;
  }
  @Input() fileSizeLimit = 2; // This is in Megabyte
  @Input() acceptedFormats = ImageTypeUtils.DEFAULT_ALLOWED_TYPES;

  /* Events */
  @Output() readonly removeImage = new EventEmitter<void>();
  @Output() readonly fail = new EventEmitter<AvatarError>();

  isLoading = false;

  private readonly destroy$ = new Subject<void>();

  constructor() {
    super();
    this.class = 'mp-avatar';
  }

  ngOnInit(): void {
    if (this.value && typeof this.value === 'string') {
      this.image = this.value;
    }
  }

  ngOnDestroy(): void {
    this.removeImage.complete();
    this.fail.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildInititalsFor(name?: string | null): string {
    if (!name) {
      return '';
    }

    const partsOfName = name.split(' ').filter((part: string) => !!part);
    const nameCanBeSplittedInParts =
      partsOfName.length >= 2
        && partsOfName[0].length > 0
        && partsOfName[1].length > 0;

    return nameCanBeSplittedInParts
      ? partsOfName[0].charAt(0).toUpperCase() + partsOfName[1].charAt(0).toUpperCase()
      : name.charAt(0).toUpperCase();
  }

  onFileChange(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    if (eventTarget) {
      const file = eventTarget.files ? eventTarget.files[0] : null;
      if (!file) {
        return;
      }

      if (!ImageTypeUtils.isDefaultImageType(file.type)) {
        this.fail.emit(AvatarError.WRONG_FILE_TYPE);

        return;
      }

      const fileSizeLimitInByte = this.fileSizeLimit * 1e+6;
      if (file.size > fileSizeLimitInByte) {
        this.fail.emit(AvatarError.FILE_SIZE_LIMIT);

        return;
      }

      this.value = file;
      this.preview(file);
    }
  }

  private preview(fileData: File): void {
    this.isLoading = true;

    this.convertToBase64String(fileData)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({ next: (fileString: string) => {
        this.image = fileString;
        this.isLoading = false;
      } });
  }

  private convertToBase64String(file: File): Observable<string> {
    return new Observable<string>(subscriber => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onerror = () => { subscriber.error(reader.error); };
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };
    });
  }

  clearImage(): void {
    /* Currently this needs to be null, because otherwise a change detection
     * compare (as it's done in the TypedForm) with `undefined` would result in `false`.
     * The preset "empty"-value used when comparing is `null` here due to the backend
     * providing `null` for unset values. */
    this.image = this.value = null as any;
    this.removeImage.next();
  }

  handleImageLoadError(error?: unknown): void {
    this.clearImage();
  }
}
