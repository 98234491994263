import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CountUpOptions } from '@core/shared/util';
import { PageLink } from '@mp/shared/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';
import { Observable } from 'rxjs';
import {
  PurchaseRequisitionQuickAccessFilter,
  PurchaseRequisitionsHistoryCounts,
  PurchaseRequisitionsPeriod,
  PurchaseRequisitionsStateFilterState,
} from '../../../models';
import { RequisitionsPeriodSelectorComponent } from '../requisitions-period-selector/requisitions-period-selector.component';
import { RequisitionsHistoryWidgetStore } from './requisitions-history-widget.store';

@Component({
  selector: 'mpdm-requisitions-history-widget',
  standalone: true,
  templateUrl: './requisitions-history-widget.component.html',
  styleUrls: ['./requisitions-history-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,

    DashboardWidgetComponent,
    RequisitionsPeriodSelectorComponent,
    CountUpStatComponent,
  ],
  providers: [RequisitionsHistoryWidgetStore],
})
export class RequisitionsHistoryWidgetComponent {
  readonly purchaseRequisitionsHistoryCounts$: Observable<
    PurchaseRequisitionsHistoryCounts | undefined
  > = this.store.purchaseRequisitionsHistoryCounts$;

  readonly link: PageLink = ['orders/document-management/purchase-requisitions'];

  readonly defaultPeriod: PurchaseRequisitionsPeriod = this.store.defaultRequisitionsPeriod;

  approvedRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: this.defaultPeriod,
    requisitionState: PurchaseRequisitionsStateFilterState.Approved,
  };

  rejectedPendingRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: this.defaultPeriod,
    requisitionState: PurchaseRequisitionsStateFilterState.Rejected,
  };

  readonly countUpOptionsCurrency: CountUpOptions = CountUpOptions.Currency;

  constructor(private readonly store: RequisitionsHistoryWidgetStore) {
    this.store.fetchPurchaseRequisitionsHistoryCounts(this.defaultPeriod);
  }

  onPeriodChange(period: PurchaseRequisitionsPeriod): void {
    this.approvedRequisitionsParams.numberOfDays = period;
    this.rejectedPendingRequisitionsParams.numberOfDays = period;

    this.store.fetchPurchaseRequisitionsHistoryCounts(period);
  }
}
