import { Injectable } from '@angular/core';
import {
  AppInsightsConfig,
  appInsightsConfigHasRequiredFields,
} from '@mp/shared/app-insights/data-access';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError, filter, map, shareReplay } from 'rxjs/operators';
import { AppConfig, authConfigHasRequiredFields, envConfigHasRequiredFields } from './app-config';
import { ConfigLoaderService } from './config-loader.service';
import { EnvironmentConfig } from './environment-config';

@Injectable()
export class ConfigLoaderFacade {
  private _appConfig$: Observable<AppConfig> = this.configLoaderService
    .loadAppConfig()
    .pipe(shareReplay(1));

  authConfig$: Observable<AuthConfig> = this._appConfig$.pipe(
    map((appConfig) => this.extractFromAppConfig(appConfig, 'auth', authConfigHasRequiredFields)),
    shareReplay(1)
  );

  environmentConfig$: Observable<EnvironmentConfig> = this._appConfig$.pipe(
    map((appConfig) => this.extractFromAppConfig(appConfig, 'env', envConfigHasRequiredFields)),
    catchError((err: unknown) => {
      // Error-Handling: Missing or invalid config-file
      // TODO: Nur eine Zwischenlösung! Fehlerseite soll Fehler in Zukunft aufbereiten und anzeigen.
      this.redirectToErrorPage(-1);
      throw err;
    }),
    shareReplay(1)
  );

  appInsightsConfig$: Observable<AppInsightsConfig> = this.configLoaderService
    .loadAppInsightsConfig()
    .pipe(
      filter((appInsightsConfig) => appInsightsConfigHasRequiredFields(appInsightsConfig)),
      shareReplay(1)
    );

  constructor(private configLoaderService: ConfigLoaderService) {}

  private extractFromAppConfig<
    Property extends keyof AppConfig,
    SubConfig extends AppConfig[Property]
  >(
    appConfig: AppConfig,
    subConfigProperty: Property,
    subConfigValidatorFn: (object: SubConfig) => boolean
  ): SubConfig {
    const subConfig = appConfig[subConfigProperty] as SubConfig;

    if (subConfigValidatorFn(subConfig)) {
      return subConfig;
    }

    throw new Error(`"${subConfigProperty}" section of "app.config.json" is invalid!`);
  }

  // TODO: Ins Shared-Modul umziehen
  private redirectToErrorPage(code = 404): void {
    window.location.assign(`/assets/error.html?code=${code}`);
  }
}
