import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { HierarchieEditorFacade } from '../hierarchie-editor.facade';
import { Organisation } from '../organisation';
import { OrganisationAddedEvent } from '../node/organisationshierarchie-node.component';
import { OrganisationsHierarchieData } from '../organisationshierarchie-data';
import { OrganisationsHierarchienFlyoutFacade } from '../organisationshierarchien-flyout.facade';
import { filterUndefined } from '@core/shared/util';

@Component({
  selector: 'mp-organisationshierarchie-editor',
  templateUrl: './organisationshierarchie-editor.component.html',
  styleUrls: ['./organisationshierarchie-editor.component.scss'],
  providers: [HierarchieEditorFacade]
})
export class OrganisationshierarchieEditorComponent implements OnDestroy {

  @Input()
  get readonly() { return this._readonly; }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  @Input() organisationen: Array<Organisation> = [];
  @Input() set data(data: OrganisationsHierarchieData | undefined) {
    this.editorFacade.initializeWithData(data);
  }

  @Output() readonly cleared = new EventEmitter();
  @Output() readonly organisationAdded = new EventEmitter<OrganisationAddedEvent>();
  @Output() readonly organisationRemoved = new EventEmitter<number>();

  @Output() readonly valueChanges = new EventEmitter<OrganisationsHierarchieData>();

  get value(): OrganisationsHierarchieData | undefined {
    return this.editorFacade.hierarchieData;
  }

  private isDestroyed$ = new Subject<void>();

  constructor(
    readonly editorFacade: HierarchieEditorFacade,
    private readonly flyoutFacade: OrganisationsHierarchienFlyoutFacade
  ) {
    this.editorFacade.hierarchieData$
      .pipe(skip(1), takeUntil(this.isDestroyed$))
      .subscribe({ next: data => this.valueChanges.next(data!) });
  }

  clearHierarchie(): void {
    this.editorFacade.reset();
    this.cleared.emit();
  }

  letUserAddOrganisationen(parentOrganisationId: number): void {

    const selectionItems$ = this.flyoutFacade.buildOrganisationSelectionItems$(
      this.organisationen,
      this.editorFacade.usedOrganisationen$
    );
    const flyoutRef = this.flyoutFacade.promptOrganisationenFlyout(
      selectionItems$,
      parentOrganisationId !== null
    );

    flyoutRef?.afterClosed$
      .pipe(
        filterUndefined(),
        takeUntil(this.isDestroyed$)
      )
      .subscribe({ next: organisationen =>
        this.addOrganisationenNodes(parentOrganisationId, organisationen)
      });
  }

  addOrganisationenNodes(parentOrganisationId: number | null, organisationen: Array<Organisation>): void {
    organisationen.forEach(({ id: organisationId, name: organisationName }) => {
      const nodeToAdd = { organisationId, organisationName, parentOrganisationId, children: [] };
      this.editorFacade.addNode(nodeToAdd);
    });

    this.organisationAdded.emit({ parentOrganisationId, organisationen });
  }

  removeOrganisationNode(organisationId: number): void {
    this.editorFacade.removeNode(organisationId);
    this.organisationRemoved.emit(organisationId);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
