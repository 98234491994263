type Stringifier<T> = (entity: T) => string;
type OptionalStringifier<T> = (entity: T) => string | undefined;

export type SelectionItemWrapper<T> = {
  header: keyof T | Stringifier<T>,
  subheader?: keyof T | OptionalStringifier<T>
};

export type WrappingOptions<T> = {
  exclude?: (item: T) => boolean,
  sort?: (itemA: T, itemB: T) => number
};

export class SelectionItem<T> {

  private readonly headerFromEntity: Stringifier<T>;
  get header(): string {
    return this.headerFromEntity(this.entity);
  }

  private readonly subheaderFromEntity: OptionalStringifier<T>;
  get subheader(): string | undefined {
    return this.subheaderFromEntity(this.entity);
  }

  constructor(
    readonly entity: T,
    wrapper: SelectionItemWrapper<T>
  ) {
    const header = wrapper.header;
    const subheader = wrapper.subheader;

    const headerStringifier: Stringifier<T> =
      typeof header === 'string' ?
        (entityToStringify: T) => String(entityToStringify[header]) :
        header as Stringifier<T>;

    this.headerFromEntity = headerStringifier;

    const subheaderStringifier =
      typeof subheader === 'string' ?
        (entityToStringify: T) => String(entityToStringify[subheader]) :
        subheader as OptionalStringifier<T>;

    this.subheaderFromEntity = subheaderStringifier ?? (() => undefined);

  }

  static wrap<T>(entity: T, itemWrapper: SelectionItemWrapper<T>): SelectionItem<T> {
    return new SelectionItem(entity, itemWrapper);
  }

  static wrapArray<T>(
    arrayOfEntities: Array<T>,
    itemWrapper: SelectionItemWrapper<T>,
    options?: WrappingOptions<T>
  ): Array<SelectionItem<T>> {
    return arrayOfEntities
      .filter(entity => options?.exclude ? !options.exclude(entity) : true)
      .sort((a, b) => options?.sort ? options.sort(a, b) : 0)
      .map(entity => SelectionItem.wrap(entity, itemWrapper));
  }

  static unwrapArray<T>(selectionItems: Array<SelectionItem<T>>): Array<T> {
    return selectionItems.map(item => item.unwrap());
  }

  unwrap(): T {
    return this.entity;
  }

}
