export enum PurchaseRequisitionsStateFilterState {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Failed = 'Failed',
}

export type PurchaseRequisitionsStateFilterInfo = {
  [key in PurchaseRequisitionsStateFilterState]: number;
};
