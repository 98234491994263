import { SupplierAddress } from './supplieraddress';
import { SupplierCommunicationChannel } from './suppliercommunicationchannel';

// TODO: Das muss eigentlich in eine Datei, die von ClinicSuppliers und PlatformSuppliers genutzt wird.
//       Aber dieses data-access/feature Zeugs hindert mich daran, bzw. weiß ich nicht, wie ich das anlege
//       und ob es der Boilerplate Code wert ist.

export enum SupplierCategory {
  ParentOrganization = 0x01,
  Supplier = 0x12,
  Manufacturer = 0x13,
  Division = 0x24,
}

export enum SupplierType {
  ClinicSupplier,
  PlatformSupplier,
}

export interface SupplierBasic {
  supplierId: string,
  name: string;
  shortName?: string;
  externalNumber?: string;
  category?: SupplierCategory;
  type: SupplierType;
  isBlocked?: boolean;
}

export interface SupplierBase extends SupplierBasic {
  // image?: string;
  placeOfBusiness?: string;
  vatId?: string;
  commercialRegisterNumber?: string;
  registryCourt?: string;
  gln?: string;
}

export interface Supplier extends SupplierBase {
  addresses: Array<SupplierAddress>;
  communicationChannels?: SupplierCommunicationChannel;
}

// TODO: EnumDescriptions so ähnlich wie im Import
export const supplierCategoryDescriptions : Readonly<Record<SupplierCategory, string>> = {
  [SupplierCategory.ParentOrganization]: 'Dachorganisation',
  [SupplierCategory.Supplier]: 'Lieferant',
  [SupplierCategory.Manufacturer]: 'Hersteller',
  [SupplierCategory.Division]: 'Division',
};
