import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBadgeComponent } from './filter-badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FilterBadgeComponent],
  exports: [FilterBadgeComponent],
})
export class FilterBadgeModule {}
