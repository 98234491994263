import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { EnumSelectorComponent } from './enum-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    EnumSelectorComponent
  ],
  exports: [
    EnumSelectorComponent
  ]
})
export class EnumSelectorModule {}
