import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectionItem, SelectionItemWrapper, WrappingOptions } from './selection-item';

export function wrapInSelectionItems<T>(
  itemWrapper: SelectionItemWrapper<T>,
  options?: WrappingOptions<T>
): (source$: Observable<Array<T>>) => Observable<Array<SelectionItem<T>>> {
  return (source$: Observable<any>) =>
    source$.pipe(
      map(entities => SelectionItem.wrapArray(
        entities,
        itemWrapper,
        options
      ))
    );
}
