import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressInfoComponent } from './components';
import { FormattedAddressInfoPipe } from './pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [AddressInfoComponent, FormattedAddressInfoPipe],
  exports: [AddressInfoComponent],
})
export class AddressInfoModule {}
