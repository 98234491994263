import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { BasicFlyoutModule } from '../component/basic-flyout/basic-flyout.module';
import { SelectorComponent } from './component/selector.component';
import { SelectorItemComponent } from './component/item/selector-item.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    OverlayModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,

    BasicFlyoutModule
  ],
  declarations: [
    SelectorComponent,
    SelectorItemComponent
  ],
  exports: [
    SelectorComponent,
    SelectorItemComponent
  ]
})
export class SelectorFlyoutModule { }
