import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { ArticleTypeIconComponent } from './component/article-type-icon.component';


@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [ArticleTypeIconComponent],
  exports: [ArticleTypeIconComponent],
})
export class ArticleTypeIconModule {}
