<mat-button-toggle-group
  name="purchaseRequisitionPeriod"
  [value]="selectedPeriod"
  (change)="onPeriodChange($event.value)"
>
  <mat-button-toggle
    *ngFor="let option of periodOptions; trackBy: trackByFn"
    [value]="option.value"
    >{{ option.label }}</mat-button-toggle
  >
</mat-button-toggle-group>
