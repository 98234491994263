import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { ActiveOrganisationInterceptor } from '@mp/shared/data-access';
import { AvatarModule } from '@core/ui';
import { ProfilService } from '@mp/shared/profil/data-access';

import { ActiveOrganisationSelectorComponent } from './active-organisation-selector/active-organisation-selector.component';
import { ActiveOrganisationSelectorFacade } from './active-organisation-selector.facade';
import { UtilPipesModule } from '@core/shared/util';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AvatarModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonModule,

    UtilPipesModule
  ],
  declarations: [
    ActiveOrganisationSelectorComponent
  ],
  providers: [
    ProfilService,
    ActiveOrganisationSelectorFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveOrganisationInterceptor,
      multi: true
    }
  ],
  exports: [
    ActiveOrganisationSelectorComponent
  ]
})
export class ActiveOrganisationSelectorModule { }
