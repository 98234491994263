import { Injectable } from '@angular/core';
import { AuthService, LocalStorageService } from '@core/shared/data-access';

/**
 * Service to get API urls, optionally with authorization information.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  constructor(
    private readonly authService: AuthService,
    private readonly storageService: LocalStorageService) {
  }

  getApiUrl(path: string, includeAuthorization = false): string {
    const link = new URL(path, self.location.origin);

    if (!link.pathname.startsWith('/api')) {
      link.pathname = `/api${path}`;
    }

    if (includeAuthorization) {
      const accessToken = this.authService.getAccessToken();
      // TODO: This has to come out of a kernel service (e.g. profile)
      const activeOrganisationId = this.storageService.readString('activeOrganisationId', null);

      link.searchParams.set('t', accessToken);

      if (activeOrganisationId != null) {
        link.searchParams.set('x-active-organisation', activeOrganisationId);
      }
    }

    return link.toString();
  }
}
