import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import {
  AppInsightsConfigProvider,
  AppInsightsDataAccessModule
} from '@mp/shared/app-insights/data-access';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from './date-adapter';
import {
  AppConfigService,
  HttpErrorInterceptor,
  PlatformRouterSerializer,
  SharedModule
} from '@mp/shared/data-access';
import { REDUCERS_TOKEN, appGlobalEffects, reducerProvider } from './app.reducer';
import { ActiveOrganisationSelectorModule } from '@mp/system/active-organisation-selector/feature';
import { AppComponent } from './app.component';
import { AppFacade } from './app.facade';
import { DashboardModule } from '@mp/dashboard/feature';
import { GlobalErrorHandlerService, IconRegistryModule } from '@core/shared/util';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MatPaginatorIntlGerman } from './locales/mat-paginator-intl-german';
import { ProfilModule } from '@mp/shared/profil/feature';
import { ShellModule } from '@core/ui';
import { appInitProvider } from './init/app-init.service';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

import 'hammerjs';


registerLocaleData(localeDe, 'de', localeDeExtra);

@NgModule({
  imports: [
    ProfilModule,
    SharedModule,
    AppInsightsDataAccessModule,
    ShellModule,
    BrowserModule,
    ActiveOrganisationSelectorModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreRouterConnectingModule.forRoot({ serializer: PlatformRouterSerializer }),
    StoreModule.forRoot(
      REDUCERS_TOKEN,
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true
        }
      }
    ),
    EffectsModule.forRoot(appGlobalEffects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    MatCardModule,
    MatIconModule,
    MatDialogModule,
    DashboardModule,
    HttpClientModule,
    IconRegistryModule
  ],
  providers: [
    appInitProvider,
    reducerProvider,
    AppFacade,
    { provide: AppInsightsConfigProvider, useExisting: AppConfigService },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGerman }
  ],
  declarations: [
    AppComponent,
    LandingPageComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
