<ng-template #loading>
  <mp-spinner></mp-spinner>
</ng-template>

<mp-view viewTitle="Profil bearbeiten" [submittable]="form.submittable$ | async" (send)="update()" (cancel)="cancel()">

  <form [formGroup]="form.formGroup" [mpForm]="form" [patchInitialValue]="profil$ | async">

    <ng-container>

      <mp-view-column>

        <mp-avatar [name]="name$ | async" [image]="image$ | async" formControlName="bild" [upload]="true">
        </mp-avatar>

        <mat-form-field>
          <mat-label>Vorname</mat-label>
          <input matInput placeholder="Vorname" formControlName="vorname" mpCharacterLimit="80">
          <mat-error></mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Nachname</mat-label>
          <input matInput placeholder="Nachname" formControlName="nachname" mpCharacterLimit="80">
          <mat-error></mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input matInput type="email" placeholder="E-Mail" formControlName="email">
          <mat-error></mat-error>
        </mat-form-field>

        <mp-change-password-input (changePassword)="changePassword($event)"></mp-change-password-input>
      </mp-view-column>

      <mat-tab-group>

        <ng-container *ngIf="profil$ | async; let profil">
          <mat-tab label="Lizenz" *ngIf="profil.activeLizenz">
            <small>Lizenz</small>
            <p>{{ profil.activeLizenz }}</p>

            <small>Gültig ab</small>
            <p>{{ profil.activeLizenzStart | date: 'dd.MM.yyyy' }}</p>

            <small>Gültig bis</small>
            <p>{{ profil.activeLizenzEnde | date: 'dd.MM.yyyy' }}</p>
          </mat-tab>
        </ng-container>

      </mat-tab-group>

    </ng-container>
  </form>

</mp-view>
