import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { FileInfoDialogComponent } from './file-info-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mpcm-file-info-dialog-container',
};

@NgModule({
  imports: [FileInfoDialogComponent],
  exports: [FileInfoDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class FileInfoDialogModule {}
