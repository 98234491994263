import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  EntityAutocompleteComponent,
  SelectionItem,
  wrapInSelectionItems,
} from '@core/ui';
import {
  PlatformSupplierBasic,
  PlatformSupplierService,
  supplierCategoryDescriptions,
} from '@mp/lieferanten-manager/lieferanten/data-access';
import { getProp } from '@core/shared/util';

// TODO: Das muss auch ins Lieferanten-Management-Modul...

@Component({
  selector: 'mp-lieferanten-autocomplete-input',
  templateUrl: './lieferanten-autocomplete-input.component.html',
  styleUrls: ['./lieferanten-autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LieferantenAutocompleteInputComponent,
    },
  ],
})
export class LieferantenAutocompleteInputComponent
  implements ControlValueAccessor
{
  @ViewChild(EntityAutocompleteComponent, { static: true })
  autocomplete!: EntityAutocompleteComponent;

  @Input() appearance?: string;

  @Input() label = 'Übergeordneter Lieferant';
  @Input() placeholder = 'Plattformlieferanten zuordnen';
  @Input() icon = 'local_shipping';

  _errors: ValidationErrors = {};
  @Input()
  get errors() {
    return this._errors;
  }
  set errors(newErrors: ValidationErrors) {
    this._errors = newErrors;
    this.control.setErrors(newErrors);
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.control[this._disabled ? 'disable' : 'enable']();
  }
  private _disabled = false;

  _value: PlatformSupplierBasic | undefined;
  private readonly _value$ = new BehaviorSubject(
    undefined as PlatformSupplierBasic | undefined
  );
  readonly value$ = this._value$.asObservable();
  @Input()
  get value() {
    return this._value;
  }
  set value(newValue: PlatformSupplierBasic | undefined) {
    this._value = newValue;
    this._value$.next(newValue);
  }

  @Output() readonly valueChanges = new EventEmitter<PlatformSupplierBasic>();

  touched = false;
  readonly control = new UntypedFormControl('');

  readonly optionsFetcher: (
    searchTerm?: string
  ) => Observable<Array<SelectionItem<PlatformSupplierBasic>>>;
  get options$() {
    return this.autocomplete.options$ as Observable<
      Array<SelectionItem<PlatformSupplierBasic>>
    >;
  }

  onTouched: () => void = () => {};
  onChange: (value: PlatformSupplierBasic | undefined) => void = () => {};

  constructor(service: PlatformSupplierService) {
    this.optionsFetcher = this.buildOptionsFetcher(service);
  }

  private buildOptionsFetcher(service: PlatformSupplierService) {
    const subheader = ({ category, externalNumber }: PlatformSupplierBasic) => {
      if (category && externalNumber) {
        return `${supplierCategoryDescriptions[category]} ${externalNumber}`;
      }
      if (category) {
        return supplierCategoryDescriptions[category];
      }
      if (externalNumber) {
        return externalNumber;
      }
      return undefined;
    };

    return (searchTerm: string | undefined) =>
      service
        .getAll(searchTerm, { pageSize: 25 })
        .pipe(
          getProp('data'),
          wrapInSelectionItems({ header: 'name', subheader })
        );
  }

  updateValue(value: PlatformSupplierBasic | undefined): void {
    if (!this.disabled) {
      this.writeValue(value);
      this.onChange(value);
      this.markAsTouched();
    }
  }

  clearUI(input: HTMLInputElement): void {
    input.value = '';
    this.updateValue(undefined);
  }

  /* ControlValueAccessor Methods */

  writeValue(value: PlatformSupplierBasic | undefined): void {
    this._value = value;
    this._value$.next(value);
    this.valueChanges.emit(value);
  }

  registerOnChange(
    fn: (value: PlatformSupplierBasic | undefined) => void
  ): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
    disabled ? this.control.disable() : this.control.enable();
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
