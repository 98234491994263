import { Pipe, PipeTransform } from '@angular/core';
import { Category, CategoryType, TreeFacet } from '@mpcm/shared';

@Pipe({
  name: 'mpcmTreeFacetToCategoryMapping',
})
export class TreeFacetToCategoryMappingPipe implements PipeTransform {
  transform(facetCategory: TreeFacet, categoryType: string = ''): Category {
    const selectedCategories: Category[] = [];
    const categories = facetCategory.root.children;

    if (categories) {
      categories.forEach((element) => {
        if (element.selected) {
          selectedCategories.push(mapBucketToCategory(element));
          mapAndAddSelectedChildrenAsParent(element);
        }
      });
    }

    /**
     *
     * @param bucket Bucket of TreeFacet to find the selected one and map it to Category
     * @returns
     */
    function mapAndAddSelectedChildrenAsParent(
      bucket: TreeFacet.Bucket<unknown>
    ): void {
      if (bucket && bucket.children) {
        const child = bucket.children.find((x) => x.selected === true);

        if (child) {
          selectedCategories.push(mapBucketToCategory(child));
          return mapAndAddSelectedChildrenAsParent(child);
        }
      }

      for (let i = 1; i < selectedCategories.length; i++) {
        selectedCategories[i].parent = selectedCategories[i - 1];
      }
    }

    /**
     *
     * @param bucket Bucket that should be mapped to Category
     * @returns Returns a Category by the bucket informations
     */

    function mapBucketToCategory(bucket: TreeFacet.Bucket<unknown>): Category {
      const category: Category = {
        type: categoryType
          ? (categoryType as CategoryType)
          : CategoryType.ECLASS,
        description: bucket.label,
        code: bucket.val,
      };
      return category;
    }

    return selectedCategories[selectedCategories.length - 1];
  }
}
