import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { EffectsBase, PageResponse } from '@mp/shared/data-access';
import { OrganisationAdresse } from '../organisation-adresse';
import { OrganisationAdressenActions } from './organisation-adressen.actions';
import { OrganisationAdressenService } from '../organisation-adressen.service';

@Injectable()
export class OrganisationAdressenEffects extends EffectsBase {

  loadSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.loadSingle),
      exhaustMap(({ queryParams }) =>
        this.getRouterParamIdByKey('adresseId').pipe(
          switchMap(id => this.service.get(id, queryParams)),
          map((adresse: Partial<OrganisationAdresse>) => ({ adresse, error: null })),
          catchError((error: unknown) => of({ adresse: null, error }))
        ),
      ),
      map(({ adresse, error }) => {
        const loadedAdresse: OrganisationAdresse = adresse as OrganisationAdresse;
        return !error ?
          OrganisationAdressenActions.API.loadedSingleSuccessfully({ loadedAdresse }) :
          OrganisationAdressenActions.API.loadedSingleUnsuccessfully({ error });
      })
    );
  });

  loadedSingleUnsuccessfully$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.API.loadedSingleUnsuccessfully)
    );
  }, { dispatch: false });

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.loadAll),
      exhaustMap(({ queryParams }) => this.service.getAll(queryParams).pipe(
        map((adressen: PageResponse<Partial<OrganisationAdresse>>) => ({ adressen, error: null })),
        catchError((error: unknown) => of({ adressen: null, error }))
        )
      ),
      map(({ error, adressen }) => {
        if (!adressen || error) {
          return OrganisationAdressenActions.API.loadedAllUnsuccessfully({ error });
        }

        const loadedAdressenPage: PageResponse<OrganisationAdresse> = adressen as PageResponse<OrganisationAdresse>;
        return OrganisationAdressenActions.API.loadedAllSuccessfully({ loadedAdressenPage });
      })
    );
  });

  loadedAllUnsuccessfully$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.API.loadedAllUnsuccessfully)
    );
  }, { dispatch: false });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.create),
      mergeMap(action => this.service.create(action.adresseToCreate)),
      map((createdAdresse: OrganisationAdresse) => OrganisationAdressenActions.API.createdSuccessfully({ createdAdresse })),
      tap(() => { this.navigateBack(); })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.update),
      mergeMap(action => this.service.update(action.adresseToUpdate).pipe(
        map(result => ({ result, error: null })),
        catchError((error: unknown) => of({ result: null, error }))
      )),
      map(({ result, error }) => {
        if (!result || error) {
          return OrganisationAdressenActions.API.updatedUnsuccessfully({ error });
        }

        return OrganisationAdressenActions.API.updatedSuccessfully({ updatedAdresse: result });
      }),
      tap(() => { this.navigateBack(); })
    );
  });

  cancelCreate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.cancelCreate),
      map(OrganisationAdressenActions.API.canceledCreate),
      tap(() => { this.navigateBack(); })
    );
  });

  cancelUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAdressenActions.COMPONENT.cancelUpdate),
      map(OrganisationAdressenActions.API.canceledUpdate),
      tap(() => { this.navigateBack(); })
    );
  });

  constructor(
    injector: Injector,
    private readonly actions$: Actions,
    private readonly service: OrganisationAdressenService
  ) {
    super(injector);
  }
}
