<ng-template #shell>
  <mp-shell [appTitle]="appTitle$ | async" [showAppLogo]="showAppLogo$ | async">
    <mp-profil-display></mp-profil-display>
    <mp-nav-item path="/" icon="home">Start</mp-nav-item>
    <mp-nav-item *ngFor="let application of applications"
                  [path]="application.route"
                  [icon]="application.icon"
                  [label]="application.label"
                  [isGroup]="application.childApplications.length > 0">
      <mp-nav-item *ngFor="let childApplication of application.childApplications"
                    [path]="childApplication.route"
                    [icon]="childApplication.icon">
        {{ childApplication.label }}
      </mp-nav-item>
    </mp-nav-item>
  </mp-shell>
</ng-template>
<ng-container *ngIf="showOrganisationSelector; else shell;">
  <mp-active-organisation-selector></mp-active-organisation-selector>
</ng-container>
