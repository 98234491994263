import { NgModule } from '@angular/core';
import { SearchQueryFieldSelectorComponent } from './search-query-field-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule
  ],
  exports: [SearchQueryFieldSelectorComponent],
  declarations: [SearchQueryFieldSelectorComponent]
})
export class SearchQueryFieldSelectorModule {}
