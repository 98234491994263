import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListCountTitleComponent } from './list-count-title.component';


@NgModule({
  imports: [CommonModule],
  declarations: [ListCountTitleComponent],
  exports: [ListCountTitleComponent]
})
export class ListCountTitleModule { }
