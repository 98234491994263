<ng-container>
  <a
    class="breadcrumb-item"
    href="#"
    *ngFor="
      let item of showParents
        ? (categoriesPath | mpcmCategoryBreadcrumb)
        : [categoriesPath];
      last as isLast
    "
    [routerLink]="basePath"
    [queryParams]="{ category: item.code, categoryType: item.type }"
    [title]="item.code + ' | ' + item.description"
  >
    <span class="breadcrumb-item__deco"></span>
    <ng-container *ngIf="showIcons">
      <mat-icon class="breadcrumb-item__arrow">chevron_right</mat-icon>
    </ng-container>
    <span class="breadcrumb-item__text">
      {{ item.description }}
    </span>
    <ng-container *ngIf="item.code">
      <span
        *ngIf="(isLast && showLastCategoryCode) || showAllCategoryCodes"
        class="breadcrumb-item__code-elements"
      >
        <span class="breadcrumb-item__code-bracket">[</span>
        <span class="breadcrumb-item__code">{{ item.code }}</span>
        <span class="breadcrumb-item__code-bracket">]</span>
      </span>
    </ng-container>
  </a>
</ng-container>
