<div class="title-bar">
  <button mat-icon-button
          color="accent"
          aria-label="Close Form"
          (click)="cancel.next()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="title">
    {{ viewTitle }}
  </div>
  <button
    mat-fab
    *ngIf="!readonly"
    class="action-fab"
    aria-label="Submit Form"
    [disabled]="!submittable"
    (click)="send.next()">
    <mat-icon>check</mat-icon>
  </button>
</div>
<div class="detail-page">
  <div class="detail-page-content">
    <ng-content></ng-content>
  </div>
</div>
<div  class="detail-page-context-bar"
      [@openClose]="isContextBarActive ? 'open' : 'closed'">
  <router-outlet  name="contextbar"
                  (activate)="isContextBarActive = true"
                  (deactivate)="isContextBarActive = false">
  </router-outlet>
</div>
