import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UtilDirectivesModule } from '@core/shared/util';
import { UploadEntryFileType } from '../../models';

@Component({
  selector: 'mpcm-file-type-tile',
  templateUrl: './file-type-tile.component.html',
  styleUrls: ['./file-type-tile.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, UtilDirectivesModule],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class FileTypeTileComponent {
  @HostBinding('class') class = 'mpcm-file-type-tile';

  @Input()
  fileType!: UploadEntryFileType;

  readonly fileTypeIconMap: Record<UploadEntryFileType, string> = {
    [UploadEntryFileType.MasterData]: '@mdi:database-outline',
    [UploadEntryFileType.PriceData]: '@mdi:tag-outline',
    [UploadEntryFileType.ConsumptionData]: '@mdi:briefcase-variant-outline',
    [UploadEntryFileType.SalesData]: 'euro',
    [UploadEntryFileType.Other]: 'note',
  };
}
