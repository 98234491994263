import { Pipe, PipeTransform } from '@angular/core';
import { RechtFilter } from '@mp/shared/data-access';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserInfoFacade } from './user-info.facade';

@Pipe({ name: 'rechtIsHeldByUser' })
export class UserHasRechtPipe implements PipeTransform {

  constructor (private readonly userInfo: UserInfoFacade) { }

  transform(recht: RechtFilter): Observable<boolean> {
    // Silently ignore undefined values, since this will be bound inside a template.
    return this.userInfo
      .watchRecht$(recht)
      .pipe(map(hasRecht => hasRecht ?? false));
  }

}