import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { RouterPartialState, activatedRoute } from '../router/router-reducer';
import { routerSelectors } from '../router/router.selectors';

@Injectable()
export class EffectsBase {
  private readonly routerStore$: Store<RouterPartialState>;
  private readonly router: Router;

  constructor(
    private readonly injector: Injector
  ) {
    this.routerStore$ = this.injector.get(Store);
    this.router = this.injector.get(Router);
  }

  protected getRouterParamIdByKey(paramIdKey: string): Observable<number> {
    return this.routerStore$
      .select(routerSelectors.ENTITY_ID_FROM_ROUTE(), paramIdKey)
      .pipe(
        filter(({ routerParamId }) =>
          routerParamId !== undefined &&
          routerParamId !== null &&
          !isNaN(routerParamId)
        ),
        map(({ routerParamId }) => routerParamId)
      );
  }

  protected navigateBack(): void {
    this.router.navigate(['../'], {
      relativeTo: activatedRoute(this.router)
    });
  }
}
