<mp-basic-flyout [title]="title" (closed)="cancel()">

  <button mat-fab
    [disabled]="!selection.length"
    (click)="confirm()">
    <mat-icon>check</mat-icon>
  </button>

  <div class="selector-content">

    <div class="search-bar">
      <mat-form-field>
        <input  matInput
                placeholder="Durchsuchen"
                [formControl]="searchField">
        <button mat-button
                mat-icon-button
                *ngIf="searchField.value"
                matSuffix
                aria-label="Clear"
                (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="context-view-content">
      <mp-selector-item *ngFor="let item of displayedItems$ | async"
                        [selected]="isSelected(item)"
                        (toggled)="toggleSelect(item)">
        <ng-container *ngTemplateOutlet="template ?? selectionItemTemplate; context: {$implicit: item}"></ng-container>
      </mp-selector-item>
    </div>

  </div>

</mp-basic-flyout>

<ng-template #selectionItemTemplate let-item>
  <h4>{{ item.header }}</h4>
  <span *ngIf="item.subheader">{{ item.subheader }}</span>
</ng-template>