import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { EntityItemModule } from '../entity-item/entity-item.module';
import { EntityListComponent } from './entity-list.component';
import { EntityListHeadModule } from '../entity-list-head/entity-list-head.module';

import { AvatarModule } from '../../avatar/avatar.module';

@NgModule({
  imports: [
    CommonModule,

    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,

    AvatarModule,
    EntityItemModule,
    EntityListHeadModule,
  ],
  declarations: [EntityListComponent],
  exports: [EntityListComponent]
})

export class EntityListModule { }
