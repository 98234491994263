import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { ReactiveComponentModule } from '@ngrx/component';

import { EntityAutocompleteModule } from '@core/ui';
import { LieferantIconModule } from '../lieferant-icon/lieferant-icon.module';
import { SelectboxComponent } from './selectbox.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveComponentModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,

    EntityAutocompleteModule,
    LieferantIconModule,
    MatSelectModule
  ],
  exports: [SelectboxComponent],
  declarations: [SelectboxComponent],
})
export class SelectboxModule { }
