import { createAction, props } from '@ngrx/store';

import { Benutzer, CreateBenutzer, UpdateBenutzer } from '../benutzer';
import { PageResponse } from '@mp/shared/data-access';
import { QueryParams } from '@core/shared/util';

const loadSingle = createAction(
  '[Benutzer Edit Component] Load Single Benutzer',
  props<{ queryParams?: QueryParams<Benutzer, Partial<Benutzer>> }>()
);

const loadedSingleSuccessfully = createAction(
  '[Benutzer Service] Loaded Benutzer',
  props<{ loadedBenutzer: Benutzer }>()
);

const loadAll = createAction(
  '[Benutzer List Component] Load All Benutzer',
  props<{ queryParams?: QueryParams<Benutzer, Partial<Benutzer>> }>()
);

const loadedAllSuccessfully = createAction(
  '[Benutzer Service] Loaded All Benutzer',
  props<{ loadedBenutzerPage: PageResponse<Benutzer> }>()
);

const create = createAction(
  '[Benutzer Create Component] Create Benutzer',
  props<{ benutzerToCreate: CreateBenutzer, organisationenToAdd?: Array<number> }>()
);

const createdSuccessfully = createAction(
  '[Benutzer Service] Created Benutzer',
  props<{ createdBenutzer: Benutzer, added?: Array<boolean> }>()
);

const update = createAction(
  '[Benutzer Edit Component] Update Benutzer',
  props<{ benutzerToUpdate: UpdateBenutzer, organisationenToAdd?: Array<number>, organisationenToRemove?: Array<number> }>()
);

const updatedSuccessfully = createAction(
  '[Benutzer Service] Updated Benutzer',
  props<{ updatedBenutzer: Benutzer, added?: Array<boolean>, removed?: Array<boolean> }>()
);

const cancelCreate = createAction(
  '[Benutzer Create Component] Cancel Benutzer Create'
);

const canceledCreation = createAction(
  '[Benutzer Create Component] Canceled Benutzer Create'
);

const cancelUpdate = createAction(
  '[Benutzer Edit Component] Cancel Benutzer Update'
);

const canceledUpdate = createAction(
  '[Benutzer Edit Component] Canceled Benutzer Update'
);

export namespace BenutzerActions {
  export const API = {
    loadedSingleSuccessfully,
    loadedAllSuccessfully,
    updatedSuccessfully,
    createdSuccessfully,
    canceledUpdate,
    canceledCreation
  };

  export const COMPONENT = {
    loadSingle,
    loadAll,
    update,
    create,
    cancelUpdate,
    cancelCreate
  };
}
