import { APP_INITIALIZER, Injectable, InjectionToken, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';

import { Applikation, ApplikationenService, ConfigLoaderFacade, ConfigLoaderService } from '@mp/shared/data-access';
import { AuthService, LocalStorageService } from '@core/shared/data-access';
import { ErrorComponent } from '@core/ui';
import { RouteBuilder } from './route-builder';

interface InitialRouteState {
  url: string;
  title: string;
}
const InitDeps = new InjectionToken<Array<() => () => unknown>>('initDeps');

@Injectable()
export class AuthInitService {
  state?: InitialRouteState;

  constructor(private readonly injector: Injector) { }

  async init(): Promise<Array<() => unknown>> {
    const state: InitialRouteState = {
      url: self.location.href,
      title: document.title
    };

    const authService = this.injector.get(AuthService);
    const configLoaderFacade = this.injector.get(ConfigLoaderFacade);
    const configDeps = this.injector.get(InitDeps);

    const authConfig = await configLoaderFacade.authConfig$.toPromise();
    authService.setupUsingOAuthConfiguration(authConfig!);

    return authService.executeCodeFlowWithState(state)
      .then((routerState: InitialRouteState | undefined) => {
        this.state = routerState;
        return !routerState ? Promise.reject() : Promise.all(configDeps.map(dep => dep()));
      });
  }
}

@Injectable()
export class RouterInitService {

  constructor(private readonly injector: Injector) { }

  async initializeRouter(state?: InitialRouteState): Promise<void> {
    const localStorageService = this.injector.get(LocalStorageService);
    if (localStorageService.hasEntry('activeOrganisationId')) {
      const router = this.injector.get(Router);
      const appsService = this.injector.get(ApplikationenService);

      let apps: Array<Applikation> | undefined = [];
      try {
        apps = await appsService.getAll().toPromise();
      } catch(e) {
        /*
         * the stored activeOrganizationId could not be found for this user
         * remove the entry from local storage and navigate to organization selection
         * (auto select if there's only one organization)
         * stop initialization
         */
        localStorageService.removeEntry('activeOrganisationId');
        await router.navigate(['/'], { queryParams: { switch: 'organisation' } });
        return;
      }

      const appRoutes = this.buildRoutesFromApplications(router.config, apps!);
      router.resetConfig(appRoutes);

      if (state) {
        this.restoreLastVisitedPageFromState(state);
      }
    }
  }

  private restoreLastVisitedPageFromState(state: InitialRouteState): void {
    if (state && state.url && state.url !== self.location.href) {
      /**
       * Die URL nach dem Redirect zum IdentityServer wiederherstellen, sodass uns der Router zur ursprünglich gewünschten Seite bringt.
       * Das geschieht hier ohne neuen Eintrag in der History und ohne den Reload des Browsers zu triggern.
       * Direkt den Router zu benutzen funktioniert nicht, da der AppInitializer hier vorm Router-Init läuft.
       */
      self.history.replaceState({}, state.title || document.title, state.url);
    }
  }

  private buildRoutesFromApplications(initialRouterConfig: Array<Route>, apps: Array<Applikation>): Array<Route> {
    const errorRoute = {
      path: 'error',
      component: ErrorComponent,
      data: {
        errorCode: 404,
        errorMessage: 'Die angegebene Seite konnte nicht gefunden werden.'
      }
    };

    return RouteBuilder
      .fromRouterConfig(initialRouterConfig)
      .registerMultipleApplications(apps)
      .registerErrorRoute(errorRoute)
      .buildRoutes();
  }
}

export const appInitProvider = [
  ConfigLoaderService,
  ConfigLoaderFacade,
  AuthInitService,
  RouterInitService,
  {
    provide: APP_INITIALIZER,
    useFactory: (authInitService: AuthInitService) => () => authInitService.init(),
    multi: true,
    deps: [AuthInitService]
  },
  {
    provide: InitDeps,
    useFactory: (
      routerInitService: RouterInitService,
      authInitService: AuthInitService
    ) => {
      return [() => routerInitService.initializeRouter(authInitService.state)];
    },
    deps: [RouterInitService, AuthInitService]
  }
];
