import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-nav-item',
  templateUrl: './navigation-item.component.html'
})
export class NavigationItemComponent extends ElementDirective implements OnDestroy {
  @HostBinding('class') override readonly class = 'mp-nav-item';

  @HostBinding('attr.aria-expanded')
    isExpanded = false;
  private readonly mouseEvents$ = new Subject<boolean>();
  private readonly isDestroyed$ = new Subject<void>();

  @Input() icon?: string;
  @Input() label?: string;
  @Input() path?: string;
  @Input() isGroup?: boolean;

  @ViewChild('link')
  linkRef!: ElementRef<HTMLAnchorElement>;

  @ViewChild('groupcontent')
  groupContentRef!: ElementRef<HTMLDivElement>;

  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.mouseEvents$.next(true);
  };

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.mouseEvents$.next(false);
  };

  constructor(public router: Router) {
    super();

    this.mouseEvents$
    .pipe(
      debounceTime(50),
      takeUntil(this.isDestroyed$)
    ).subscribe( mouseEvent => {
      this.isExpanded = mouseEvent === true;

      if (this.isGroup) {
        this.handleSubMenuContainByScreen();
      }
    });
  }

  get isRouteActive(): boolean {
    if (!this.path) {
      throw Error('"path" not set in template!');
    }

    return this.router.isActive(this.path, false);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  private handleSubMenuContainByScreen(): void {
    requestAnimationFrame(() => {
      if (this.linkRef.nativeElement && this.groupContentRef.nativeElement) {
        const group = this.groupContentRef.nativeElement;
        const sideNav = this.linkRef.nativeElement.closest('.mat-sidenav');
        if (sideNav && !sideNav.classList.contains('mat-drawer-opened') && this.isExpanded) {
          const link = this.linkRef.nativeElement;

          const linkRect = link.getBoundingClientRect();
          const groupRect = group.getBoundingClientRect();
          const buffer = 10;

          const top = linkRect.top + groupRect.height + buffer < window.innerHeight
            ? linkRect.top
            : window.innerHeight - groupRect.height - buffer;

          group.style.top = `${Math.ceil(top)}px`;
          group.style.left = `${Math.ceil(linkRect.right)}px`;
        } else {
          group.style.top = group.style.left = '';
        }
      }
    });
  }
}

