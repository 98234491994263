import { SelectionItem } from '../selection-item/selection-item';

export type SearchLambda<T = any> = (item: T, searchTerm: Exclude<string, ''>) => boolean;

export class DefaultSearchLambdas {

  /**
   * This lambda takes an object and searches all non-nested string-type field values for the search term.
   * @example
   * Any({ foo: 'baz search', bar: 1 }, 'search') // true
   * Any({ foo: { baz: 'search' }, bar: 1 }, 'search') // false
   */
  static any<T extends object>(item: T, searchTerm: string): boolean {
    return Object.values(item)
      .filter(itemFieldValue => typeof itemFieldValue === 'string')
      .some((itemFieldValue: string) => {
        const trimmedLowercaseSearchTerm = searchTerm.trim().toLowerCase();
        const lowercaseFieldValue = itemFieldValue.toLowerCase();

        return lowercaseFieldValue.includes(trimmedLowercaseSearchTerm);
    });
  }

  /**
   * This lambda takes a SelectionItem and searches it's header and subheader for the search term.
   */
  static selectionItem<T>(item: SelectionItem<T>, searchTerm: string): boolean {
    const trimmedLowercaseSearchTerm = searchTerm.trim().toLowerCase();
    const lowercaseHeader = item.header.toLowerCase();
    const lowercaseSubheader = (item.subheader || '').toLowerCase();

    return lowercaseHeader.includes(trimmedLowercaseSearchTerm) ||
      lowercaseSubheader.includes(trimmedLowercaseSearchTerm);
  }

}

export type SortLambda<T = any> = (a: T, b: T) => number;

export class DefaultSortLambdas {

  static unsorted<T>(a: T, b: T): number {
    return 0;
  }

  static selectionItem<T>(a: SelectionItem<T>, b: SelectionItem<T>): number {
    return a.header.localeCompare(b.header, undefined, { usage: 'sort' });
  }

}
