import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BenutzerState, BENUTZER_FEATURE_KEY } from './benutzer.reducer';

const getBenutzerState = createFeatureSelector<BenutzerState>(BENUTZER_FEATURE_KEY);

const IS_LOADING = createSelector(
  getBenutzerState,
  state => state.isLoading
);

const LIST = createSelector(
  getBenutzerState,
  state => state.entities
);

const SELECTED = createSelector(
  getBenutzerState,
  state => state.selected
);

export const benutzerSelectors = {
  IS_LOADING,
  LIST,
  SELECTED
};
