import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageLink } from '@mp/shared/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';
import { Observable } from 'rxjs';
import {
  PendingPurchaseRequisitionCounts,
  PurchaseRequisitionQuickAccessFilter,
  PurchaseRequisitionsPeriod,
  PurchaseRequisitionsStateFilterState,
} from '../../../models';
import { PendingRequisitionsCountsWidgetStore } from './pending-requisition-counts-widget.store';

@Component({
  selector: 'mpdm-pending-requisition-counts-widget',
  standalone: true,
  templateUrl: './pending-requisition-counts-widget.component.html',
  styleUrls: ['./pending-requisition-counts-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, AsyncPipe, DashboardWidgetComponent, CountUpStatComponent],
  providers: [PendingRequisitionsCountsWidgetStore],
})
export class PendingRequisitionCountsWidgetComponent {
  readonly pendingRequisitionsCounts$: Observable<PendingPurchaseRequisitionCounts | undefined> =
    this.store.pendingPurchaseRequisitionCounts$;

  readonly link: PageLink = ['orders/document-management/purchase-requisitions'];

  readonly pendingRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: PurchaseRequisitionsPeriod.All,
    requisitionState: PurchaseRequisitionsStateFilterState.Pending,
  };

  readonly failedPendingRequisitionsParams: PurchaseRequisitionQuickAccessFilter = {
    numberOfDays: PurchaseRequisitionsPeriod.All,
    requisitionState: PurchaseRequisitionsStateFilterState.Failed,
  };

  constructor(private readonly store: PendingRequisitionsCountsWidgetStore) {
    this.store.fetchPendingPurchaseRequisitionCounts();
  }
}
