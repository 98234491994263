import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { EntityListHeadComponent } from '../entity-list-head/entity-list-head.component';
import { SearchFieldModule } from '../../search-field/search-field.module';
import { UtilPipesModule } from '@core/shared/util';

@NgModule({
  imports: [
    CommonModule,

    MatButtonModule,
    MatIconModule,
    MatInputModule,

    SearchFieldModule,
    UtilPipesModule
  ],
  declarations: [EntityListHeadComponent],
  exports: [
    EntityListHeadComponent,
    UtilPipesModule
  ]
})

export class EntityListHeadModule { }
