import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbItemComponent } from './components/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import {
  CategoryBreadcrumbPipe,
  TreeFacetToCategoryMappingPipe,
} from './pipes';

@NgModule({
  imports: [MatIconModule, RouterModule, CommonModule],
  declarations: [
    CategoryBreadcrumbPipe,
    TreeFacetToCategoryMappingPipe,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
  ],
  exports: [
    CategoryBreadcrumbPipe,
    TreeFacetToCategoryMappingPipe,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
  ],
})
export class CategoriesModule {}
