import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { CreateRolle, Rolle, UpdateRolle } from '../rolle';
import { CreateRolleBenutzer, RolleBenutzer } from '../rolle-benutzer';
import { CreateRolleRecht, RolleRecht } from '../rolle-recht';
import { EffectsBase, PageResponse, RouterPartialState, routerSelectors } from '@mp/shared/data-access';
import { NotificationService } from '@mp/shared/data-access';
import { RollenActions } from './rollen.actions';
import { RollenPartialState } from './rollen.reducer';
import { RollenService } from '../rollen.service';
import { rollenSelectors } from './rollen.selectors';

@Injectable()
export class RollenEffects extends EffectsBase {

  loadSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.loadSingle),
      exhaustMap(({ queryParams }) =>
        this.getRouterParamIdByKey('rolleId').pipe(
          switchMap(id => this.service.get(id, queryParams)),
          map((rolle: Partial<Rolle>) => ({ rolle, error: null })),
          catchError((error: unknown) => of({ rolle: null, error }))
        ),
      ),
      map(({ rolle, error }) => {
        if (!rolle || error) {
          return RollenActions.API.loadedSingleUnsuccessfully({ error });
        }

        const loadedRolle: Rolle = rolle as Rolle;
        return RollenActions.API.loadedSingleSuccessfully({ loadedRolle });
      })
    );
  });

  loadedSingleUnsuccessfully$ = createEffect(
    () => { return this.actions$.pipe(ofType(RollenActions.API.loadedSingleUnsuccessfully)); },
    { dispatch: false }
  );

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.loadAll),
      exhaustMap(({ queryParams }) => this.service.getAll(queryParams).pipe(
        map((rollen: PageResponse<Partial<Rolle>>) => ({ rollen, error: null })),
          catchError((error: unknown) => of({ rollen: null, error }))
        )
      ),
      map(({ rollen, error }) => {
        if (!rollen || error) {
          return RollenActions.API.loadedAllUnsuccessfully({ error });
        }

        const loadedRollenPage: PageResponse<Rolle> = rollen as PageResponse<Rolle>;
        return RollenActions.API.loadedAllSuccessfully({ loadedRollenPage });
      })
    );
  });

  loadedAllUnsuccessfully$ = createEffect(
    () => { return this.actions$.pipe(ofType(RollenActions.API.loadedAllUnsuccessfully)); },
    { dispatch: false }
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.create),
      mergeMap(({ rolleToCreate, benutzerToAdd, rechteToAdd }) =>
        this.createRoleAndRelations(
          rolleToCreate,
          benutzerToAdd ?? [],
          rechteToAdd ?? []
        ).pipe(
          map((result: [Rolle, Array<RolleBenutzer>, Array<RolleRecht>]) => ({ result, error: null })),
          catchError((error: unknown) => of({ result: null, error }))
        )
      ),
      map(({ result, error }) => {
        if (!result || error) {
          return RollenActions.API.createdUnsuccessfully({ error });
        }

        const [ createdRolle, addedBenutzer, addedRechte ] = result;
        return RollenActions.API.createdSuccessfully({
          createdRolle,
          addedBenutzer: addedBenutzer.map(benutzer => !!benutzer),
          addedRechte: addedRechte.map(recht => !!recht)
        });
      }),
      tap(() => { this.navigateBack(); })
    );
  });

  createdUnsuccessfully$ = createEffect(
    () => { return this.actions$.pipe(ofType(RollenActions.API.createdUnsuccessfully)); },
    { dispatch: false }
  );

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.update),
      mergeMap(({ rolleToUpdate, benutzerToAdd, benutzerToRemove, rechteToAdd, rechteToRemove }) =>
        this.updateRolleAndRelations(
          rolleToUpdate,
          benutzerToAdd ?? [],
          benutzerToRemove ?? [],
          rechteToAdd ?? [],
          rechteToRemove ?? []
        ).pipe(
          map(result => ({ result, error: null })),
          catchError((error: unknown) => of({ result: [], error }))
        )
      ),
      map(({ result, error }) => {
        if (!result || error) {
          return RollenActions.API.updatedUnsuccessfully({ error });
        }

        const [updatedRolle, addedBenutzer, removedBenutzer, addedRechte, removedRechte] = result;
        return RollenActions.API.updatedSuccessfully({
          updatedRolle,
          addedBenutzer: addedBenutzer.map(benutzer => !!benutzer),
          removedBenutzer: removedBenutzer.map(benutzer => !!benutzer),
          addedRechte: addedRechte.map(rechte => !!rechte),
          removedRechte: removedRechte.map(rechte => !!rechte)
        });
      }),
      tap(() => { this.navigateBack(); })
    );
  });

  updatedUnsuccessfully$ = createEffect(
    () => { return this.actions$.pipe(ofType(RollenActions.API.updatedUnsuccessfully)); },
    { dispatch: false }
  );

  cancelCreate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.cancelCreate),
      map(RollenActions.API.canceledCreate),
      tap(() => { this.navigateBack(); })
    );
  });

  cancelUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.cancelUpdate),
      map(RollenActions.API.canceledUpdate),
      tap(() => { this.navigateBack(); })
    );
  });

  deleteSingle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.COMPONENT.deleteSingle),
      mergeMap(action =>
        this.store$
        .pipe(
          select(rollenSelectors.LIST),
          map(rollen => (rollen ?? []).find(rolle => rolle.id === action.rolleId)),
          map(rolle => {
            if (!rolle) {
              throw new Error(`Rolle with id "${ action.rolleId }" cound not been found in store!`);
            }

            return rolle;
          }),
          take(1)
        )
      ),
      mergeMap(rolle =>
        this.service.delete(rolle.id).pipe(
          // Propagates error and rolle (error might be empty if API call is successfull)
          map(() => ({ rolle, error: null })),
          catchError((error: unknown) => of({ rolle, error }))
        )
      ),
      map(({ error, rolle }) => {
        if (!rolle || error) {
          return RollenActions.API.deletedSingleUnsuccessfully({ deletedRolle: rolle, error: error });
        }

        return RollenActions.API.deletedSingleSuccessfully({ deletedRolle: rolle });
      })
    );
  });

  deletedSingleSuccessfully$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RollenActions.API.deletedSingleSuccessfully),
      tap(action => this.notificationService.toastSuccess(
        `Rolle "${ action.deletedRolle.name }" erfolgreich gelöscht!`
      )),
      mergeMap(({ deletedRolle }) =>
        this.router$
          .select(routerSelectors.FROM_PARAM_MAP, 'rolleId')
          .pipe(
            map(selectedRolleId => {
              if (!selectedRolleId) {
                throw Error('"rolleId" could not be read from route!');
              }

              return selectedRolleId;
            }),
            map(selectedRolleId => ({
              deletedRolleId: deletedRolle.id,
              selectedRolleId: parseInt(selectedRolleId)
            })
          ))
      ),
      filter(({ deletedRolleId, selectedRolleId }) => deletedRolleId === selectedRolleId),
      tap(() => { this.navigateBack(); })
    );
  }, { dispatch: false });

  deletedSingleUnsuccessfully$ = createEffect(
    () => { return this.actions$.pipe(ofType(RollenActions.API.deletedSingleUnsuccessfully)); },
    { dispatch: false }
  );

  constructor(
    injector: Injector,
    private readonly actions$: Actions,
    private readonly service: RollenService,
    private readonly notificationService: NotificationService,
    private readonly store$: Store<RollenPartialState>,
    private readonly router$: Store<RouterPartialState>
  ) {
    super(injector);
  }

  private createRoleAndRelations(
    rolleToCreate: CreateRolle,
    benutzerToAdd: Array<CreateRolleBenutzer>,
    rightsToAdd: Array<CreateRolleRecht>
  ): Observable<[Rolle, Array<RolleBenutzer>, Array<RolleRecht>]> {

    return this.service
      .create(rolleToCreate)
      .pipe(
        mergeMap((createdRolle: Rolle) =>
          forkJoin([
            of(createdRolle),
            this.addBenutzerToRolle(benutzerToAdd, createdRolle.id),
            this.addRechteToRolle(rightsToAdd, createdRolle.id)
          ])
        )
      );
  }

  private updateRolleAndRelations(
    rolleToUpdate: UpdateRolle,
    benutzerToAdd: Array<CreateRolleBenutzer>,
    benutzerToRemove: Array<RolleBenutzer>,
    rechteToAdd: Array<CreateRolleRecht>,
    rechteToRemove: Array<RolleRecht>
  ): Observable<[Rolle, Array<RolleBenutzer>, Array<RolleBenutzer>, Array<RolleRecht>, Array<RolleRecht>]> {

    return forkJoin([
      this.service.update(rolleToUpdate),
      this.addBenutzerToRolle(benutzerToAdd, rolleToUpdate.id),
      this.removeBenutzerFromRolle(benutzerToRemove),
      this.addRechteToRolle(rechteToAdd, rolleToUpdate.id),
      this.removeRechteFromRolle(rechteToRemove)
    ]);
  }

  private addBenutzerToRolle(benutzerToAdd: Array<CreateRolleBenutzer>, id: number): Observable<Array<RolleBenutzer>> {
    return !benutzerToAdd || benutzerToAdd.length === 0
      ? of([])
      : this.service.addBenutzerToRolle(benutzerToAdd.map(benutzer => ({ ...benutzer, rolleId: id })));
  }

  private removeBenutzerFromRolle(benutzerToRemove: Array<RolleBenutzer>): Observable<Array<RolleBenutzer>> {
    return !benutzerToRemove || benutzerToRemove.length === 0
      ? of([])
      : this.service.removeBenutzerFromRolle(benutzerToRemove);
  }

  private addRechteToRolle(rechteToAdd: Array<CreateRolleRecht>, id: number): Observable<Array<RolleRecht>> {
    return !rechteToAdd || rechteToAdd.length === 0
      ? of([])
      : this.service.addRechteToRolle(rechteToAdd.map(recht => ({ ...recht, rolleId: id })));
  }

  private removeRechteFromRolle(rechteToRemove: Array<RolleRecht>): Observable<Array<RolleRecht>> {
    return !rechteToRemove || rechteToRemove.length === 0
      ? of([])
      : this.service.removeRechteFromRolle(rechteToRemove);
  }
}
