/* eslint-disable max-len */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { LoadChildren } from '@angular/router';

const systemImports: Record<string, LoadChildren> = {
  'organisationen': () => import('libs/mediio-plattform/system/organisationen/feature/src/lib/organisationen.module')
    .then(mod => mod.OrganisationenModule),

  'geschaeftspartnerarten': () => import('libs/mediio-plattform/system/geschaeftspartnerarten/feature/src/lib/geschaeftspartnerarten.module')
    .then(mod => mod.GeschaeftspartnerartenModule),

  'kommunikationslabels': () => import('libs/mediio-plattform/system/kommunikationslabels/feature/src/lib/kommunikationslabels.module')
    .then(mod => mod.KommunikationslabelsModule),

  'titel': () => import('libs/mediio-plattform/system/titel/feature/src/lib/titel.module')
    .then(mod => mod.TitelModule),

  'rechtsformen': () => import('libs/mediio-plattform/system/rechtsformen/feature/src/lib/rechtsformen.module')
    .then(mod => mod.RechtsformenModule),

  'benutzer': () => import('libs/mediio-plattform/system/benutzer/feature/src/lib/benutzer.module')
    .then(mod => mod.BenutzerModule),

  'organisationshierarchien': () => import('libs/mediio-plattform/system/organisationshierarchien/feature/src/lib/organisationshierarchien.module')
    .then(mod => mod.OrganisationsHierarchienModule),

  'rollen': () => import('libs/mediio-plattform/system/rollen/feature/src/lib/rollen.module')
    .then(mod => mod.RollenModule),

  'info': () => import('libs/mediio-plattform/system/info/feature/src/lib/info.module')
    .then(mod => mod.InfoModule),

  'bi-darstellung': () => import('libs/mediio-plattform/system/bi-darstellung/feature/src/lib/bi-darstellung.module')
    .then(mod => mod.BiDarstellungModule),

  'platform-packaging-units': () => import('libs/mediio-plattform/system/platform-packaging-units/feature/src/lib/platform-packaging-units.module')
  .then(mod => mod.PlatformPackagingUnitsModule),

  'freigabe': () => import('libs/mediio-plattform/content-manager/freigabe/feature/src/lib/freigabe.module')
  .then(mod => mod.FreigabeModule),
};

const meineOrganisationImports: Record<string, LoadChildren> = {
  'organisation-benutzer': () => import('libs/mediio-plattform/meine-organisation/benutzer/feature/src/lib/organisation-benutzer.module')
    .then(mod => mod.OrganisationBenutzerModule),

  'organisation-rollen': () => import('libs/mediio-plattform/meine-organisation/rollen/feature/src/lib/organisation-rollen.module')
    .then(mod => mod.OrganisationRollenModule),

  'organisation-kostenstellen': () => import('libs/mediio-plattform/meine-organisation/kostenstellen/feature/src/lib/organisation-kostenstellen.module')
    .then(mod => mod.OrganisationKostenstellenModule),

  'organisation-adressen': () => import('libs/mediio-plattform/meine-organisation/adressen/feature/src/lib/organisation-adressen.module')
    .then(mod => mod.OrganisationAdressenModule),

  'organisation-info': () => import('libs/mediio-plattform/meine-organisation/info/feature/src/lib/organisation-info.module')
    .then(mod => mod.OrganisationInfoModule),

  'organisation-api-keys': () => import('libs/mediio-plattform/meine-organisation/api-keys/feature/src/lib/api-keys.module')
    .then(mod => mod.ApiKeysModule),

  'barcode-protokoll': () => import('libs/mediio-plattform/meine-organisation/barcode-protokoll/feature/src/lib/barcode-protokoll.module')
    .then(mod => mod.BarcodeProtokollModule)
};

const medicalShopImports: Record<string, LoadChildren> = {
  'artikelsuche': () => import('libs/mediio-plattform/medical-shop/artikelsuche/feature/src/lib/artikelsuche.module')
    .then(mod => mod.ArtikelsucheModule),

  'auftraege': () => import('libs/mediio-plattform/medical-shop/auftraege/feature/src/lib/auftraege.module')
    .then(mod => mod.AuftraegeModule)
};

const medicalShop2Imports: Record<string, LoadChildren> = {
  'medical-shop2': () => import('libs/mediio-plattform/medical-shop-2/core/src/lib/medical-shop-core.module')
  .then(mod => mod.MedicalShopCoreModule),
};

const catalogManagementImports: Record<string, LoadChildren> = {
  'kataloge': () => import('libs/mediio-plattform/content-manager/industriekataloge/feature/src/lib/industriekataloge.module')
    .then(mod => mod.IndustriekatalogeModule),

  'plattformkataloge': () => import('libs/mediio-plattform/content-manager/plattformkataloge/feature/src/lib/plattformkataloge.module')
    .then(mod => mod.PlattformkatalogeModule),

  'klinikkataloge': () => import('libs/mediio-plattform/content-manager/klinikkataloge/feature/src/lib/klinikkataloge.module')
    .then(mod => mod.KlinikkatalogeModule),
};

const articleSearchImports: Record<string, LoadChildren> = {
  'artikel': () => import('libs/mediio-plattform/content-manager/artikelsuche/feature/src/lib/artikelsuche.module')
    .then(mod => mod.ArtikelsucheModule),

  'favorites': () => import('libs/mediio-plattform/content-manager/favorites/feature/src/lib/favorites.module')
    .then(mod => mod.FavoritesModule)
};

const ordersImports: Record<string, LoadChildren> = {
  'orders-history': () => import('libs/mediio-plattform/orders/feature/src/lib/orders.module')
    .then(mod => mod.OrdersModule),
};

const lieferantenManagementImports: Record<string, LoadChildren> = {
  'lieferanten': () => import('libs/mediio-plattform/lieferanten-manager/lieferanten/feature/src/lib/lieferanten.module')
    .then(mod => mod.LieferantenModule),

  'kliniklieferanten': () => import('libs/mediio-plattform/lieferanten-manager/kliniklieferanten/feature/src/lib/kliniklieferanten.module')
    .then(mod => mod.KliniklieferantenModule)
};

const documentManagementImports: Record<string, LoadChildren> = {
  'purchase-requisitions': () => import('libs/mediio-plattform/document-management/core/src/lib/document-management-core.module')
    .then(mod => mod.DocumentManagementCoreModule),
};

/**
 * Object containing async import statements for all modules to be "lazy-loaded" by the application.
 * Every property represents the route-path of the module,
 * mapped to an async import statement actually importing the module (when navigating to the route).
 *
 * This object will be used as input by the RouteBuilder when building all routes.
 */
export const moduleImports: Record<string, LoadChildren> = {
  ...systemImports,
  ...meineOrganisationImports,
  ...medicalShopImports,
  ...medicalShop2Imports,
  ...catalogManagementImports,
  ...articleSearchImports,
  ...lieferantenManagementImports,
  ...ordersImports,
  ...documentManagementImports,
};
