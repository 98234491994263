interface BaseAddress {
  street: string;
  additionalInformation?: string;
  zipCode: number;
  city: string;
}

export function formatAddress<T extends BaseAddress>(address: T): string {
  return (
    (address.additionalInformation
      ? address.additionalInformation + ', '
      : '') +
    address.street +
    ', ' +
    address.zipCode +
    ' ' +
    address.city
  );
}
