<div class="title-bar">
  
  <button
    mat-icon-button
    aria-label="Close Flyout"
    color="accent"
    (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  
  <div class="title">
    {{ title }}
  </div>

  <div class="action-fab">
    <ng-content select="[mat-fab]"></ng-content>
  </div>
</div>
<div class="content">
  <ng-content></ng-content>
</div>