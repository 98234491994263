import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';

import { WarengruppenNavigationComponent } from './warengruppen-navigation.component';
import { WarengruppenNavigationItemComponent } from './warengruppen-navigation-item/warengruppen-navigation-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatListModule,
    MatRippleModule
  ],
  declarations: [WarengruppenNavigationComponent, WarengruppenNavigationItemComponent],
  exports: [WarengruppenNavigationComponent]
})
export class WarengruppenNavigationModule { }
