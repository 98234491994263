import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-basic-flyout',
  templateUrl: './basic-flyout.component.html',
  styleUrls: ['./basic-flyout.component.scss']
})
export class BasicFlyoutComponent implements OnInit, OnDestroy {

  @HostBinding('class') readonly class = 'mp-basic-flyout';

  @Input() title?: string;
  @Output() readonly closed = new EventEmitter<void>();

  private readonly isDestroyed$ = new Subject<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor (private readonly router: Router) { }

  close(): void {
    this.closed.next();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({ next: event => {
        if (event instanceof NavigationStart) {
          this.close();
        }
      } });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();

    this.closed.complete();
  }
}
