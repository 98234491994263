import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { BasicFlyoutComponent } from './basic-flyout.component';

@NgModule({
  imports: [
    CommonModule,
  
    MatButtonModule,
    MatIconModule
  ],
  declarations: [BasicFlyoutComponent],
  exports: [BasicFlyoutComponent]
})
export class BasicFlyoutModule {}