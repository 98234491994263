import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AppConfigService } from '../config-loader/app-config.service';

export function tabTitleResolver(featureTitle: string): ResolveFn<string> {
  return (): Observable<string> =>
    inject(AppConfigService).envConfig$.pipe(
      map(({ appTitle }) => `${featureTitle} [${appTitle}]`)
    );
}
