import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivatedRoute, Params, Router} from '@angular/router';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Filter } from './models';
import { QueryParamsFilterConverterInterface } from './query-params-filter-converter.interface';
import { isEqual } from 'lodash';

export const QUERY_PARAMS_FILTER_TOKEN = new InjectionToken('QUERY_PARAMS_FILTER_TOKEN');

@Injectable()
export class QueryParamsStoreFilterFacade<T extends Filter> {

  get filter(): T {
    return this.queryParamsFilter.convertFromQueryParams(this.activatedRoute.snapshot.queryParams);
  }

  readonly filter$: Observable<T> = this.activatedRoute.queryParams.pipe(
    map(this.queryParamsFilter.convertFromQueryParams.bind(this))
  );

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(QUERY_PARAMS_FILTER_TOKEN) private readonly queryParamsFilter: QueryParamsFilterConverterInterface<T>,
  ) {}

  updateFilterAndResetPage(
    newFilterState: Partial<T>
  ): void {
    if (this.hasChangedFilterState(newFilterState)) {
      this.updateFilter({ ...newFilterState, page: 1 });
    }
  }

  updateFilter(partialFilters: Partial<T>): void {
    if (!this.hasChangedFilterState(partialFilters)) {
      return;
    }

    const newFilters = {
      ...this.filter,
      ...partialFilters
    };
    this.navigateWithParams(this.queryParamsFilter.convertToQueryParams(newFilters));
  }

  resetFilter(): void {
    const queryParams = this.queryParamsFilter.convertToQueryParams({});

    // Null existing params
    for (const [param, value] of Object.entries(this.filter)) {
      // If page/pageSize is present, reset page, but keep pageSize
      if (param === 'page') {
        queryParams[param] = 1;
        continue;
      }

      if (param === 'pageSize') {
        queryParams[param] = value;
        continue;
      }

      queryParams[param] = null;
    }

    this.navigateWithParams(queryParams);
  }

  hasChangedFilterState(newFilterState: Partial<T>): boolean {
    const keys = Object.keys(newFilterState);
    return keys.some((key) => !isEqual(newFilterState[key], this.filter[key]));
  }

  private navigateWithParams(queryParams: Params): void {
    this.router.navigate(
      [],
      {
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
  }
}
