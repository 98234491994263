<ng-container *ngrxLet="editorFacade.hierarchieData$ as data">

  <div class="action-bar">
    <h4>Hierarchie-{{ readonly ? 'Viewer' : 'Editor' }}</h4>
    <button
      mat-icon-button
      *ngIf="!readonly"
      [disabled]="!data"
      (click)="clearHierarchie()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  
  <div class="canvas">
    <mp-organisationshierarchie-node
      [readonly]="readonly"
      [data]="data"
      (removeNode)="removeOrganisationNode($event)"
      (addNodes)="letUserAddOrganisationen($event)">
    </mp-organisationshierarchie-node>
  </div>

</ng-container>
