import { createAction, props } from '@ngrx/store';

import { CreateRolle, Rolle, UpdateRolle } from '../rolle';
import { CreateRolleBenutzer, RolleBenutzer } from '../rolle-benutzer';
import { CreateRolleRecht, RolleRecht } from '../rolle-recht';
import { PageResponse } from '@mp/shared/data-access';
import { QueryParams } from '@core/shared/util';

type UpdateRelations = {
  benutzerToAdd?: Array<CreateRolleBenutzer>,
  benutzerToRemove?: Array<RolleBenutzer>,

  rechteToAdd?: Array<CreateRolleRecht>,
  rechteToRemove?: Array<RolleRecht>
};

type UpdatedRelations = {
  addedBenutzer?: Array<boolean>,
  removedBenutzer?: Array<boolean>,

  addedRechte?: Array<boolean>,
  removedRechte?: Array<boolean>
};

type CreateRelations = Pick<UpdateRelations, 'benutzerToAdd' | 'rechteToAdd'>;
type CreatedRelations = Pick<UpdatedRelations, 'addedBenutzer' | 'addedRechte'>;

const loadSingle = createAction(
  '[Rolle Edit Component] Load Single Rolle',
  props<{ queryParams?: QueryParams<Rolle, Partial<Rolle>> }>()
);

const loadedSingleSuccessfully = createAction(
  '[Rollen Service] Loaded Single Rolle',
  props<{ loadedRolle: Rolle }>()
);

const loadedSingleUnsuccessfully = createAction(
  '[Rolle Component] Loaded Single Rolle Unsuccessfully',
  props<{ error: unknown }>()
);

const loadAll = createAction(
  '[Rollen List Component] Load All Rollen',
  props<{ queryParams?: QueryParams<Rolle, Partial<Rolle>> }>()
);

const loadedAllSuccessfully = createAction(
  '[Rollen Service] Loaded All Rollen',
  props<{ loadedRollenPage: PageResponse<Rolle> }>()
);

const loadedAllUnsuccessfully = createAction(
  '[Rolle Component] Loaded All Rollen Unsuccessfully',
  props<{ error: unknown }>()
);

const create = createAction(
  '[Rolle Create Component] Create Rolle',
  props<{ rolleToCreate: CreateRolle } & CreateRelations>()
);

// TODO: Check if CreatedRelations really should contain boolean[]:
const createdSuccessfully = createAction(
  '[Rolle Service] Created Rolle',
  props<{ createdRolle: Rolle } & CreatedRelations>()
);

const createdUnsuccessfully = createAction(
  '[Rolle Service] Could Not Create Rolle',
  props<{ error: unknown }>()
);

const update = createAction(
  '[Rolle Edit Component] Update Rolle',
  props<{ rolleToUpdate: UpdateRolle } & UpdateRelations>()
);

// TODO: Check if UpdatedRelations really should contain boolean[]:
const updatedSuccessfully = createAction(
  '[Rollen Service] Updated Rolle',
  props<{ updatedRolle: Rolle } & UpdatedRelations>()
);

const updatedUnsuccessfully = createAction(
  '[Rollen Service] Could Not Update Rolle',
  props<{ error: unknown }>()
);

const cancelCreate = createAction(
  '[Rolle Create Component] Cancel Rolle Create'
);

const canceledCreate = createAction(
  '[Rolle Create Component] Canceled Rolle Create'
);

const cancelUpdate = createAction(
  '[Rolle Edit Component] Cancel Rolle Update'
);

const canceledUpdate = createAction(
  '[Rolle Edit Component] Canceled Rolle Update'
);

const deleteSingle = createAction(
  '[Rolle Component] Delete Rolle',
  props<{ rolleId: number }>()
);

const deletedSingleSuccessfully = createAction(
  '[Rolle Component] Deleted Rolle Successfully',
  props<{ deletedRolle: Rolle }>()
);

const deletedSingleUnsuccessfully = createAction(
  '[Rolle Component] Deleted Rolle Unsuccessfully',
  props<{ deletedRolle: Rolle, error: unknown }>()
);

export namespace RollenActions {
  export const API = {
    loadedSingleSuccessfully,
    loadedSingleUnsuccessfully,
    loadedAllSuccessfully,
    loadedAllUnsuccessfully,
    createdSuccessfully,
    createdUnsuccessfully,
    updatedSuccessfully,
    updatedUnsuccessfully,
    canceledCreate,
    canceledUpdate,
    deletedSingleSuccessfully,
    deletedSingleUnsuccessfully
  };

  export const COMPONENT = {
    loadAll,
    loadSingle,
    create,
    update,
    cancelCreate,
    cancelUpdate,
    deleteSingle
  };
}
