<mp-dashboard-widget title="Content Manager" icon="@enrich">
  <mpcm-file-upload-handler
    [disabled]="disabled"
    [acceptedFileTypes]="acceptedFileTypes"
    [maxFileBytes]="maxFileBytes"
  >
  </mpcm-file-upload-handler>
  <div class="result__list" *ngIf="vm$ | async as vm">
    <mat-list *ngIf="!vm.isLoading; else loading">
      <mpcm-datasource-upload-list-item
        *ngFor="
          let uploadedFile of vm.uploadedFiles
            | slice : 0 : (listExpanded ? vm.uploadedFiles.length : collapsedListLength)
        "
        [uploadedFile]="uploadedFile"
      >
        <mpcm-datasource-upload-list-item-actions-menu
          *ngIf="editFileRight | rechtIsHeldByUser | async"
          [status]="uploadedFile.status"
          (fileAttach)="onFileAttach(uploadedFile.uploadEntryId)"
          (fileDelete)="onFileDelete(uploadedFile.uploadEntryId)"
          (fileStatusChange)="onFileStatusChange(uploadedFile.uploadEntryId, $event)"
        ></mpcm-datasource-upload-list-item-actions-menu>
      </mpcm-datasource-upload-list-item>
    </mat-list>
    <ng-container *ngIf="listExpanded && vm.uploadedFilesPaginationMetadata as pagination">
      <mat-paginator
        *ngIf="pagination.totalRowCount > 10"
        showFirstLastButtons
        [length]="pagination.totalRowCount"
        [pageIndex]="pagination.currentPage - 1"
        [pageSize]="pagination.limit"
        [pageSizeOptions]="[10, 25, 50, 100]"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </ng-container>
    <button
      class="footer"
      mat-button
      footer
      (click)="toggleListExpand()"
      *ngIf="!vm.isLoading && vm.uploadedFiles.length > collapsedListLength"
    >
      <ng-container *ngIf="!listExpanded">
        Mehr anzeigen
        <mat-icon>arrow_drop_down</mat-icon>
      </ng-container>
      <ng-container *ngIf="listExpanded">
        Weniger anzeigen
        <mat-icon>arrow_drop_up</mat-icon>
      </ng-container>
    </button>
  </div>
</mp-dashboard-widget>

<ng-template #loading>
  <mp-spinner></mp-spinner>
</ng-template>
