import { Component, HostBinding, Input } from '@angular/core';

import { SupplierBasic, SupplierCategory, SupplierType } from '@mp/lieferanten-manager/lieferanten/data-access';

type ReservedIcons = Lowercase<(keyof SupplierCategory & string)> | 'clinicsupplier';

@Component({
  selector: 'mp-lieferant-icon',
  templateUrl: './lieferant-icon.component.html',
  styleUrls: ['./lieferant-icon.component.scss']
})
export class LieferantIconComponent {
  @HostBinding('class') readonly class = 'mp-lieferant-icon';

  @Input() supplier!: SupplierBasic;

  get icon(): ReservedIcons | null {
    if (this.supplier.type === SupplierType.ClinicSupplier) {
      return 'clinicsupplier';
    } else if (this.supplier.category != null) {
      return SupplierCategory[this.supplier.category].toLowerCase() as ReservedIcons;
    }
    return null;
  }
}
