import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AvatarComponent } from './avatar.component';
import { SpinnerModule } from '../spinner/spinner.module';
export { DisplaySize } from './display-size.type';
export { AvatarError } from './avatar-error.enums';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    SpinnerModule
  ],
  declarations: [ AvatarComponent ],
  exports: [ AvatarComponent ]
})
export class AvatarModule { }
