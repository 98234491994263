import { SupplierType } from '@mp/lieferanten-manager/lieferanten/data-access';

import { ListFacetBase } from '../../facets';

const supplierTypeIcons = {
  [SupplierType.ClinicSupplier]: '@transport_clinic',
  [SupplierType.PlatformSupplier]: 'local_shipping',
};

export function supplierFacetIconSelector(bucket: ListFacetBase.Bucket<{ type: SupplierType }>) {
  return supplierTypeIcons[bucket.additionalData.type];
}
