import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { FilterBadgeModule } from '../../filter-badge';
import { FilterInputOptionsComponent } from './filter-input-options.component';
import { FilterItemSelectorModule } from '../../filter-item-selector/filter-item-selector.module';

@NgModule({
    imports: [
        CommonModule,

        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,

        FilterItemSelectorModule,
        FilterBadgeModule
    ],
  declarations: [FilterInputOptionsComponent],
  exports: [FilterInputOptionsComponent],
  providers: []
})
export class FilterInputOptionsModule { }
