<div class="wrapper">
  <div class="search-field-col">
    <mat-form-field appearance="outline" size="small">

      <input matInput type="text"
        [placeholder]="placeholder"
        [formControl]="searchField"
        (keyup.enter)="emitSearchEvent()">

      <!-- NOTE: Order is important for these <ng-content> tags! -->
      <ng-container matSuffix>
        <ng-content select="[mp-search-field-button][mp-search-field-button=right]"></ng-content>
      </ng-container>

      <ng-container matPrefix>
        <ng-content select="[mp-search-field-button][mp-search-field-button=left]"></ng-content>
        <ng-content select="[mp-search-field-button]"></ng-content>
      </ng-container>

    </mat-form-field>
  </div>
</div>
