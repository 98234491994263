import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule],
})
export class IconRegistryModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // https://materialdesignicons.com
    this.matIconRegistry.addSvgIconSetInNamespace(
      'mdi',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/mdi.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'barcode',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/barcode.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'heart_plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/heart_plus.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'heart_minus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/heart_minus.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'export_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/export_icon.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'globalarticle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/globalarticle.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'division',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/suppliertype_division.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'ref_number',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/ref_number.svg'
      )
    );

    const statuses = ['successful', 'pending', 'failed'];

    statuses.forEach((status) => {
      this.matIconRegistry.addSvgIcon(
        `export_${status}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/svg/export_${status}.svg`
        )
      );
    });

    this.matIconRegistry.addSvgIcon(
      'transport_clinic',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/transport_clinic.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'truck_half',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/truck_half.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'truck_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/truck_full.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'delivered',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/delivered.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'approval_indicator',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/approval_indicator.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'home_pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/home_pin.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'main_supplier',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/main_supplier.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'enrich',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/enrich.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'dotted-square',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/dotted-square.svg'
      )
    );
  }
}
