<mat-toolbar class="toolbar">
  <button mat-icon-button
          (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container *ngIf="!showAppLogo">
    <a  mat-button
      routerLink="/">
      {{ appTitle }}
    </a>
  </ng-container>
  <ng-container *ngIf="showAppLogo">
    <img class="logo"
        alt="App Logo"
        src="assets/app_logo.png">
  </ng-container>
</mat-toolbar>

<!-- RK 2023-01-03: Bekam hier einen ExpressionChangedAfterItHasBeenCheckedError -->
<mat-progress-bar *ngIf="(isLoading$ | async) === true"
                  mode="indeterminate"
                  color="accent"
                  style="position: absolute; top: 0; z-index: 100;">
</mat-progress-bar>

<mat-sidenav-container class="navigation-container" >
  <mat-sidenav  #sidenav
                class="sidebar"
                [opened]="isOpened"
                [mode]="(isMediumViewport$ | async)  ? 'over' : 'side'"
                (openedStart)="isOpened = true"
                (closedStart)="isOpened = false"
                style="visibility: visible;">
    <div class="sidebar-wrapper">
      <nav>
        <ng-content></ng-content>
        <ng-content select="mp-nav-profile"></ng-content>
        <ng-content select="mp-nav-item"></ng-content>
      </nav>
      <div *ngIf="feedbackRight$ | rechtIsHeldByUser | async" class="sidebar-end-wrapper">
        <mp-feedback-button [isSmall]="!isOpened"></mp-feedback-button>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
