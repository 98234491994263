import { NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  ArticlePackagingMeasurementType,
  ArticlePackagingTag,
} from '../../../models';
import { ArticleMeasurement, ArticlePackagingUnit } from '../../models';

@Component({
  standalone: true,
  selector: 'mpcm-tab-packungsinfo',
  imports: [NgIf, NgForOf, MatCardModule, MatIconModule],
  templateUrl: './tab-packungsinfo.component.html',
  styleUrls: ['./tab-packungsinfo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPackungsinfoComponent implements OnInit {
  @Input() packungsinfo?: ArticlePackagingUnit;

  measurementsNet = '';
  measurementsGross = '';
  weightNet = '';
  weightGross = '';

  isBaseUnit = false;
  isOrderableUnit = false;
  isStandardOrderUnit = false;

  ngOnInit(): void {
    this.populate();
  }

  populate(): void {
    this.buildMeasurementValues(this.packungsinfo);
    this.setUnitBooleans(this.packungsinfo?.tags);
  }

  buildMeasurementValues(packungsinfo?: ArticlePackagingUnit): void {
    packungsinfo?.measurements?.forEach((measurement) => {
      if (measurement.type === ArticlePackagingMeasurementType.Article) {
        this.measurementsNet = this.buildMeasurementString(measurement);
        this.weightNet = this.buildWeightString(measurement);
      } else {
        this.measurementsGross = this.buildMeasurementString(measurement);
        this.weightGross = this.buildWeightString(measurement);
      }
    });
  }

  buildMeasurementString(measurement?: ArticleMeasurement): string {
    let returnString = '';

    if (
      !!measurement?.height &&
      !!measurement?.width &&
      !!measurement?.depth &&
      !!measurement?.dimensionUOM
    ) {
      returnString =
        measurement?.height?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' x ' +
        measurement?.width?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' x ' +
        measurement?.depth?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' ' +
        measurement?.dimensionUOM;
    }

    return returnString;
  }

  buildWeightString(measurement?: ArticleMeasurement): string {
    let returnString = '';
    if (measurement?.weight && measurement?.weightUOM) {
      returnString =
        measurement.weight.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' ' +
        measurement.weightUOM;
    }

    return returnString;
  }

  setUnitBooleans(tags?: Array<ArticlePackagingTag>): void {
    this.isBaseUnit =
      tags?.some((tag) => tag === ArticlePackagingTag.IsBaseUnit) ?? false;
    this.isOrderableUnit =
      tags?.some((tag) => tag === ArticlePackagingTag.IsOrderableUnit) ?? false;
    this.isStandardOrderUnit =
      tags?.some((tag) => tag === ArticlePackagingTag.IsStandardOrderUnit) ??
      false;
  }
}
