<!-- This class below will be moved onto the overlay panel: -->
<mat-autocomplete
  [class]="class"
  [panelWidth]="panelWidth"
  [autoActiveFirstOption]="autoActiveFirstOption"
  [displayWith]="displayFn"
  (optionSelected)="emitOptionSelected($event.option.value)"
  (optionActivated)="emitOptionActivated($event.option.value)"
>
  <mat-option
    *ngIf="
      isLoading &&
      (options$ | async)?.length === 0 &&
      searchTerm$.value.length >= minSearchTermLength
    "
  >
    <div class="no-entries">
      <mp-spinner></mp-spinner>
    </div>
  </mat-option>
  <mat-option
    *ngIf="
      (options$ | async)?.length === 0 &&
      searchTerm$.value.length >= minSearchTermLength &&
      !isLoading
    "
  >
    <div class="no-entries">
      <span>Keine Ergebnisse</span>
    </div>
  </mat-option>
  <mat-option
    *ngFor="let option of options$ | async"
    [value]="usesSelectionItems ? option.entity : option"
    [ngClass]="classObj"
  >
    <ng-container
      *ngTemplateOutlet="optionTemplate ?? selectionItemTemplate; context: { $implicit: option }"
    >
    </ng-container>
  </mat-option>
</mat-autocomplete>

<ng-template #selectionItemTemplate let-item>
  <div class="entity-autocomplete-item">
    <h4>{{ item.header }}</h4>
    <span *ngIf="item.subheader">{{ item.subheader }}</span>
  </div>
</ng-template>
