import { Action, createReducer, on } from '@ngrx/store';

import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';
import { Organisation } from '../organisation';
import { OrganisationenActions } from './organisationen.actions';

export const ORGANISATIONEN_FEATURE_KEY = 'organisationen';

export interface OrganisationenPartialState {
  readonly [ORGANISATIONEN_FEATURE_KEY]: OrganisationenState;
}

export type OrganisationenState = BasicState<Organisation>;
export const initialState: OrganisationenState = buildInitialBasicState();

const reducer = createReducer(
  initialState,

  on(
    OrganisationenActions.COMPONENT.loadSingle,
    OrganisationenActions.COMPONENT.create,
    OrganisationenActions.COMPONENT.update,
    
    (state): OrganisationenState => ({
      ...state,
      isLoading: true
    })
  ),

  on(
    OrganisationenActions.API.loadedAllSuccessfully,
    
    (state, action): OrganisationenState => ({
      ...state,
      isLoading: false,
      entities: action.loadedOrganisationenPage.data,
      pagination: action.loadedOrganisationenPage.pagination
    })
  ),

  on(
    OrganisationenActions.API.createdSuccessfully,
    
    (state, action): OrganisationenState => ({
      ...state,
      isLoading: false,
      entities: [action.createdOrganisation, ...(state.entities ?? [])]
    })
  ),

  on(
    OrganisationenActions.API.loadedSingleSuccessfully,
    
    (state, action): OrganisationenState => ({
      ...state,
      isLoading: false,
      selected: action.loadedOrganisation
    })
  ),

  on(
    OrganisationenActions.API.updatedSuccessfully,
    
    (state, action): OrganisationenState => ({
      ...state,
      isLoading: false,
      entities: [
        ...(state.entities ?? []).map((entity: Organisation) =>
          entity.id === action.updatedOrganisation.id ? action.updatedOrganisation : entity
        )
      ],
      selected: undefined
    })
  ),

  on(
    OrganisationenActions.API.canceled,

    (state): OrganisationenState => ({
      ...state,
      isLoading: false,
      selected: undefined
    })
  )
);

export function organisationenReducer(
  state: OrganisationenState | undefined,
  action: Action
): OrganisationenState {
  return reducer(state, action);
}
